import { Col, Row, Switch } from "antd";
import React, {
  FC,
  memo,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import AppNavigation from "src/components/AppNavigation";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import "./index.scss";
import { ReactComponent as NotificationIcon } from "@components/Icons/notification-app-setting.svg";
import { ReactComponent as FaqIcon } from "@components/Icons/faq-app-setting.svg";
import { ReactComponent as OperatingCompanyIcon } from "@components/Icons/operating-company-app-setting.svg";
import { ReactComponent as PrivacyPolicyIcon } from "@components/Icons/privacy-policy-app-setting.svg";
import { ReactComponent as TermsOfUseIcon } from "@components/Icons/terms-of-use-app-setting.svg";
import { ReactComponent as TransactionLawIcon } from "@components/Icons/transaction-law-app-setting.svg";
import { ReactComponent as UserManualIcon } from "@components/Icons/user-manual-app-setting.svg";
import { ReactComponent as ArrowRight } from "@components/Icons/arrow-right.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store/configureStore";
import { MessageType } from "src/models/common.model";
import { setAppLoading } from "src/store/State";

interface MenuItem {
  icon: ReactNode;
  label: string;
  keyPathname?: string;
  iconRight: ReactNode;
  url?: string;
  state?: boolean;
  onClick?: () => void;
}
const AppSettingMenu: FC = ({ ...props }) => {
  const [enableNotification, setEnableNotification] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();
  useHideBottomBar(false);
  const systemConfig = useSelector(
    (state: RootState) => state.GlobalReducer.systemConfig
  );
  const getUrlPolicy = (key: string): string => {
    const item = systemConfig.find((element) => element.key === key);
    if (item) {
      return item.value as unknown as string;
    }
    return "";
  };

  function handleMessageFromApp(event: any) {
    if (!event || !event.data) {
      return;
    }
    try {
      dispatch(setAppLoading(true));
      const message = JSON.parse(event.data);
      if (message.type === MessageType.SETTING_NOTIFICATION_DEVICE) {
        setEnableNotification(message.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setAppLoading(false));
    }
  }

  useEffect(() => {
    try {
      const message = {
        type: "CHECK_NOTIFICATION",
      };
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify(message)
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    window.removeEventListener("message", handleMessageFromApp, false); // Fails
    window.addEventListener("message", (ev) => handleMessageFromApp(ev), false);
    document.addEventListener(
      "message",
      (ev) => handleMessageFromApp(ev),
      false
    );
    return () => {
      window.removeEventListener("message", handleMessageFromApp, false);
      document.removeEventListener(
        "message",
        (ev) => handleMessageFromApp(ev),
        false
      );
    };
  }, []);

  const items: MenuItem[] = useMemo(
    () => [
      {
        icon: <NotificationIcon />,
        label: "PUSH通知",
        keyPathname: "notification",
        iconRight: <ArrowRight />,
        onClick: () => {
          const message = {
            type: MessageType.OPEN_SETTING,
          };
          (window as any).ReactNativeWebView.postMessage(
            JSON.stringify(message)
          );
        },
      },
      {
        icon: <TermsOfUseIcon />,
        label: "アプリ利用規約",
        keyPathname: "terms-of-use",
        url: getUrlPolicy("TermOfService"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <PrivacyPolicyIcon />,
        label: "プライバシーポリシー",
        keyPathname: "privacy-policy",
        url: getUrlPolicy("PrivacyPolicy"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <FaqIcon />,
        label: "よくある質問",
        keyPathname: "faq",
        url: getUrlPolicy("FAQ"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <OperatingCompanyIcon />,
        label: "運営会社",
        keyPathname: "operating-company",
        url: getUrlPolicy("CompanyInformation"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <TransactionLawIcon />,
        label: "特定商取引法",
        keyPathname: "transaction-law",
        url: getUrlPolicy("CompanyLaw"),
        iconRight: <ArrowRight />,
      },
      {
        icon: <UserManualIcon />,
        label: "ご利用ガイド",
        keyPathname: "user-manual",
        url: getUrlPolicy("UserGuide"),
        iconRight: <ArrowRight />,
      },
    ],
    [systemConfig]
  );

  return (
    <div className="ui-app-setting-menu">
      <Row gutter={[32, 32]} justify="center">
        <Col xs={24}>
          <AppNavigation title="設定" />
        </Col>
      </Row>
      <div className="ui-app-setting-menu__content">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={24} xl={24}>
            {items &&
              items.length &&
              items.map(
                ({ label, icon, iconRight, url, keyPathname, onClick }, index) => (
                  <div
                    className="ui-app-setting-menu__content-item"
                    key={index}
                    style={{
                      borderBottom: `solid 0 #D9DBE9`,
                    }}
                    onClick={() => {
                      if (url) {
                        const message = {
                          type: MessageType.MODAL,
                          data: url,
                        };
                        (window as any).ReactNativeWebView.postMessage(
                          JSON.stringify(message)
                        );
                      }
                      if (onClick) {
                        onClick();
                      }
                    }}
                  >
                    <div className="ui-app-setting-menu__content-left">
                      <div className="ui-app-setting-menu__content-icon">
                        {icon}
                      </div>
                      <div className="ui-app-setting-menu__content-label">
                        {label}
                      </div>
                    </div>
                    <div className="ui-app-setting-menu__content-arrow">
                      {keyPathname === "notification" && (
                        <div className="ui-app-setting-menu__content-state">{enableNotification === true ? 'ON' : 'OFF'}</div>
                      )}
                      {iconRight}
                    </div>
                  </div>
                )
              )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(AppSettingMenu);

AppSettingMenu.defaultProps = {};
