import React, { FC, memo } from "react";
import "./index.scss";

interface Props {
  loading: boolean
}

const AppSpinner: FC<Props> = memo(({ ...props }) => {
  const { loading } = props;

  return (
    loading ? (
      <div className="ui-spinner">
        <div className="ui-spinner__loader">
          <img src="/assets/images/Loader.gif" alt="spinner"/>
        </div>
      </div>
    ) : null
  );
});

export default AppSpinner;
