import AppStore, { RootState } from 'src/store/configureStore';
import AppHttpClient from '@core/http/index';
import StorageService from 'src/services/Storage';
import { signOut } from 'src/store/User';
import { clearAll } from 'src/store/Product-Filter';
import { BankAccountInformation, UserInformation } from 'src/pages/my-page/model';
import { clearProduct } from 'src/store/Product';

const handleAuthenticated = () => {
    const token = StorageService.getUserToken();
    // check token expired
};

function logout() {
    StorageService.deleteUserToken();
    AppStore.dispatch(signOut());
    AppStore.dispatch(clearAll());
    AppStore.dispatch(clearProduct());
}

const isAuthenticated = () => !!StorageService.getUserToken();

const login = (email: any, password: any) => AppHttpClient.post('/api/TokenAuth/Authenticate', { emailAddress: email, password: password, rememberClient: true });
const getUserInfo = () => AppHttpClient.get('/api/services/app/Account/GetAccountInfo');
const updateUserInfo = (data: UserInformation) => AppHttpClient.put('/api/services/app/Account/Update', data);
const getBankInfo = () => AppHttpClient.get('/api/services/app/BankAccountService/Get');
const getBankAccountStatus = () => AppHttpClient.get('/api/services/app/BankAccountService/GetBankAccountStatus');
const getBanks = () => AppHttpClient.get('/api/services/app/BankAccountService/GetBanks');
const getBranches = (bankCode: string) => AppHttpClient.get(`/api/services/app/BankAccountService/GetBranches?bankCode=${bankCode}`);
const updateBankAccount = (data: BankAccountInformation) => AppHttpClient.put('/api/services/app/BankAccountService/UpdateBankAccount', data);
const deActiveAccount = (data: { password: string, token: string }) => AppHttpClient.post(`/api/services/app/DeActivateMemberService/DeActivate`, data);
const getUserToken = () => AppHttpClient.get('/api/services/app/DeActivateMemberService/GetUserToken');
const checkValidateAccount = (password: string) => AppHttpClient.post(`/api/services/app/DeActivateMemberService/Validate`, { password });

const UserService = { login, handleAuthenticated, isAuthenticated, getUserInfo, updateUserInfo, logout, getBankInfo, getBankAccountStatus, getBanks, getBranches, updateBankAccount, deActiveAccount, getUserToken, checkValidateAccount };
export default UserService;