import React, { FC, memo, useState } from "react";
import { ReactComponent as ArrowDown } from "@components/Icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "@components/Icons/arrow-up.svg";

import { CollapseData } from "../../model";
import "./index.scss";

const Collapse: FC<CollapseData> = memo(({ ...props }) => {
  const { title, content } = props;
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const toggle = () => setCollapsed(prevState => !prevState);

  return (
    <div className="ui-sign-up-collapse">
      <div className="ui-sign-up-collapse__header" onClick={toggle} style={{ marginBottom: collapsed ? "10px" : "0" }}>
        <div className="ui-sign-up-collapse__header-text">{ title }</div>
        <div className="ui-sign-up-collapse__header-icon">
          {
            collapsed ? <ArrowUp /> : <ArrowDown />
          }
        </div>
      </div>
      {
        collapsed && (
          <div className="ui-sign-up-collapse__content">
            { content }
          </div>
        )
      }
    </div>
  );
});

export default Collapse;

Collapse.defaultProps = {};
