import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ChatFile {
  DOCUMENT = "document",
  MEDIA = "media"
}

interface ChatState {
  roomIds: Array<string>;
}

const initialState: ChatState = { roomIds: [] };
const slice = createSlice({
  name: "Chat",
  initialState,
  reducers: {
    setRoomId(state, action: PayloadAction<string>) {
      if (!state.roomIds.some((roomId: string) => roomId === action.payload)) {
        state.roomIds = [action.payload, ...state.roomIds];
      }

      if (state.roomIds.length > 3) {
        state.roomIds = [...state.roomIds.slice(0, 3)];
      }
    },
    clearRoomId(state, action: PayloadAction<string>) {
      state.roomIds = state.roomIds.filter((roomId: string) => roomId !== action.payload);
    },
    clearRoomIds(state, action: PayloadAction<Array<any>>) {
      state.roomIds = action.payload;
    }
  }
});

export const { setRoomId, clearRoomId, clearRoomIds } = slice.actions;

export default slice.reducer;
