import React, { FC } from "react";

interface Props {
  active?: boolean
}

const PurchaseConfirmed: FC<Props> = (({ ...props }) => {
  const { active } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <g clipPath="url(#clip0_1944_13893)">
        <path d="M39.1875 0H4.8125C4.63016 0 4.4553 0.0724328 4.32636 0.201364C4.19743 0.330295 4.125 0.505164 4.125 0.6875V5.17533C4.125 5.35766 4.19743 5.53253 4.32636 5.66146C4.4553 5.7904 4.63016 5.86283 4.8125 5.86283C4.99484 5.86283 5.1697 5.7904 5.29864 5.66146C5.42757 5.53253 5.5 5.35766 5.5 5.17533V1.375H38.5V42.625H5.5V7.92533C5.5 7.74299 5.42757 7.56812 5.29864 7.43919C5.1697 7.31026 4.99484 7.23783 4.8125 7.23783C4.63016 7.23783 4.4553 7.31026 4.32636 7.43919C4.19743 7.56812 4.125 7.74299 4.125 7.92533V43.3125C4.125 43.4948 4.19743 43.6697 4.32636 43.7986C4.4553 43.9276 4.63016 44 4.8125 44H39.1875C39.3698 44 39.5447 43.9276 39.6736 43.7986C39.8026 43.6697 39.875 43.4948 39.875 43.3125V0.6875C39.875 0.505164 39.8026 0.330295 39.6736 0.201364C39.5447 0.0724328 39.3698 0 39.1875 0V0Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M18.8887 4.40039H10.6387C10.4563 4.40039 10.2815 4.47282 10.1525 4.60175C10.0236 4.73069 9.95117 4.90555 9.95117 5.08789V13.3379C9.95117 13.5202 10.0236 13.6951 10.1525 13.824C10.2815 13.953 10.4563 14.0254 10.6387 14.0254H18.8887C19.071 14.0254 19.2459 13.953 19.3748 13.824C19.5037 13.6951 19.5762 13.5202 19.5762 13.3379V5.08789C19.5762 4.90555 19.5037 4.73069 19.3748 4.60175C19.2459 4.47282 19.071 4.40039 18.8887 4.40039ZM18.2012 12.6504H11.3262V5.77539H18.2012V12.6504Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M22.7539 7.97852H35.1891C35.3714 7.97852 35.5463 7.90608 35.6752 7.77715C35.8041 7.64822 35.8766 7.47335 35.8766 7.29102C35.8766 7.10868 35.8041 6.93381 35.6752 6.80488C35.5463 6.67595 35.3714 6.60352 35.1891 6.60352H22.7539C22.5716 6.60352 22.3967 6.67595 22.2678 6.80488C22.1388 6.93381 22.0664 7.10868 22.0664 7.29102C22.0664 7.47335 22.1388 7.64822 22.2678 7.77715C22.3967 7.90608 22.5716 7.97852 22.7539 7.97852Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M22.7539 11.8223H35.1891C35.3714 11.8223 35.5463 11.7498 35.6752 11.6209C35.8041 11.492 35.8766 11.3171 35.8766 11.1348C35.8766 10.9524 35.8041 10.7776 35.6752 10.6486C35.5463 10.5197 35.3714 10.4473 35.1891 10.4473H22.7539C22.5716 10.4473 22.3967 10.5197 22.2678 10.6486C22.1388 10.7776 22.0664 10.9524 22.0664 11.1348C22.0664 11.3171 22.1388 11.492 22.2678 11.6209C22.3967 11.7498 22.5716 11.8223 22.7539 11.8223Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M22.7539 20.7656H35.1891C35.3714 20.7656 35.5463 20.6932 35.6752 20.5643C35.8041 20.4353 35.8766 20.2605 35.8766 20.0781C35.8766 19.8958 35.8041 19.7209 35.6752 19.592C35.5463 19.4631 35.3714 19.3906 35.1891 19.3906H22.7539C22.5716 19.3906 22.3967 19.4631 22.2678 19.592C22.1388 19.7209 22.0664 19.8958 22.0664 20.0781C22.0664 20.2605 22.1388 20.4353 22.2678 20.5643C22.3967 20.6932 22.5716 20.7656 22.7539 20.7656Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M22.7539 24.6094H35.1891C35.3714 24.6094 35.5463 24.5369 35.6752 24.408C35.8041 24.2791 35.8766 24.1042 35.8766 23.9219C35.8766 23.7395 35.8041 23.5647 35.6752 23.4357C35.5463 23.3068 35.3714 23.2344 35.1891 23.2344H22.7539C22.5716 23.2344 22.3967 23.3068 22.2678 23.4357C22.1388 23.5647 22.0664 23.7395 22.0664 23.9219C22.0664 24.1042 22.1388 24.2791 22.2678 24.408C22.3967 24.5369 22.5716 24.6094 22.7539 24.6094Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M9.95117 38.9121C9.95117 39.0944 10.0236 39.2693 10.1525 39.3982C10.2815 39.5272 10.4563 39.5996 10.6387 39.5996H18.8887C19.071 39.5996 19.2459 39.5272 19.3748 39.3982C19.5037 39.2693 19.5762 39.0944 19.5762 38.9121V36.1621C19.5762 35.9798 19.5037 35.8049 19.3748 35.676C19.2459 35.547 19.071 35.4746 18.8887 35.4746C18.7063 35.4746 18.5315 35.547 18.4025 35.676C18.2736 35.8049 18.2012 35.9798 18.2012 36.1621V38.2246H11.3262V31.3496H18.2012V33.4121C18.2012 33.5944 18.2736 33.7693 18.4025 33.8982C18.5315 34.0272 18.7063 34.0996 18.8887 34.0996C19.071 34.0996 19.2459 34.0272 19.3748 33.8982C19.5037 33.7693 19.5762 33.5944 19.5762 33.4121V30.6621C19.5762 30.4798 19.5037 30.3049 19.3748 30.176C19.2459 30.047 19.071 29.9746 18.8887 29.9746H10.6387C10.4563 29.9746 10.2815 30.047 10.1525 30.176C10.0236 30.3049 9.95117 30.4798 9.95117 30.6621V38.9121Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M22.7539 33.5527H35.1891C35.3714 33.5527 35.5463 33.4803 35.6752 33.3514C35.8041 33.2224 35.8766 33.0476 35.8766 32.8652C35.8766 32.6829 35.8041 32.508 35.6752 32.3791C35.5463 32.2502 35.3714 32.1777 35.1891 32.1777H22.7539C22.5716 32.1777 22.3967 32.2502 22.2678 32.3791C22.1388 32.508 22.0664 32.6829 22.0664 32.8652C22.0664 33.0476 22.1388 33.2224 22.2678 33.3514C22.3967 33.4803 22.5716 33.5527 22.7539 33.5527Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M22.7539 37.3965H35.1891C35.3714 37.3965 35.5463 37.3241 35.6752 37.1951C35.8041 37.0662 35.8766 36.8913 35.8766 36.709C35.8766 36.5266 35.8041 36.3518 35.6752 36.2228C35.5463 36.0939 35.3714 36.0215 35.1891 36.0215H22.7539C22.5716 36.0215 22.3967 36.0939 22.2678 36.2228C22.1388 36.3518 22.0664 36.5266 22.0664 36.709C22.0664 36.8913 22.1388 37.0662 22.2678 37.1951C22.3967 37.3241 22.5716 37.3965 22.7539 37.3965Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M18.9795 17.1875H10.7295C10.5472 17.1875 10.3723 17.2599 10.2434 17.3889C10.1144 17.5178 10.042 17.6927 10.042 17.875V26.125C10.042 26.3073 10.1144 26.4822 10.2434 26.6111C10.3723 26.7401 10.5472 26.8125 10.7295 26.8125H18.9795C19.1618 26.8125 19.3367 26.7401 19.4656 26.6111C19.5946 26.4822 19.667 26.3073 19.667 26.125V17.875C19.667 17.6927 19.5946 17.5178 19.4656 17.3889C19.3367 17.2599 19.1618 17.1875 18.9795 17.1875ZM18.292 25.4375H11.417V18.5625H18.292V25.4375Z" fill={active ? "#FF671E" : "#666666"}/>
        <path d="M12.214 23.2305L13.7739 24.4698C13.8955 24.5665 14.0463 24.6191 14.2016 24.6191C14.228 24.6191 14.2545 24.6175 14.2807 24.6145C14.3706 24.6041 14.4575 24.5761 14.5365 24.532C14.6155 24.4879 14.685 24.4287 14.741 24.3578L17.6931 20.6215C17.8061 20.4785 17.8577 20.2963 17.8364 20.1152C17.8152 19.9341 17.7229 19.7689 17.5798 19.6559C17.4367 19.5428 17.2546 19.4913 17.0735 19.5125C16.8924 19.5337 16.7272 19.626 16.6141 19.7691L14.0895 22.9645L13.0693 22.1539C12.9265 22.0405 12.7445 21.9884 12.5634 22.0092C12.3822 22.0299 12.2167 22.1218 12.1033 22.2645C11.9899 22.4073 11.9378 22.5893 11.9586 22.7704C11.9793 22.9516 12.0712 23.1171 12.214 23.2305Z" fill={active ? "#FF671E" : "#666666"}/>
      </g>
      <defs>
        <clipPath id="clip0_1944_13893">
          <rect width="44" height="44" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
});

export default PurchaseConfirmed;
