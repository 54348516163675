import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserService from "@service/API/User";
import { signIn, signOut } from "src/store/User";
import { RootState } from "@store/configureStore";
import { useNavigate } from "react-router-dom";
import { injectNavigate } from "src/core/http/AxiosConfig";
import { injectNavigate1 } from "src/App";

export default function Auth({ children }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogined = useSelector(
    (state: RootState) => state.UserReducer.isLogin
  );
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    
    function initAuth() {
      UserService.handleAuthenticated();
      if (UserService.isAuthenticated()) {
        dispatch(signIn({}));
      } else dispatch(signOut());
    }
    initAuth();
    injectNavigate(navigate);
    injectNavigate1(navigate);
  }, [dispatch]);


  return <> {children} </>;
}
