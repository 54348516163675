import React, { FC, useMemo, useState } from "react";
import { Col, Row } from "antd";
import { transformStringDate } from "src/helper";
import { useSelector } from "react-redux";
import moment from "moment";
import { DATE_FORMAT } from "src/configs";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.scss";
import AppNavigation from "../../../../components/AppNavigation";
import { RootState } from "../../../../store/configureStore";
import { ProfileField } from "../../model";
import ProfileView from "./View";
import useHideBottomBar from "../../../../hooks/useHideBottomBar";

const MyPageProfile: FC = ({ ...props }) => {
  useHideBottomBar(true);
  const navigate = useNavigate();
  const userData = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const profileLabels = [
    { field: ProfileField.EMAIL_ADDRESS, label: "メールアドレス", value: "" },
    { field: ProfileField.NAME, label: "お名前", value: "" },
    { field: ProfileField.DATE_OF_BIRTH, label: "生年月日", value: "" },
    { field: ProfileField.PHONE_NUMBER, label: "電話番号", value: "" },
    { field: ProfileField.COMPANY_NAME, label: "会社名", value: "" },
    { field: ProfileField.DEPARTMENT, label: "部署", value: "" },
    { field: ProfileField.POSITION, label: "役職", value: "" },
    { field: ProfileField.ZIP_CODE, label: "郵便番号", value: "" },
    { field: ProfileField.CITY, label: "都道府県", value: "" },
    { field: ProfileField.DISTRICT, label: "市区町村", value: "" },
    { field: ProfileField.STREET, label: "地名番地", value: "" },
    { field: ProfileField.BUILDING_NAME, label: "建物名", value: "" },
  ];

  const transformedUserInformation = useMemo(() => {
    const clonedUserData = { ...userData };
    clonedUserData.dateOfBirth = transformStringDate(
      moment(clonedUserData.dateOfBirth, DATE_FORMAT).format(DATE_FORMAT)
    );
    const userFieldValues = Object.entries(clonedUserData as {}).map(
      ([field, value]) => ({ field, value })
    );
    profileLabels.forEach((profileLabel) => {
      userFieldValues.forEach((userFieldValue) => {
        if (profileLabel.field === userFieldValue.field) {
          (profileLabel as any).value = userFieldValue.value;
        }
      });
    });
    return profileLabels;
  }, [userData]);

  return (
    <div className="ui-my-page-profile">
      <AppNavigation onClick={() => navigate("/my-page")} title="会員情報" />
      <ProfileView data={transformedUserInformation} />
    </div>
  );
};

export default MyPageProfile;

MyPageProfile.defaultProps = {};
