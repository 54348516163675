/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "antd";

import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  login as loginAction,
  getUserInfo as getUserInfoAction,
} from "@store/User";
import { useDispatch, useSelector } from "react-redux";
import "./login.scss";
import { DEEP_LINK, DEEP_LINK_SCREEN, PATH_NAME, REGEX_PASSWORD, } from "src/configs";
import { AppDispatch, RootState } from "@store/configureStore";
import AppTextField from "src/components/Form/AppTextField";
import AppPassword from "src/components/Form/AppPassword";
import AppButton from "src/components/AppButton";
import { setAppLoading } from "@store/State";
import AppCheckbox from "@components/Form/AppCheckbox";
import { buildLink, getOS, handleOpenApp } from "src/helper";
import AppModal from "src/components/AppModal";
import ChatService from '@service/API/Chat';
import { Collection } from '@models/chat.model';
import UserService from "../../services/API/User/index";

export default function LoginScreen() {
  const [isLoading, setLoading] = useState(false);
  const [showApp, setShowApp] = useState<boolean>(false);
  const [href, setHref] = useState<string>("");
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [searchParam] = useSearchParams();
  const disableModal = searchParam.get("disableModal");
  const location = useLocation();
  const fromMail = searchParam.get("fromMail");
  const deviceToken = useSelector(
    (state: RootState) => state.GlobalReducer.deviceToken
  );
  useEffect(() => {
    let urlDeepLink = "";
    const screen = DEEP_LINK_SCREEN.find((item) =>
      searchParam.get("returnUrl")?.includes(item)
    );
    const locationSearch = searchParam.get("returnUrl") || '';
    const arrURL = locationSearch.split('/');
    const dataURL = arrURL[arrURL.length - 1];
    const arrData = dataURL.split('?');
    const id = arrData[0];
    const params = arrData[1];
    switch (screen) {
      case DEEP_LINK.SALE_HISTORY:
        urlDeepLink = `haraseikouapp://home/salesHistory${id}`;
        break;
      case DEEP_LINK.CONFIRM_CONTACT:
        urlDeepLink = `haraseikouapp://home/confirmContact${id}`;
        break;
      case DEEP_LINK.PURCHASE_HISTORY:
        urlDeepLink = `haraseikouapp://home/purchaseHistory${id}`;
        break;
      case DEEP_LINK.VISIT_ONLINE_PREPARATION:
        urlDeepLink = `haraseikouapp://home/visitOnline${params}`;
        break;
      default:
    }
    setHref(urlDeepLink);
    if (!disableModal && urlDeepLink && params?.includes('fromMail')) {
      handleOpenApp(urlDeepLink, setShowApp);
    }
  }, []);

  async function getUserInfo() {
    const response = await dispatch(getUserInfoAction());

    if (getUserInfoAction.fulfilled.match(response)) {
      if (deviceToken) {
        const { id } = response.payload.result;
        const isUserExisted = await ChatService.isUserExisted(id);
        const userIndex = await ChatService.getDocumentIndex(Collection.USERS, "userId", id);
        if (isUserExisted) {
          await ChatService.updateUserToken(userIndex as string, deviceToken as string);
        } else {
          await ChatService.addUser(id, deviceToken as string);
        }
      }
      setLoading(false);
      if (searchParam.get("returnUrl")) {
        navigate(searchParam.get("returnUrl")!);
        return;
      }
      navigate(PATH_NAME.DEFAULT);
    }

    if (getUserInfoAction.rejected.match(response)) {
      // setError(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (UserService.isAuthenticated()) {
      navigate("/dashboard");
    }
  }, []);

  async function login() {
    dispatch(setAppLoading(true));
    const response = await dispatch(
      loginAction({
        email: form.getFieldValue("email"),
        password: form.getFieldValue("password"),
        remember: form.getFieldValue("remember"),
      })
    );

    if (loginAction.fulfilled.match(response)) {
      dispatch(setAppLoading(false));
      getUserInfo();

    }

    if (loginAction.rejected.match(response)) {
      // setError(true);
      dispatch(setAppLoading(false));
    }
  }

  function signUp() {
    navigate(PATH_NAME.SIGN_UP);
  }

  function resetPassword() {
    navigate("/reset-password");
  }

  const checkLengthPassword = (_: any, value: string) => {
    if (!!value.match(REGEX_PASSWORD) === false) {
      return Promise.reject(translate("signup.form.validate.password.length"));
    }
    return Promise.resolve();
  };

  return (
    <div className="login-container">
      <AppModal
        visible={showApp}
        title="このページを“マシプラ”で開きますか？"
        cancelText="キャンセル"
        onCancel={() => setShowApp(false)}
        onOk={() => {
          setShowApp(false);
          buildLink(href);
        }}
        okText={
          getOS() === "Android" ? (
            <a href={href}>
              <span style={{ color: "#fff" }}>あける</span>
            </a>
          ) : (
            <span style={{ color: "#fff" }}>あける</span>
          )
        }
      />
      <div className="icon-container">
        <img src="/assets/images/icon-app.svg" alt="" />
      </div>
      <Form form={form} layout="vertical" onFinish={login} autoComplete="off">
        <div className="login-form">
          <div className="row-login">
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <AppTextField
                  label={translate("login.email")}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: translate("login.email.validate1"),
                    },
                    {
                      type: "email",
                      message: translate("login.email.validate2"),
                    },
                  ]}
                  formgroup={form}
                  maxLength={255}
                />
              </Col>
            </Row>
          </div>

          <div className="row-login">
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <AppPassword
                  label={translate("login.password")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: translate("login.password.validate1"),
                    },
                    {
                      max: 255,
                      message: translate("login.password.validate2"),
                    },
                    { validator: checkLengthPassword },
                  ]}
                  formgroup={form}
                  maxLength={255}
                  autoComplete="off"
                />
              </Col>
            </Row>
          </div>

          <div className="remember-me">
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <AppCheckbox
                  name="remember"
                  label=""
                  type="default"
                  options={[
                    {
                      label: (
                        <span className="bold remember-me__text">
                          {translate("login.rememberMe")}
                        </span>
                      ),
                      value: true,
                    },
                  ]}
                  formgroup={form}
                />
              </Col>
            </Row>
          </div>

          <Form.Item shouldUpdate className="btn-login">
            {() => (
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <AppButton
                    loading={isLoading}
                    buttontype="primary"
                    htmlType="submit"
                  >
                    {translate("login.login")}
                  </AppButton>
                </Col>
              </Row>
            )}
          </Form.Item>

          <div className="text-forgot text-body-4 color-primary-3">
            <span onClick={resetPassword}>
              {translate("login.forgot.password")}
            </span>
          </div>

          <p className="text-or">{translate("login.or")}</p>
          <p className="text-or-unSignUp">{translate("login.or.unSignUp")}</p>

          <Row gutter={[16, 16]} className="login-withoutSignIn">
            <Col xs={24}>
              <AppButton
                buttontype="primary"
                onClick={() => {
                  navigate("/");
                }}
              >
                {translate("login.withoutSignin")}
              </AppButton>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="btn-signUp">
            <Col xs={24}>
              <AppButton buttontype="secondary" onClick={() => signUp()}>
                {translate("login.signUp.text")}
              </AppButton>
            </Col>
          </Row>
        </div>
      </Form>
      <div className="text-sign-in" onClick={() => {
        const currentLanguage = document.querySelector('html')?.getAttribute('lang');
        if (currentLanguage !== 'ja') {
          signUp();
        }
      }}>
        <span>{translate("login.signin.text1")}</span>&nbsp;
        <span
          className="bold color-primary-3 text-body-4"
          onClick={() => signUp()}
        >
          {translate("login.signin.text2")}
        </span>
      </div>
    </div>
  );
}
