import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Col, Form, FormInstance, Row, Select, SelectProps, Modal } from "antd";
import moment from "moment";
import {
  DATE_FORMAT,
  DEFAULT_YEAR_DATE_PICKER,
  END_YEAR,
  MONTHS_IN_A_YEAR,
} from "src/configs";
import { ReactComponent as ArrowDown } from "@components/Icons/arrow-down.svg";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useMediaQuery } from "react-responsive";
import "./index.scss";

interface Props extends SelectProps {
  name: string;
  label?: string;
  form?: FormInstance;
  rules?: object[];
  values: moment.Moment | null;
  startyear?: number;
  endyear?: number;
  validatetrigger?: Array<string>;
  type?: "birthday" | "feature" | "default";
  onChangeValue?: (date: any) => void;
  extra?: string;
}

const AppDatePicker = forwardRef((props: Props, ref) => {
  const {
    name,
    label,
    form,
    rules,
    values,
    startyear,
    endyear,
    type,
    disabled,
    validatetrigger,
    onChangeValue,
    extra,
  } = props;
  const { Option } = Select;
  const [selectedYear, setSelectedYear] = useState<number | undefined>(() => undefined);
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<number>();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const [yearVisible, setYearVisible] = useState<boolean>(false);
  const [monthVisible, setMonthVisible] = useState<boolean>(false);
  const [dayVisible, setDayVisible] = useState<boolean>(false);

  const daysInSelectedMonth = useMemo(() => {
    const generatedDays = [];
    let startDayOfMonth = 1;
    const endDayOfMonth = moment(
      `${selectedYear} - ${selectedMonth}`,
      "YYYY-MM"
    ).daysInMonth();
    if (
      type === "feature" &&
      moment().format("YYYY/MM") ===
        moment(`${selectedYear}/${selectedMonth}`).format("YYYY/MM")
    ) {
      startDayOfMonth = moment().date();
    }

    for (let i = startDayOfMonth; i <= endDayOfMonth; i++) {
      generatedDays.push(i);
    }
    return generatedDays;
  }, [selectedYear, selectedMonth]);

  const desiredMonths = useMemo(() => {
    const generateMonths = [];
    let startMonth = 1;
    if (type === "feature" && selectedYear === moment().year()) {
      startMonth = moment().month() + 1;
    }
    for (let i = startMonth; i <= MONTHS_IN_A_YEAR; i++) {
      generateMonths.push(i);
    }
    return generateMonths;
  }, [selectedYear]);

  const desiredYears = useMemo(() => {
    const result: Array<number> = [];
    for (let i = startyear as number; i >= (endyear as number); i--) {
      result.push(i);
    }
    return result;
  }, []);

  const isValidField = useMemo(
    () => !!(typeof selectedMonth === "number" && selectedYear && selectedDate),
    [selectedDate, selectedMonth, selectedYear]
  );
  const isValidDate = useMemo(
    () =>
      moment(
        `${selectedYear}/${selectedMonth}/${selectedDate}`,
        DATE_FORMAT
      ).isValid(),
    [selectedDate, selectedMonth, selectedYear]
  );

  const handleBlur = () => {
    if (isValidField) {
      if (isValidDate) {
        const date = moment().set({
          year: selectedYear,
          month: (selectedMonth as number) - 1,
          date: selectedDate,
          hour: moment().hour(),
        });

        form?.setFieldsValue({
          [name]: date,
        });

        form?.validateFields([name]);

        if (onChangeValue) {
          onChangeValue(date);
        }
      } else {
        setSelectedDate(undefined);
        form?.setFieldsValue({ [name]: undefined });
        if (onChangeValue) {
          onChangeValue(undefined);
        }
      }
    }
  };

  useEffect(() => {
    if (values && values instanceof moment) {
      setSelectedYear((values as moment.Moment).year());
      setSelectedMonth((values as moment.Moment).month() + 1);
      setSelectedDate((values as moment.Moment).date());
    } else if (!values) {
      if (type !== "birthday") {
        setSelectedYear(undefined);
      }
      setSelectedMonth(undefined);
      setSelectedDate(undefined);
    }
  }, [values]);

  useEffect(() => {
    handleBlur();
  }, [selectedYear, selectedMonth, selectedDate]);

  useImperativeHandle(ref, () => ({
    clearDataForm() {
      setSelectedYear(undefined);
      setSelectedMonth(undefined);
      setSelectedDate(undefined);
      form?.setFields([
        {
          name: name,
          errors: [],
        },
      ]);
    },
  }));

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      labelCol={{ span: 24 }}
      validateTrigger={[]}
      extra={extra}
    >
      <Row gutter={[32, 32]}>
        <Col xs={8} id="year-picker">
          <Select
            getPopupContainer={() =>
              document.getElementById("year-picker") as HTMLElement
            }
            onChange={(year) => {
              console.log('year', year);
              setSelectedYear(year);
              setYearVisible(false);
            }}
            onBlur={handleBlur}
            value={selectedYear}
            className="app-select"
            dropdownClassName={classnames("p-0 br-8")}
            // dropdownClassName="ant-select-custom-dropdown"
            suffixIcon={<ArrowDown />}
            placeholder={t("common.year")}
            // fix virtual scroll
            listItemHeight={isMobile ? 48 : 32}
            listHeight={isMobile ? 480 : 256}
            dropdownRender={menu => (
              isMobile ? (
                <Modal visible={yearVisible} className="datepicker-dropdown-modal" closable={false} centered destroyOnClose>
                  <div className={ classnames("ant-select-custom-dropdown", isMobile && "ant-select-custom-dropdown-mobile") }>
                    {menu}
                  </div>
                </Modal>
              ) : (
                <div className="ant-select-custom-dropdown">{menu}</div>
              )
            )}
            onDropdownVisibleChange={(visible) => {
              setYearVisible(visible);
            }}
            {...props}
          >
            {desiredYears &&
              desiredYears.length &&
              desiredYears.map((desiredYear, index) => (
                <Option key={index} value={desiredYear}>
                  {desiredYear}
                </Option>
              ))}
          </Select>
        </Col>
        <Col xs={8} id="month-picker">
          <Select
            getPopupContainer={() =>
              document.getElementById("month-picker") as HTMLElement
            }
            onChange={(month) => {
              setMonthVisible(false);
              setSelectedMonth(month);
            }}
            onBlur={handleBlur}
            value={selectedMonth}
            className="app-select"
            dropdownClassName={classnames("p-0 br-8")}
            // dropdownClassName="ant-select-custom-dropdown"
            suffixIcon={<ArrowDown />}
            placeholder={t("common.month")}
            // fix virtual scroll
            listItemHeight={isMobile ? 48 : 32}
            listHeight={isMobile ? 480 : 256}
            dropdownRender={menu => (
              isMobile ? (
                <Modal visible={monthVisible} className="datepicker-dropdown-modal" closable={false} centered destroyOnClose>
                  <div className={ classnames("ant-select-custom-dropdown", isMobile && "ant-select-custom-dropdown-mobile") }>
                    {menu}
                  </div>
                </Modal>
              ) : (
                <div className="ant-select-custom-dropdown">{menu}</div>
              )
            )}
            onDropdownVisibleChange={(visible) => {
              setMonthVisible(visible);
            }}
            {...props}
          >
            {desiredMonths.map((month, index) => (
              <Option key={index} value={month}>
                {String(month).padStart(2, "0")}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={8} id="day-picker">
          <Select
            getPopupContainer={() =>
              document.getElementById("day-picker") as HTMLElement
            }
            onChange={(date) => {
              setSelectedDate(date);
              setDayVisible(false);
            }}
            onBlur={() => {
              handleBlur();
              form?.validateFields([name]);
            }}
            value={selectedDate}
            className="app-select"
            dropdownClassName={classnames("p-0 br-8")}
            // dropdownClassName="ant-select-custom-dropdown"
            suffixIcon={<ArrowDown />}
            placeholder={t("common.date")}
            // fix virtual scroll
            listItemHeight={isMobile ? 48 : 32}
            listHeight={isMobile ? 480 : 256}
            dropdownRender={menu => (
              isMobile ? (
                <Modal visible={dayVisible} className="datepicker-dropdown-modal" closable={false} centered destroyOnClose>
                  <div className={ classnames("ant-select-custom-dropdown", isMobile && "ant-select-custom-dropdown-mobile") }>
                    {menu}
                  </div>
                </Modal>
              ) : (
                <div className="ant-select-custom-dropdown">{menu}</div>
              )
            )}
            onDropdownVisibleChange={(visible) => {
              setDayVisible(visible);
            }}
            {...props}
            disabled={
              !selectedYear || typeof selectedMonth !== "number" || disabled
            }
          >
            {daysInSelectedMonth &&
              daysInSelectedMonth.map((day, index) => (
                <Option key={index} value={day}>
                  {String(day).padStart(2, "0")}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
    </Form.Item>
  );
});

export default AppDatePicker;

AppDatePicker.defaultProps = {
  startyear: moment().subtract(1, "year").year(),
  endyear: END_YEAR,
  type: "birthday",
  validatetrigger: ["onBlur"],
};
