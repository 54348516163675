import React, { FC, useRef, useState } from "react";
import { Button, Col, Popover, Row } from "antd";
import classNames from "classnames";
import { DeleteOutlined, EyeOutlined, HeartOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { PATH_NAME } from "src/configs";
import NumberFormat from "react-number-format";
import AppModal from "src/components/AppModal";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import ProductService from "src/services/API/Product";
import { Desktop, Mobile } from "src/layouts/break-point";
import { useDispatch, useSelector } from "react-redux";
import { setAppLoading } from "src/store/State";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "src/store/configureStore";
import { updateLike } from "src/store/Product";
import { ReactComponent as Delete } from "../../../../components/Icons/delete-circle.svg";
import { ReactComponent as Trash } from "../../../../components/Icons/button-delete.svg";
import { ReactComponent as Action } from "../../../../components/Icons/three-dots.svg";
import { FavoriteItems } from "./Favorite.model";

interface Props {
  item?: FavoriteItems;
  onReload?: () => void;
}

const FavoriteItem: FC<Props> = ({ ...props }) => {
  const { item, onReload } = props;
  const navigate = useNavigate();
  const [modalWarningVisible, setModalWarningVisible] =
    useState<boolean>(false);
  const [modalSuccessVisible, setModalSuccessVisible] =
    useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const { t: translate } = useTranslation();

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );
  
  const handleRemoveLike = async () => {
    dispatch(setAppLoading(true));
    try {
      await dispatch(updateLike({ ...item, id: item?.productId, canRemoveFromFavorite: true } as FavoriteItems));
      dispatch(setAppLoading(false));
      setModalSuccessVisible(true);
    } catch (e) {
      console.log(e);
    } finally {
      setModalWarningVisible(false);
      dispatch(setAppLoading(false));
    }
  };

  function getImage() {
    if (!item || !(item.productMedias || []).length) {
      return "";
    }
    const firstImage = item.productMedias.filter((f) => f.type === 1)[0];
    return firstImage ? firstImage.url.concat(sasCode as string) : "";
  }

  return (
    <>
      <AppModal
        visible={modalWarningVisible}
        icon={<Warning />}
        title={translate("my.page.favorite.popup.warning.title")}
        content={translate("my.page.favorite.popup.warning.content")}
        okText={translate("my.page.favorite.popup.warning.okText")}
        cancelText={translate("my.page.favorite.popup.warning.cancelText")}
        onCancel={() => setModalWarningVisible(false)}
        onOk={handleRemoveLike}
      />
      <AppModal
        visible={modalSuccessVisible}
        icon={<Success />}
        title={translate("my.page.favorite.popup.success.title")}
        content=""
        okText={translate("my.page.favorite.popup.success.okText")}
        cancelText=""
        onOk={() => {
          setModalSuccessVisible(false);
          onReload?.();
        }}
      />
      <Col xs={24} md={8}>
        <div
          className={classNames(
            "favorite-item__wrapper",
            item?.isDisable && !isDesktop && "bg-disabled"
          )}
        >
          <div
            {...(!isDesktop &&
              !item?.isDisable && {
                onClick: () => {
                  navigate(`/product-detail/${item?.productId}`);
                },
              })}
            className="favorite-item__product"
          >
            <div
              className="favorite-item__product-action"
              onClick={(e) => {
                e.stopPropagation();
                setModalWarningVisible(true);
              }}
            >
              <Trash />
            </div>
            <div className="favorite-item__product-image">
              <img
                src={getImage()}
                className="favorite-item__product-image-content"
                alt=""
              />
              <div
                className="favorite-item__product-bin"
                onClick={() => {
                  setModalWarningVisible(true);
                }}
              >
                <Trash />
              </div>
            </div>
            <div className="favorite-item__product-information">
              <div className="favorite-item__product-name three-dot">
                {item?.title}
              </div>

              <div className={classNames(item?.isDisable && "pb-8")}>
                <div className="favorite-item__product-model three-dot">
                  {item?.maker}
                </div>
                <div className="favorite-item__product-year">
                  {item?.yearOfManufacture
                    ? item?.yearOfManufacture >= 1980
                      ? `${item?.yearOfManufacture}年式`
                      : "1980年以前"
                    : "年式不明"}
                </div>
              </div>
              <div className="favorite-item__product-price">
                <span className="favorite-item__product-price-title">
                  価格:
                </span>
                <span className="text-caption-2">¥</span>
                <NumberFormat
                  value={item?.price}
                  displayType="text"
                  thousandSeparator
                />
              </div>
              <div className="favorite-item__product-status">
                <div className="favorite-item__product-views">
                  <EyeOutlined />
                  <span style={{ marginLeft: "4px" }}>
                    {item?.viewCount || 0}
                  </span>
                </div>
                <div className="favorite-item__product-favorite">
                  <HeartOutlined />
                  <span style={{ marginLeft: "4px" }}>
                    {item?.favoriteCount || 0}
                  </span>
                </div>
              </div>
              {item?.isDisable && (
                <div className="favorite-item__product-disabled">
                  <span>{translate("my.page.favorite.note.disabled")}</span>
                </div>
              )}
              <Desktop>
                {!item?.isDisable && (
                  <Button
                    onClick={() =>
                      navigate(`/product-detail/${item?.productId}`)
                    }
                    className={classNames(
                      "button-primary",
                      item?.isDisable && "product-btn-disable"
                    )}
                  >
                    {translate("my.page.favorite.button.go.detail")}
                  </Button>
                )}
              </Desktop>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default FavoriteItem;

FavoriteItem.defaultProps = {};
