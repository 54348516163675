import { useEffect } from "react";

const useScrollTop = () => {
  useEffect(() => {
    const scroll = document.getElementById("appLayout") as HTMLDivElement;

    if (scroll) {
      scroll.scrollTo({ top: 0 });
    }
  }, []);
};

export default useScrollTop;
