import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DATE_TIME_FORMAT } from "src/configs";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { useTranslation } from "react-i18next";
import AppButton from "src/components/AppButton";
import { Col, Row } from "antd";
import { Desktop, Mobile } from "src/layouts/break-point";
import AppRow from "src/components/AppRow";
import AppFieldset from "src/components/AppFieldset";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import { setAppLoading } from "src/store/State";
import UserService from "src/services/API/User";
import { ReactComponent as InfoIcon } from "../../../../components/Icons/info-icon-2.svg";
import AppNavigation from "../../../../components/AppNavigation";
import { AppDispatch, RootState } from "../../../../store/configureStore";
import { BankAccountField, BankAccountInformation } from "../../model";
import useHideBottomBar from "../../../../hooks/useHideBottomBar";

const MyBankAccount: FC = ({ ...props }) => {
  useHideBottomBar(true);
  const navigate = useNavigate();
  const userData = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [bankInfo, setBankInfo] = useState<BankAccountInformation>();

  async function getData() {
    dispatch(setAppLoading(true));
    try {
      const response = await UserService.getBankInfo();
      setBankInfo(response.data.result);
      dispatch(setAppLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setAppLoading(false));
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    document.body.scrollTo({ top: 0 });
  }, []);

  const appScroll = useRef<HTMLElement | undefined>(undefined);

  useEffect(() => {
    const scroll = document.getElementById("appLayout") || undefined;
    appScroll.current = scroll;
  }, []);

  const bankAccountLabels = [
    {
      field: BankAccountField.BANK_NAME,
      label: t("my.page.bank.account.label.bank.name"),
      value: "",
    },
    {
      field: BankAccountField.BRAND_NAME,
      label: t("my.page.bank.account.label.branch.name"),
      value: "",
    },
    {
      field: BankAccountField.ACCOUNT_TYPE,
      label: t("my.page.bank.account.label.account.type"),
      value: "",
    },
    {
      field: BankAccountField.ACCOUNT_NUMBER,
      label: t("my.page.bank.account.label.account.number"),
      value: "",
    },
    {
      field: BankAccountField.ACCOUNT_NAME,
      label: t("my.page.bank.account.label.account.name"),
      value: "",
    },
    {
      field: BankAccountField.UPDATE_TIME,
      label: t("my.page.bank.account.label.account.time"),
      value: "",
    },
  ];

  const transformedBankInformation = useMemo(() => {
    const clonedBankData = { ...bankInfo };
    clonedBankData.updateTime = bankInfo?.updateTime
      ? moment(clonedBankData.updateTime).format(DATE_TIME_FORMAT)
      : "";
    const accountType = {
      1: t("my.page.bank.account.label.account.type.normal"),
      2: t("my.page.bank.account.label.account.type.temporarily"),
    };
    // @ts-ignore
    clonedBankData.bankAccountType = ( bankInfo && bankInfo?.bankAccountType !== 0 ) ? accountType[bankInfo.bankAccountType] : "";
    clonedBankData.bankName = bankInfo?.bankName
      ? `${bankInfo?.bankCode}:${bankInfo?.bankName}`
      : "";
    clonedBankData.branchName = bankInfo?.branchName
      ? `${bankInfo?.branchCode}:${bankInfo?.branchName}`
      : "";
    const bankAccountFieldValues = Object.entries(clonedBankData as {}).map(
      ([field, value]) => ({ field, value })
    );
    bankAccountLabels.forEach((bankAccountLabel) => {
      bankAccountFieldValues.forEach((bankAccountFieldValue) => {
        if (bankAccountLabel.field === bankAccountFieldValue.field) {
          (bankAccountLabel as any).value = bankAccountFieldValue.value;
        }
      });
    });
    return bankAccountLabels;
  }, [bankInfo]);

  return (
    <div className="ui-my-page-bank">
      <AppNavigation
        onClick={() => navigate("/my-page")}
        title={t("my.page.bank.account")}
      />
      <AppPullToRefresh
        onRefresh={() => {
          getData();
        }}
      >
        <div className="ui-my-page-bank-view">
          <Row gutter={[32, 32]} justify="center" className="row-parent">
            <Col xs={24} className="col-parent">
              <div className="ui-my-page-bank-view__wrap">
                <Mobile>
                  <div className="ui-my-page-bank-view__id">
                    会員ID: {userData?.id}
                  </div>
                  <div className="ui-my-page-bank-view__guideline">
                    <span>
                      <InfoIcon />
                    </span>
                    <span>{t("my.page.bank.account.guideline")}</span>
                  </div>
                  <div className="ui-my-page-bank-view__overview">
                    <AppFieldset>
                      {transformedBankInformation &&
                        transformedBankInformation.length &&
                        transformedBankInformation.map(
                          ({ label, value }, index) => (
                            <AppRow
                              key={index}
                              label={label}
                              value={value}
                              hasBorderBottom={
                                index !== transformedBankInformation.length - 1
                              }
                            />
                          )
                        )}
                    </AppFieldset>
                  </div>
                </Mobile>

                <Desktop>
                  <div className="ui-my-page-bank-view__guideline">
                    <span>
                      <InfoIcon />
                    </span>
                    <span>{t("my.page.bank.account.guideline")}</span>
                  </div>
                  <Row gutter={[32, 32]}>
                    {transformedBankInformation &&
                      transformedBankInformation.length &&
                      transformedBankInformation.map(
                        ({ label, value }, index) => (
                          <Col key={index} xs={12}>
                            <AppRow
                              key={index}
                              label={label}
                              value={value}
                              hasBorderBottom={true}
                              displayBlock
                            />
                          </Col>
                        )
                      )}
                  </Row>
                </Desktop>
              </div>

              <div className="ui-my-page-bank-view__action">
                  <Row gutter={[32, 32]} justify="center">
                    <Col xs={24} md={10} xl={10}>
                      <AppButton
                        buttontype="primary"
                        onClick={() => navigate(`/my-page/bank-account/edit`)}
                      >
                        {t("my.page.bank.account.button.edit")}{" "}
                      </AppButton>
                    </Col>
                  </Row>
              </div>
            </Col>
          </Row>
        </div>
      </AppPullToRefresh>
    </div>
  );
};

export default MyBankAccount;

MyBankAccount.defaultProps = {};
