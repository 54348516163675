
import { MessageType } from 'src/models/common.model';


const openURL = (url: string) => {
    if ((window as any).ReactNativeWebView) {
        try {
            const message = { type: MessageType.OPEN_URL, data: url };
            (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
        } catch (e) {
            console.log(`Send message call to app fail ${url}`);
        }
    } else {
        window.open(url)?.focus();
    }

};

function scrollToError() {
    setTimeout(() => {
        const item = document.getElementsByClassName(
            "ant-form-item-has-error"
        )[0];
        if (item) {
            item.scrollIntoView({
                behavior: "smooth",
                block: "center",
                // inline: "start",
            });
        }
    }, 300);
}

function paramsToObject(entries: any) {
    const result: any = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
}

const CommonService = { openURL, scrollToError, paramsToObject };

export default CommonService;