import React, { FC } from "react";

interface Props {
  active?: boolean
}

const UnderInspection: FC<Props> = (({ ...props }) => {
  const { active } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path d="M17.1875 4.125C14.604 4.125 12.0785 4.8911 9.93037 6.32643C7.78225 7.76175 6.108 9.80184 5.11933 12.1887C4.13066 14.5756 3.87198 17.202 4.376 19.7359C4.88002 22.2697 6.1241 24.5973 7.95092 26.4241C9.77775 28.2509 12.1053 29.495 14.6391 29.999C17.173 30.503 19.7994 30.2443 22.1863 29.2557C24.5732 28.267 26.6132 26.5928 28.0486 24.4446C29.4839 22.2965 30.25 19.771 30.25 17.1875C30.2462 13.7243 28.8687 10.404 26.4199 7.95514C23.971 5.50627 20.6507 4.12882 17.1875 4.125ZM17.1875 28.875C14.8759 28.875 12.6163 28.1895 10.6943 26.9053C8.77228 25.6211 7.27426 23.7957 6.38966 21.6601C5.50506 19.5245 5.27361 17.1745 5.72458 14.9074C6.17554 12.6402 7.28867 10.5577 8.92319 8.92319C10.5577 7.28866 12.6402 6.17554 14.9074 5.72457C17.1745 5.27361 19.5245 5.50506 21.6601 6.38966C23.7957 7.27426 25.6211 8.77227 26.9053 10.6943C28.1895 12.6163 28.875 14.8759 28.875 17.1875C28.8715 20.2862 27.6391 23.2569 25.448 25.448C23.2569 27.6391 20.2862 28.8715 17.1875 28.875Z" fill={active ? "#FF671E" : "#666666"}/>
      <path d="M23.375 10.3125H11C10.6353 10.3125 10.2856 10.4574 10.0277 10.7152C9.76987 10.9731 9.625 11.3228 9.625 11.6875V22.6875C9.625 23.0522 9.76987 23.4019 10.0277 23.6598C10.2856 23.9176 10.6353 24.0625 11 24.0625H23.375C23.7397 24.0625 24.0894 23.9176 24.3473 23.6598C24.6051 23.4019 24.75 23.0522 24.75 22.6875V11.6875C24.75 11.3228 24.6051 10.9731 24.3473 10.7152C24.0894 10.4574 23.7397 10.3125 23.375 10.3125ZM23.375 13.0625H19.25V11.6875H23.375V13.0625ZM17.875 11.6875V15.8125H16.5V11.6875H17.875ZM15.125 11.6875V13.0625H11V11.6875H15.125ZM11 22.6875V14.4375H15.125V16.5C15.125 16.6823 15.1974 16.8572 15.3264 16.9861C15.4553 17.1151 15.6302 17.1875 15.8125 17.1875H18.5625C18.7448 17.1875 18.9197 17.1151 19.0486 16.9861C19.1776 16.8572 19.25 16.6823 19.25 16.5V14.4375H23.375V22.6875H11Z" fill={active ? "#FF671E" : "#666666"}/>
      <path d="M22 19.9375H19.25V21.3125H22V19.9375Z" fill={active ? "#FF671E" : "#666666"}/>
      <path d="M17.875 19.9375H16.5V21.3125H17.875V19.9375Z" fill={active ? "#FF671E" : "#666666"}/>
      <path d="M41.9799 37.1203L33.2294 28.3698C33.098 28.2455 32.9241 28.1763 32.7433 28.1763C32.5626 28.1763 32.3886 28.2455 32.2573 28.3698L31.7719 28.8551L29.7245 26.8078C32.1734 23.618 33.3171 19.6162 32.9237 15.6141C32.5303 11.6121 30.6293 7.90953 27.6062 5.25769C24.5831 2.60585 20.6645 1.20331 16.6453 1.33463C12.6261 1.46594 8.80735 3.12128 5.96383 5.9648C3.12031 8.80832 1.46497 12.6271 1.33365 16.6463C1.20233 20.6655 2.60487 24.5841 5.25671 27.6072C7.90856 30.6302 11.6111 32.5313 15.6132 32.9247C19.6152 33.3181 23.6171 32.1744 26.8068 29.7255L28.8541 31.7729L28.3688 32.2583C28.2399 32.3872 28.1675 32.562 28.1675 32.7443C28.1675 32.9266 28.2399 33.1015 28.3688 33.2304L37.1186 41.9809C37.4363 42.3067 37.8155 42.5663 38.2342 42.7445C38.653 42.9226 39.1029 43.0159 39.558 43.0188C40.0131 43.0217 40.4642 42.9342 40.8852 42.7614C41.3062 42.5887 41.6887 42.334 42.0105 42.0122C42.3324 41.6904 42.5871 41.308 42.7599 40.887C42.9328 40.466 43.0203 40.0149 43.0175 39.5598C43.0146 39.1048 42.9214 38.6548 42.7433 38.236C42.5652 37.8172 42.3057 37.438 41.9799 37.1203ZM2.74977 17.1883C2.74977 14.3328 3.59652 11.5414 5.18293 9.1672C6.76934 6.79297 9.02417 4.94248 11.6623 3.84974C14.3004 2.757 17.2033 2.47109 20.0039 3.02816C22.8045 3.58524 25.377 4.96028 27.3961 6.9794C29.4152 8.99852 30.7903 11.571 31.3474 14.3716C31.9044 17.1722 31.6185 20.0751 30.5258 22.7132C29.433 25.3514 27.5826 27.6062 25.2083 29.1926C22.8341 30.779 20.0427 31.6258 17.1873 31.6258C13.3596 31.6214 9.68985 30.0989 6.98324 27.3923C4.27663 24.6857 2.75414 21.016 2.74977 17.1883ZM28.8383 27.8658L30.7998 29.8245L29.8235 30.8008L27.8648 28.8421C28.2031 28.5304 28.5276 28.205 28.8383 27.8658ZM41.0071 41.0088C40.6198 41.3948 40.0953 41.6115 39.5486 41.6115C39.0018 41.6115 38.4773 41.3948 38.09 41.0088L29.827 32.7443L32.7433 29.8279L41.0078 38.0924C41.3944 38.4792 41.6116 39.0037 41.6116 39.5506C41.6116 40.0975 41.3944 40.622 41.0078 41.0088H41.0071Z" fill={active ? "#FF671E" : "#666666"}/>
    </svg>
  );
});

export default UnderInspection;
