import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import reduxThunk from "redux-thunk";
import UserReducer from "./User";
import MyPageReducer from "./MyPage";
import ConfigReducer from "./Config";
import ProductReducer from "./Product";
import ProductFilterReducer from "./Product-Filter";
import GlobalReducer from "./Global";
import AppStateReducer from "./State";
import ChatReducer from "./Chat";
import NoticeReducer from "./Notice";

export type AppDispatch = typeof AppStore.dispatch;
export type RootState = ReturnType<typeof AppStore.getState>;

const reducers = combineReducers({
  UserReducer,
  MyPageReducer,
  ConfigReducer,
  ProductReducer,
  GlobalReducer,
  AppStateReducer,
  ProductFilterReducer,
  ChatReducer,
  NoticeReducer
});

const persistConfig = {
  key: "persist",
  storage,
  whitelist: ["ConfigReducer", "UserReducer"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const AppStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(reduxThunk),
});

export default AppStore;
