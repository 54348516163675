import React, { FC, memo, ReactNode } from "react";
import "./index.scss";

interface Props {
  title?: string;
  children?: ReactNode;
}

const AppFieldset: FC<Props> = memo(({ ...props }) => {
  const { title, children } = props;

  return (
    <div className="ui-fieldset">
      {
        title && (<div className="ui-fieldset__title">{title}</div>)
      }
      <div className="ui-fieldset__content">{children}</div>
    </div>
  );
});

export default AppFieldset; 

AppFieldset.defaultProps = {};
