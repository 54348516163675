import React, { FC, useState, useEffect, useMemo, useRef } from "react";
import { Mobile } from "src/layouts/break-point";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import AppNavigation from "src/components/AppNavigation";
import AppFieldset from "src/components/AppFieldset";
import { Col, Row } from "antd";
import AppButton from "src/components/AppButton";
import AppCountdownCircleTimer from "src/components/AppCountdownCircleTimer";
import ProductService from "src/services/API/Product";
import moment from "moment";
import AppRow from "src/components/AppRow";
import {
  ProductItem,
  ProductTransactionStatus,
} from "src/models/product.model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store/configureStore";
import { setAppLoading, setError } from "src/store/State";
import { useLocation, useNavigate } from "react-router-dom";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import AppModal from "src/components/AppModal";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import { buildLink, getOS, handleOpenApp, paramsDisableModal } from "src/helper";
import { injectHandleOk } from "src/App";
import { get } from "lodash";
import { MessageType } from "src/models/common.model";
import {
  DefaultDuration,
  DefaultTimeCall,
  IsNotPlaying,
  PreCallInfo,
  StatusCall,
} from "./model";

const Prepration: FC = ({ ...props }) => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const [duration, setDuration] = useState<number>(); // seconds
  const [preCallInfo, setPreCallInfo] = useState<PreCallInfo>();
  const [countdown, setCountdown] = useState<boolean>();
  const [product, setProduct] = useState<ProductItem>();
  const [startTime, setStartTime] = useState<string>();
  const [enableJoinCall, setEnableJoinCall] = useState<boolean>(false);
  const [isAnyJoinCall, setIsAnyJoinCall] = useState<boolean>(false);
  const [isAnyJoinCalling, setIsAnyJoinCalling] = useState<boolean>(false);
  const [showApp, setShowApp] = useState<boolean>(false);
  const [isEndCall, setIsEndCall] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { contactId, productId, disableModal } = Object.fromEntries(
    urlSearchParams.entries()
  );
  const userInfo = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );
  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );

  const isInCallingRoom = useSelector(
    (state: RootState) => state.GlobalReducer.isInCallingRoom
  );

  const isSocket = useRef<boolean>(false);

  let intervalJoinCall: any;

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const isApp = (window as any).ReactNativeWebView;

  const href = `haraseikouapp://home/visitOnlinecontactId=${contactId}&productId=${productId}`;

  function handleBack() {
    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      navigate((location.state as any)?.routeState || -1);
    }
  }

  const openApp = () => {
    if (
      !disableModal &&
      (!currentPathname?.length || currentPathname.length === 1)
    ) {
      handleOpenApp(href, setShowApp);
    }
    if (!disableModal) {
      navigate(paramsDisableModal(), { replace: true });
    }
  };

  useEffect(() => {
    if (isInCallingRoom !== undefined) {
      setIsAnyJoinCalling(isInCallingRoom || false);
      setIsAnyJoinCall(isInCallingRoom || false);
      isSocket.current = true;
    }
  }, [isInCallingRoom]);

  function getInfoVideoCall() {
    dispatch(setAppLoading(true));
    const contactInfo = Promise.resolve(
      ProductService.getContactDetail(Number(contactId))
    );
    const productInfo = Promise.resolve(
      ProductService.getProductById(Number(productId))
    );
    const checkAnyJoinCall = Promise.resolve(
      ProductService.checkAnyJoinCall(contactId)
    );
    const checkRemainTimeCall = Promise.resolve(
      ProductService.checkRemainTimeCall(contactId)
    );
    Promise.all([
      contactInfo,
      productInfo,
      checkAnyJoinCall,
      checkRemainTimeCall,
    ])
      .then((values) => {
        const contactInfo = values[0];
        const productInfo = values[1];
        const checkAnyJoinCall = values[2];
        const checkRemainTimeCall = values[3];

        const { timeConfirmed } = contactInfo.data.result;
        const startTime = `time${timeConfirmed}`;
        setStartTime(
          moment(contactInfo.data.result[startTime]).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        );
        setPreCallInfo(contactInfo.data.result);
        setProduct(productInfo.data.result);
        if (!isSocket.current) {
          setIsAnyJoinCall(checkAnyJoinCall.data.result);
        }
        if (
          checkRemainTimeCall &&
          checkRemainTimeCall.data.result.status === StatusCall.LATELY
        ) {
          setIsEndCall(true);
        }
        openApp();
      })
      .catch((err) => {
        const idError = get(
          err,
          "response.data.error.validationErrors[0].message"
        );
        if (idError === "M0131") {
          injectHandleOk(() => navigate("/dashboard"));
        }
        openApp();
      })
      .finally(() => {
        dispatch(setAppLoading(false));
      });
  }

  function handleMessageFromApp(event: any) {
    if (!event || !event.data) {
      return;
    }
    try {
      dispatch(setAppLoading(true));
      const message = JSON.parse(event.data);
      if (message.type === MessageType.RE_OPEN_APP) {
        clearInterval(intervalJoinCall);
        setDuration(0);
        setIsActive((preState) => !preState); 
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setAppLoading(false));
    }
  }

  useEffect(() => {
    window.removeEventListener("message", handleMessageFromApp, false); // Fails
    window.addEventListener("message", (ev) => handleMessageFromApp(ev), false);
    document.addEventListener(
      "message",
      (ev) => handleMessageFromApp(ev),
      false
    );
    return () => {
      window.removeEventListener("message", handleMessageFromApp, false);
      document.removeEventListener(
        "message",
        (ev) => handleMessageFromApp(ev),
        false
      );
    };
  }, []);

  useEffect(() => {
    if (startTime) {
      const now = moment();
      const startTimeByMoment = moment(startTime);
      const duration = startTimeByMoment.diff(now) * 0.001;
      if (duration >= 0) {
        setDuration(duration);
      }
    }
  }, [startTime, isActive]);

  useEffect(() => {
    if (duration) {
      setCountdown(true);
      const timeInterval = duration - 300;
      if (timeInterval >= 0) {
        intervalJoinCall = setInterval(
          () => setEnableJoinCall(true),
          timeInterval * 1000
        );
      } else {
        setEnableJoinCall(true);
      }
    }
    return () => clearInterval(intervalJoinCall);
  }, [duration]);

  useEffect(() => {
    getInfoVideoCall();
  }, [contactId, productId]);

  const productOverView = useMemo(
    () => [
      {
        label: "product.detail.category",
        value: product?.categoryName
          ?.split(">")
          .slice(1, product.categoryName.split(">").length)
          .join(">")
          .trim(),
      },
      { label: "product.detail.maker", value: product?.maker },
      { label: "product.detail.title", value: product?.title },
      {
        label: "product.detail.year",
        value: product?.yearOfManufacture
          ? product?.yearOfManufacture >= 1980
            ? product?.yearOfManufacture
            : "1980年以前"
          : "年式不明",
      },
      { label: "product.detail.comment", value: product?.comment },
      {
        label: "product.detail.location",
        value: product?.city && `${product?.city}${product?.district}`,
      },
    ],
    [productId, product]
  );

  const afterEndTime = useMemo(
    () => (
      <>
        <div>{moment(startTime).add(40, "minutes").format("HH:mm")}</div>
        <div>過ぎています</div>
      </>
    ),
    [startTime]
  );

  return (
    <>
      <AppModal
        icon={<Warning />}
        visible={preCallInfo && !isApp}
        title="この機能は開発中です。"
        content="ブラウザではオンライン見学をご利用できません。"
        okText="閉じる"
        onOk={() => {
          navigate("/dashboard");
        }}
      />
      <AppModal
        icon={<Warning />}
        visible={isEndCall && isApp}
        title="エラーが発生しました。"
        content={afterEndTime}
        okText={translate("common.ok")}
        onOk={() => {
          navigate("/dashboard");
        }}
      />
      <Mobile>
        <AppPullToRefresh
          onRefresh={() => {
            clearInterval(intervalJoinCall);
            setDuration(0);
            setIsActive(!isActive);
            getInfoVideoCall();
          }}
        >
          <AppModal
            visible={showApp}
            title="このページを“マシプラ”で開きますか？"
            cancelText="キャンセル"
            onCancel={() => setShowApp(false)}
            onOk={() => {
              setShowApp(false);
              buildLink(href);
            }}
            okText={
              getOS() === "Android" ? (
                <a href={href}>
                  <span style={{ color: "#fff" }}>あける</span>
                </a>
              ) : (
                <span style={{ color: "#fff" }}>あける</span>
              )
            }
          />

          <div className="prepration-page-container">
            <AppNavigation
              title={translate("prepration.title")}
              onClick={() => {
                handleBack();
              }}
            />
            {preCallInfo && (
              <>
                <div className="content">
                  <div className="content-view">
                    <div className="content__description">
                      <span>
                        {duration && duration > DefaultDuration
                          ? "まだオンライン見学の時間ではありません。"
                          : "もう少しでオンライン見学の時間になります。"}
                      </span>
                      <br />
                      <span>
                        {duration && duration > DefaultDuration
                          ? `${moment(startTime).format("MM月DD日 HH時mm分")}
                        開始予定`
                          : "しばらくお待ちください。"}
                      </span>
                      <br />
                      <span>5分前より参加できます。</span>
                      <br />
                      <span>{translate("contact.step5.description2")}</span>
                    </div>
                    {!!(duration && duration <= DefaultDuration) && (
                      <div className="content__countdown">
                        <AppCountdownCircleTimer
                          isPlaying={countdown || IsNotPlaying}
                          duration={duration || 0}
                        />
                      </div>
                    )}
                    {!duration && moment(startTime).diff(moment()) * 0.001 < 0 && (
                      <div className="content__countdown">
                        <AppCountdownCircleTimer
                          isPlaying={countdown || IsNotPlaying}
                          duration={0}
                        />
                      </div>
                    )}
                    <div className="content__info">
                      <span>
                        <b>
                          {product?.userId === userInfo?.id
                            ? "購入者情報"
                            : "販売者情報"}
                        </b>
                      </span>
                      <br />
                      {preCallInfo?.partnerCompany && (
                        <>
                          <span>{preCallInfo?.partnerCompany}</span>
                          <br />
                        </>
                      )}
                      <span>{preCallInfo?.partnerName}様</span>
                      {!!(
                        enableJoinCall &&
                        (isAnyJoinCall || isAnyJoinCalling)
                      ) && (
                        <div className="content__info-tag">
                          <span>オンライン見学参加中</span>
                        </div>
                      )}
                    </div>
                    <div className="content__overview">
                      <AppFieldset>
                        {productOverView &&
                          productOverView.map(({ label, value }, index) => (
                            <AppRow
                              key={index}
                              label={translate(label)}
                              value={value}
                              hasBorderBottom={
                                index !== productOverView.length - 1
                              }
                            />
                          ))}
                      </AppFieldset>
                    </div>
                  </div>
                </div>
                {moment(startTime).diff(moment()) * 0.001 <=
                  DefaultDuration && (
                  <div className="button-container">
                    <Row gutter={[32, 32]} justify="center">
                      <Col xs={24} md={8}>
                        <AppButton
                          disabled={duration ? !enableJoinCall : false}
                          buttontype="primary"
                          onClick={() => {
                            const compareTime =
                              moment(startTime).diff(moment()) * 0.001;
                            if ((window as any).ReactNativeWebView) {
                              if (compareTime < -DefaultTimeCall) {
                                setIsEndCall(true);
                              } else {
                                const message = {
                                  type: "JOIN_CALL",
                                  data: preCallInfo,
                                };
                                (window as any).ReactNativeWebView.postMessage(
                                  JSON.stringify(message)
                                );
                              }
                            }
                          }}
                        >
                          {translate("prepration.call")}
                        </AppButton>
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            )}
          </div>
        </AppPullToRefresh>
      </Mobile>
    </>
  );
};

export default Prepration;
