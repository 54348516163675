import React, { FC, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Form, Row } from "antd";
import {
  confirmForgotPassword,
  resetPassword,
} from "@service/API/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import { setAppLoading, setTitle } from "@store/State";
import AppPassword from "src/components/Form/AppPassword";
import { REGEX_PASSWORD } from "src/configs";
import StorageService from "@service/Storage";
import { buildLink, getOS, handleOpenApp, paramsDisableModal } from "src/helper";
import AppModal from "@components/AppModal";
import AppNavigation from "../../../../components/AppNavigation";
import AppButton from "../../../../components/AppButton";
import { ResetPasswordStatus, ResetPasswordStatusName } from "../../model";
import "./index.scss";
import { ReactComponent as InfoIcon } from "../../../../components/Icons/info-icon.svg";

interface Props {
  setStatus?: (status: ResetPasswordStatus) => void;
}

const PasswordForm: FC<Props> = memo(({ ...props }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(
    (state: RootState) => state.AppStateReducer.loading
  );

  const [showApp, setShowApp] = useState<boolean>(false);
  const [notExpiredToken, setNotExpiredToken] = useState<boolean>(true);
  const token = searchParams.get("token");

  const disableModal = searchParams.get("disableModal");

  const [href, setHref] = useState<string>(
    `haraseikouapp://home/resetPasswordWithToken${token}`
  );

  const openApp = (url: string) => {
    if (!disableModal) {
      handleOpenApp(url, setShowApp);
      navigate(paramsDisableModal(), { replace: true });
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(setAppLoading(true));
      (async () => {
        try {
          await confirmForgotPassword(token);
          openApp(href);
        } catch (e) {
          setNotExpiredToken(false);
          openApp(`haraseikouapp://home/resetPasswordExpired`);
        } finally {
          dispatch(setAppLoading(false));
        }
      })();
    }
  }, [token]);

  useEffect(() => {
    if (!notExpiredToken) {
      navigate(
        `/reset-password?token=${token}&type=${ResetPasswordStatus.FAIL}`
      );
    }
  }, [notExpiredToken]);

  const onFinish = async () => {
    const value = form.getFieldsValue();
    dispatch(setAppLoading(true));
    try {
      await resetPassword(token as string, value.newPassword);
      navigate(
        `/reset-password?token=${token}&type=${ResetPasswordStatus.SUCCESS}`
      );
    } catch (e) {
      console.log(e);
      navigate(
        `/reset-password?token=${token}&type=${ResetPasswordStatus.FAIL}`
      );
      setHref(`haraseikouapp://home/resetPasswordExpired`);
      openApp(`haraseikouapp://home/resetPasswordExpired`);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    dispatch(setTitle(ResetPasswordStatusName.INPUT_PASSWORD));
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  const checkPasswordMatch = (_: any, value: string) => {
    const password = form.getFieldValue("newPassword");
    if (password && password !== value) {
      return Promise.reject(
        t("resetPassword.passwordForm.validate.confirmNewPassword.notMatch")
      );
    }
    return Promise.resolve();
  };

  const checkPasswordLength = (_: any, value: string) => {
    if (!!value.match(REGEX_PASSWORD) === false) {
      return Promise.reject(
        t("resetPassword.passwordForm.validate.newPassword.length")
      );
    }
    return Promise.resolve();
  };

  const guidelinePassword = (
    <div style={{ marginTop: 4 }}>
      <InfoIcon />
      <span>{t("resetPassword.passwordForm.guideline.password")}</span>
    </div>
  );

  return !loading ? (
    <>
      <AppModal
        visible={showApp}
        title="このページを“マシプラ”で開きますか？"
        cancelText="キャンセル"
        onCancel={() => setShowApp(false)}
        onOk={() => {
          setShowApp(false);
          buildLink(href);
        }}
        okText={
          getOS() === "Android" ? (
            <a href={href}>
              <span style={{ color: "#fff" }}>あける</span>
            </a>
          ) : (
            <span style={{ color: "#fff" }}>あける</span>
          )
        }
      />
      <div className="ui-reset-password-password-form">
        <div className="ui-reset-password-password-form__pageTitle">
          <Row gutter={[32, 32]} justify="center">
            <Col xs={24}>
              <AppNavigation
                title={t("resetPassword.passwordForm.pageTitle")}
              />
            </Col>
          </Row>
        </div>
        <div className="ui-reset-password-password-form__form">
          <Row gutter={[32, 32]} justify="center">
            <Col xs={24} md={12} xl={7}>
              <div className="ui-reset-password-password-form__explain">
                {t("resetPassword.passwordForm.explain")}
              </div>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppPassword
                      name="newPassword"
                      label={t("resetPassword.passwordForm.newPassword")}
                      placeholder=""
                      extra={guidelinePassword}
                      formgroup={form}
                      rules={[
                        {
                          required: true,
                          message: t(
                            "resetPassword.passwordForm.validate.newPassword.required"
                          ),
                        },
                        { validator: checkPasswordLength },
                      ]}
                      maxLength={255}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppPassword
                      name="confirmNewPassword"
                      label={t("resetPassword.passwordForm.confirmNewPassword")}
                      placeholder=""
                      formgroup={form}
                      rules={[
                        {
                          required: true,
                          message: t(
                            "resetPassword.passwordForm.validate.confirmNewPassword.required"
                          ),
                        },
                        { validator: checkPasswordMatch },
                        { validator: checkPasswordLength },
                      ]}
                      maxLength={255}
                    />
                  </Col>
                </Row>
                <div className="ui-reset-password-password-form__action">
                  <Form.Item style={{ margin: 0 }}>
                    <Row gutter={[48, 48]} justify="center">
                      <Col xs={24} md={12} xl={24}>
                        <div className="ui-reset-password-password-form__action-button">
                          <AppButton
                            buttontype="primary"
                            loading={loading}
                            htmlType="submit"
                          >
                            {t("resetPassword.passwordForm.next")}
                          </AppButton>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  ) : null;
});

export default PasswordForm;

PasswordForm.defaultProps = {};
