import React from "react";
import { Outlet } from "react-router-dom";

import BottomBar from "@components/bottom-bar";
import "./mobile-dashboard-layout.scss";
import { useMediaQuery } from "react-responsive";

export default function DashboardLayout({ children }: any) {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  return (
    <>
      {!isDesktop && (
        <div className="mobile-dashboard-container">
          <div className="outlet-container">
            <Outlet></Outlet>
          </div>
          <BottomBar />
        </div>
      )}
      {isDesktop && (
        <div className="lg-dashboard-container">
          <Outlet></Outlet>
        </div>
      )}
    </>
  );
}
