import React, { FC, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AppModal from "src/components/AppModal";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { Col, Form, Row } from "antd";
import AppNavigation from "src/components/AppNavigation";
import AppPassword from "src/components/Form/AppPassword";
import { PATH_NAME, REGEX_PASSWORD } from "src/configs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store/configureStore";
import { setAppLoading } from "src/store/State";
import AppButton from "src/components/AppButton";
import {
  changePassword,
  checkCurrentPassword,
} from "@service/API/ResetPassword";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import { remove } from "lodash";
import UserService from "src/services/API/User";
import {
  setTotalUnreadMyNotice,
  setTotalUnreadNotice,
  setVideoCallStatus,
} from "src/store/Global";
import { clearRoomIds } from "src/store/Chat";
import { ChangePasswordFormValue } from "./model";
import "./index.scss";
import { ReactComponent as InfoIcon } from "../../components/Icons/info-icon.svg";

const ChangePassword: FC = memo(({ ...props }) => {
  useHideBottomBar(true);
  const [isConfirmChangePassword, setIsConfirmChangePassword] =
    useState<boolean>(false);

  const [isChangePasswordSuccess, setIsChangePasswordSuccess] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(
    (state: RootState) => state.AppStateReducer.loading
  );
  const location = useLocation();
  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );
  const connectionSystem = useSelector(
    (state: RootState) => state.AppStateReducer.connectionSystem
  );
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    form.resetFields();
  }, [searchParams]);

  const onFinish = async () => {
    dispatch(setAppLoading(true));
    try {
      await checkCurrentPassword(form.getFieldValue("currentPassword"));
      setIsConfirmChangePassword(true);
    } catch (e) {
      setIsConfirmChangePassword(false);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  const handleChangePassword = async () => {
    const value = form.getFieldsValue();
    const formData: ChangePasswordFormValue = {
      currentPassword: value.currentPassword,
      newPassword: value.newPassword,
    };
    dispatch(setAppLoading(true));
    try {
      await changePassword(formData);
      setIsConfirmChangePassword(false);
      setIsChangePasswordSuccess(true);
    } catch (e) {
      console.log(e);
      setIsConfirmChangePassword(false);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  const checkPasswordMatch = (_: any, value: string) => {
    const password = form.getFieldValue("newPassword");
    if (password && password !== value) {
      return Promise.reject(
        t("changePassword.passwordForm.validate.confirmNewPassword.notMatch")
      );
    }
    return Promise.resolve();
  };

  const checkPasswordMatch2 = (_: any, value: string) => {
    const password = form.getFieldValue("confirmNewPassword");
    if (password && password !== value) {
      form.validateFields(["confirmNewPassword"]);
    }
    if (password && password === value) {
      form.setFields([
        {
          name: "confirmNewPassword",
          errors: remove(
            form.getFieldError("confirmNewPassword"),
            form.getFieldError("confirmNewPassword")[
              form
                .getFieldError("confirmNewPassword")
                .indexOf(
                  t(
                    "changePassword.passwordForm.validate.confirmNewPassword.notMatch"
                  )
                )
            ]
          ),
        },
      ]);
    }
    return Promise.resolve();
  };

  const checkPasswordSameOld = (_: any, value: string) => {
    const password = form.getFieldValue("currentPassword");
    if (password && password === value) {
      return Promise.reject(
        t(
          "changePassword.passwordForm.validate.newPassword.same.currentPassword"
        )
      );
    }
    return Promise.resolve();
  };

  const checkPasswordLength = (_: any, value: string) => {
    if (!!value.match(REGEX_PASSWORD) === false) {
      return Promise.reject(t("changePassword.passwordForm.validate.required"));
    }
    return Promise.resolve();
  };

  const guidelinePassword = (
    <div style={{ marginTop: 4 }}>
      <InfoIcon />
      <span className="guideline-password">
        {t("changePassword.passwordForm.guideline.password")}
      </span>
    </div>
  );

  function handleBack() {
    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      navigate((location.state as any)?.routeState || -1);
    }
  }

  return (
    <>
      <AppModal
        icon={<Warning />}
        visible={isConfirmChangePassword}
        title={t("changePassword.passwordForm.popup.warning.title")}
        content={t("changePassword.passwordForm.popup.warning.content")}
        okText={t("changePassword.passwordForm.popup.warning.confirm")}
        cancelText={t("changePassword.passwordForm.popup.warning.cancel")}
        onCancel={() => setIsConfirmChangePassword(false)}
        onOk={handleChangePassword}
      />
      <AppModal
        icon={<Success />}
        visible={isChangePasswordSuccess}
        title={t("changePassword.passwordForm.popup.success.title")}
        content={t("changePassword.passwordForm.popup.success.content")}
        okText={t("changePassword.passwordForm.popup.success.close")}
        onOk={() => {
          UserService.logout();
          navigate(PATH_NAME.LOGIN);
          dispatch(clearRoomIds([]));
          dispatch(setTotalUnreadNotice(0));
          dispatch(setTotalUnreadMyNotice(0));
          dispatch(setVideoCallStatus(undefined));
          const message = {
            type: "LOGOUT",
          };
          (window as any)?.ReactNativeWebView?.postMessage(
            JSON.stringify(message)
          );
          if (connectionSystem) {
            connectionSystem.stop();
          }
        }}
      />
      <div className="ui-change-password-password-form">
        <div className="ui-change-password-password-form__pageTitle">
          <Row gutter={[32, 32]} justify="center">
            <Col xs={24}>
              <AppNavigation
                title={t("changePassword.passwordForm.pageTitle")}
                onClick={() => {
                  handleBack();
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="ui-change-password-password-form__form">
          <div className="ui-change-password-password-form__note">
            <p className="ui-change-password-password-form__note-row">
              {t("changePassword.passwordForm.note")}
            </p>
            <div className="ui-change-password-password-form__explain">
              {t("changePassword.passwordForm.explain")}
            </div>
          </div>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={[16, 16]} className="row-input">
              <Col xs={24}>
                <AppPassword
                  name="currentPassword"
                  label={t("changePassword.passwordForm.currentPassword")}
                  placeholder=""
                  formgroup={form}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "changePassword.passwordForm.validate.currentPassword.required"
                      ),
                    },
                    { validator: checkPasswordLength },
                  ]}
                  maxLength={255}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="row-input">
              <Col xs={24}>
                <AppPassword
                  name="newPassword"
                  label={t("changePassword.passwordForm.newPassword")}
                  placeholder=""
                  extra={guidelinePassword}
                  formgroup={form}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "changePassword.passwordForm.validate.newPassword.required"
                      ),
                    },
                    { validator: checkPasswordLength },
                    { validator: checkPasswordSameOld },
                    { validator: checkPasswordMatch2 },
                  ]}
                  maxLength={255}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} className="row-input">
              <Col xs={24}>
                <AppPassword
                  name="confirmNewPassword"
                  label={t("changePassword.passwordForm.confirmNewPassword")}
                  placeholder=""
                  formgroup={form}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "changePassword.passwordForm.validate.confirmNewPassword.required"
                      ),
                    },
                    { validator: checkPasswordMatch },
                    { validator: checkPasswordLength },
                  ]}
                  maxLength={255}
                />
              </Col>
            </Row>
            <div className="ui-change-password-password-form__action">
              <Form.Item style={{ margin: 0 }}>
                <Row gutter={[16, 16]} justify="center">
                  <Col xs={24}>
                    <div className="ui-change-password-password-form__action-button">
                      <AppButton
                        buttontype="primary"
                        loading={loading}
                        htmlType="submit"
                      >
                        {t("changePassword.passwordForm.save")}{" "}
                      </AppButton>
                    </div>
                  </Col>
                </Row>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
});
export default ChangePassword;

ChangePassword.defaultProps = {};
