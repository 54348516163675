import { ReactNode } from "react";
import { ProductTransactionStatus } from "src/models/product.model";

export interface UserInformation {
  gmail?: string;
  name?: string;
  dateOfBirth?: any;
  companyName?: string;
  department?: string;
  position?: string;
  postalCode?: string | number;
  phoneNumber?: string | number;
  prefectures?: string;
  municipalities?: string;
  address?: string;
  id?: number;
  emailAddress?: string;
  zipCode?: string;
  city?: string;
  district?: string;
  street?: string;
  buildingName?: string;
}

export enum MyPageType {
  MENU = "menu",
  PROFILE = "profile",
  FAVORITE = "favorite",
  PURCHASE_HISTORY = "purchase-history",
  SALES_HISTORY = "sales-history",
}

export enum ProfileField {
  EMAIL_ADDRESS = "emailAddress",
  NAME = "name",
  DATE_OF_BIRTH = "dateOfBirth",
  COMPANY_NAME = "companyName",
  DEPARTMENT = "department",
  POSITION = "position",
  ZIP_CODE = "zipCode",
  PHONE_NUMBER = "phoneNumber",
  STREET = "street",
  DISTRICT = "district",
  CITY = "city",
  ADDRESS = "address",
  BUILDING_NAME = "buildingName",
}

export interface BankAccountInformation {
  bankName?: string;
  bankCode?: string;
  updateTime?: any;
  branchName?: string;
  bankAccountNumber: number;
  bankAcountName: string;
  bankAccountType: number;
  token: string;
  branchId: number;
  branchCode?: number;
}

export interface BankInformation {
  value?: string;
  key: number;
  code?: number;
}

export enum BankAccountField {
  BANK_NAME = "bankName",
  BRAND_NAME = "branchName",
  ACCOUNT_TYPE = "bankAccountType",
  ACCOUNT_NUMBER = "bankAccountNumber",
  ACCOUNT_NAME = "bankAccountName",
  UPDATE_TIME = "updateTime",
}

export enum TypeBankAccount {
  NORMAL = 1,
  TEMPORARILY = 2,
}

export enum PURCHASE_HISTORY_TYPE {
  TRADING,
  TRADED,
  UNPUBLISH,
}

type TRANSACTION_STATUS = "NOT_HAPPENED" | "PROCESSING" | "COMPLETED";

export interface TRANSACTION_TIMELINE {
  status: ProductTransactionStatus;
  icon?: (status: ProductTransactionStatus) => ReactNode;
  label?: string;
  time?: any;
  note?: string;
}

export interface PurchaseHistorySearchParams {
  skipCount?: number;
  maxResultCount?: number;
  currentPage?: number;
  tapType?: number;
}

export enum TransactionType {
  PURCHASE = 1,
  SALE = 2,
}
