import "./styles.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import classNames from "classnames";
import { Desktop, Mobile } from "src/layouts/break-point";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import { ProductMedia } from "../../models/product.model";
import AppVideoPlayer from "../AppVideoPlayer";
import AppImageView from "../AppImageView";
import AppModalMedia from "../AppModalMedia";

const AppShowMedia: FC<{ productMedias?: ProductMedia[] }> = ({ ...props }) => {
  const { productMedias } = props;
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderShow = useRef<any>();
  const sliderMedia = useRef<any>();
  const sliderShowDesktop = useRef<any>();
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState<ProductMedia>();

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  function PrevArrowLg(props: any) {
    const { className, onClick } = props;
    return (
      <div className={classNames(className, "button-slider")} onClick={onClick}>
        <img className="prev" src="/assets/icons/arr-left.svg" alt="" />
      </div>
    );
  }
  function NextArrowLg(props: any) {
    const { className, onClick } = props;
    return (
      <div className={classNames(className, "button-slider")} onClick={onClick}>
        <img className="next" src="/assets/icons/arr-right.svg" alt="" />
      </div>
    );
  }

  const settingsTop = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    autoplay: false,
    loop: false,
    swipe: true,
    arrows: false,
  };

  const settingsTopDesktop = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    autoplay: false,
    loop: false,
    swipe: false,
    arrows: false,
  };

  return (
    <>
      {showFullScreen && (
        <AppModalMedia
          visible={showFullScreen}
          onClose={() => {
            setShowFullScreen(false);
          }}
          media={currentMedia}
        />
      )}

      <Mobile>
        <div className="app-show-media">
          <div className="slider-show">
            <Slider
              ref={sliderShow}
              {...settingsTop}
              onSwipe={(event) => {
                if (isVideoPlay) {
                  setIsVideoPlay(false);
                }
                if (event === 'left' && productMedias && currentIndex < productMedias.length - 1) {
                  setCurrentIndex(pre => pre + 1);
                  sliderMedia.current.slickGoTo(currentIndex + 1);
                } else if (event === 'right' && productMedias && currentIndex > 0) {
                  setCurrentIndex(pre => pre - 1);
                  sliderMedia.current.slickGoTo(currentIndex - 1);
                }
              }}
            >
              {productMedias &&
                productMedias.map((media) => (
                  <div className="div-ratio" key={media.id}>
                    {media.type === 1 ? (
                      <AppImageView
                        src={media.url.concat(sasCode as string)}
                        key={media.id}
                        onClick={() => {
                          setCurrentMedia(media);
                          setShowFullScreen(true);
                        }}
                      />
                    ) : (
                      <AppVideoPlayer
                        isPlay={isVideoPlay}
                        url={media.url.concat(sasCode as string)}
                        height="100%"
                        width="100%"
                        key={media.id}
                        onClick={() => {
                          setCurrentMedia(media);
                          setShowFullScreen(true);
                        }}
                        onPlayChange={(isPlay) => {
                          setIsVideoPlay(isPlay);
                        }}
                      />
                    )}
                  </div>
                ))}
                {productMedias && productMedias.length < 1 &&
                  <div className="div-ratio">
                    <AppImageView src=""/>
                  </div>
                }
            </Slider>
          </div>
          <div className="app-media-slider">
            <Slider
            ref={sliderMedia}
              infinite={false}
              slidesToShow={
                productMedias && productMedias?.length >= 3
                  ? 3
                  : productMedias?.length
              }
              // slidesToScroll={1}
              swipeToSlide
              slidesToScroll={1}
              swipe
              centerMode={false}
              nextArrow={<NextArrowLg />}
              prevArrow={<PrevArrowLg />}
              className={classNames({
                "slider-normal": productMedias && productMedias?.length < 4,
              })}
            >
              {productMedias &&
                productMedias.map((option, index: number) => (
                  <div key={index} className="div-s-ratio">
                    <div
                      className={classNames("media-item", {
                        select: index === currentIndex,
                      })}
                      onClick={() => {
                        sliderShow.current.slickGoTo(index);
                        setCurrentIndex(index);
                        if (isVideoPlay) {
                          setIsVideoPlay(false);
                        }
                      }}
                    >
                      {option.type !== 1 ? (
                        <AppVideoPlayer
                          url={option.url.concat(sasCode as string)}
                          height="100%"
                          width="100%"
                        />
                      ) : (
                        <AppImageView
                          src={option.url.concat(sasCode as string)}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </Mobile>

      <Desktop>
        <div className="app-show-media">
          <div className="slider-show">
            <Slider
              // eslint-disable-next-line no-return-assign
              ref={sliderShowDesktop}
              {...settingsTopDesktop}
              onSwipe={(event) => {
                if (isVideoPlay) {
                  setIsVideoPlay(false);
                }
              }}
            >
              {productMedias &&
                productMedias.map((media) =>
                  media.type === 1 ? (
                    <AppImageView
                      src={media.url.concat(sasCode as string)}
                      key={media.id}
                    />
                  ) : (
                    <AppVideoPlayer
                      isPlay={isVideoPlay}
                      url={media.url.concat(sasCode as string)}
                      height="560px"
                      width="100%"
                      key={media.id}
                      onPlayChange={(isPlay) => {
                        setIsVideoPlay(isPlay);
                      }}
                    />
                  )
                )}
                {productMedias && productMedias.length < 1 &&
                  <AppImageView src=""/>
                }
            </Slider>
          </div>
          <div className="app-media-slider">
            <Slider
              infinite={false}
              slidesToShow={
                productMedias && productMedias?.length >= 4
                  ? 4
                  : productMedias?.length
              }
              swipeToSlide
              swipe
              centerMode={false}
              slidesToScroll={1}
              nextArrow={<NextArrowLg />}
              prevArrow={<PrevArrowLg />}
              className={classNames({
                "slider-normal": productMedias && productMedias?.length < 4,
                "slider-two": productMedias && productMedias?.length === 2,
              })}
            >
              {productMedias &&
                productMedias.map((option, index: number) => (
                  <div
                    key={index}
                    className={classNames("media-item", {
                      select: index === currentIndex,
                    })}
                    onMouseEnter={() => {
                      sliderShowDesktop.current.slickGoTo(index);
                      setCurrentIndex(index);
                      if (isVideoPlay) {
                        setIsVideoPlay(false);
                      }
                    }}
                    onClick={() => {
                      sliderShowDesktop.current.slickGoTo(index);
                      setCurrentIndex(index);
                      if (isVideoPlay) {
                        setIsVideoPlay(false);
                      }
                    }}
                  >
                    {option.type !== 1 ? (
                      <AppVideoPlayer
                        url={option.url.concat(sasCode as string)}
                        height="100%"
                        width="100%"
                      />
                    ) : (
                      <AppImageView
                        src={option.url.concat(sasCode as string)}
                      />
                    )}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </Desktop>
    </>
  );
};

export default AppShowMedia;
