import React, { FC } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./styles.scss";

interface Props {
  duration: number;
  isPlaying: boolean;
}

const AppCountdownCircleTimer: FC<Props> = ({ ...props }) => {
  const { duration, isPlaying } = props;

  const convertSecondsToMinutes = (seconds: number): string =>
    new Date(seconds * 1000).toISOString().substr(-10, 5);

  const renderTime = ({ remainingTime }: any) => (
    <div className="timer">
      <div className="text"></div>
      <div className="value">{convertSecondsToMinutes(remainingTime)}</div>
      <div className="text">あと</div>
    </div>
  );

  return (
    <div className="timer-wrapper">
      <CountdownCircleTimer
        isPlaying={isPlaying}
        duration={duration}
        colors={["#FF671E", "#FF671E"]}
        colorsTime={[duration, 0]}
        onComplete={() => ({ shouldRepeat: false, delay: 1 })}
        strokeWidth={3}
      >
        {renderTime}
      </CountdownCircleTimer>
    </div>
  );
};

export default AppCountdownCircleTimer;
