/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, memo, useEffect, useState, useRef } from "react";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./styles.scss";
import classNames from "classnames";

interface Props {
  children: any;
  onRefresh: () => void;
}

// const App: React.FC = () => ;
const pStart = { x: 0, y: 0 };
const pStop = { x: 0, y: 0 };
const pCurrent = { x: 0, y: 0 };

const AppPullToRefresh: FC<Props> = ({ ...props }) => {
  const antIcon = <ReloadOutlined style={{ fontSize: 18, color: "#f27527" }} />;
  const antIconLoading = <LoadingOutlined style={{ fontSize: 18 }} spin />;

  const [isSwipe, setSwipe] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const loadingContainer = useRef<HTMLElement | undefined>(undefined);
  const loadingIcon = useRef<HTMLElement | undefined>(undefined);

  const { children, onRefresh } = props;

  function swipeStart(e: any) {
    if (typeof e.targetTouches !== "undefined") {
      const touch = e.targetTouches[0];
      pStart.x = touch.screenX;
      pStart.y = touch.screenY;
    }
  }

  function swipe(e: any) {
    if (pStart.y < 150) {

      if (typeof e.changedTouches !== "undefined") {
        const touch = e.changedTouches[0];
        pCurrent.x = touch.screenX;
        pCurrent.y = touch.screenY;
        const changeY = pCurrent.y - pStart.y;

        if (changeY > 100  ) {
          if (!checkIsModal()) {
            setSwipe(true);
          }
        }

        if (loadingContainer.current && loadingIcon.current && changeY < 150) {
          loadingContainer.current.style.transform = `translate(-50%, ${
            changeY * 0.6
          }px)`;
          loadingIcon.current.style.opacity = `${changeY / 70}`;
          // loadingIcon.current.style.transform = `translate(rotate(${changeY / 70 * 360}deg))`;
        }
      }

      // if (changeY >= 120) {
      //   setSwipe(false);
      // }
    }
  }

  useEffect(() => {
    const changeY = pCurrent.y - pStart.y;
    if (changeY > 100 && !isSwipe && pStart.y < 350) {
      onRefresh();
      pCurrent.x = 0;
      pCurrent.y = 0;
      pStart.x = 0;
      pStart.y = 0;
      setIsDisable(false);
    }
  }, [isSwipe]);

  function swipeEnd(e: any) {
    const changeY = pCurrent.y - pStart.y;
    if (changeY > 100 && pStart.y < 350) {
      if (!checkIsModal()) {
        setIsDisable(true);
        setTimeout(() => {
          setSwipe(false);
        }, 1000);
      }
    }
  }

  function checkIsModal() {
    const container = document.getElementsByClassName("ant-drawer-mask");
    const containerModal = document.getElementsByClassName("ant-modal-mask");
    return container.length > 0 || containerModal.length > 0;
  }

  function onSwipeStart(e: any) {
    swipeStart(e);
  }

  function onSwipeEnd(e: any) {
    swipeEnd(e);
  }
  function onSwipe(e: any) {
    swipe(e);
  }

  useEffect(() => {
    const container = document.getElementById("loadingIcon") || undefined;
    loadingContainer.current = container;
    const icon = document.getElementById("l-icon") || undefined;
    loadingIcon.current = icon;

    document.addEventListener("touchstart", onSwipeStart, false);
    document.addEventListener("touchmove", onSwipe, false);

    document.addEventListener("touchend", onSwipeEnd, false);

    return () => {
      document.removeEventListener("touchstart", onSwipeStart, false);

      document.removeEventListener("touchmove", onSwipe, false);

      document.removeEventListener("touchend", onSwipeEnd, false);
    };
  }, []);

  return (
    <>
      <div
        className={classNames("refresh-container", { show: isSwipe })}
        id="loadingIcon"
      >
        <div className="icon-bg">
          <div id="l-icon">
            {isDisable ? <Spin indicator={antIconLoading} /> : antIcon}
          </div>
        </div>
      </div>
      {children}
    </>
  );
};

export default AppPullToRefresh;
