import { Col, Form, RadioChangeEvent, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import AppRadio from "src/components/Form/AppRadio";
import { LabeledValue } from "antd/lib/select";
import moment from "moment";
import ProductService from "src/services/API/Product";
import AppTextArea from "src/components/Form/AppTextArea";
import AppButton from "src/components/AppButton";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setAppLoading, setError, setTitle } from "src/store/State";
import { ProductHistory } from "src/models/product.model";
import AppModal from "src/components/AppModal";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import AppNavigation from "src/components/AppNavigation";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CommonService from "src/services/Common";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import { buildLink, getOS, handleOpenApp, paramsDisableModal } from "src/helper";
import { RootState } from "@store/configureStore";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import { get } from "lodash";
import { injectHandleOk } from "src/App";
import { DATE_TIME_FORMAT } from "src/configs";
import { ContactDetail, ContactMethodEnum } from "../contact/model";
import StorageService from "../../services/Storage";

interface Props {
  item: ProductHistory;
  onChange: () => void;
}

const ConfirmContact: FC<Props> = ({ ...props }) => {
  useHideBottomBar(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [timeSelect, setTimeSelect] = useState<LabeledValue[]>([]);
  const [timeSelectInit, setTimeSelectInit] = useState<string[]>([]);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [contactDetail, setContactDetail] = useState<ContactDetail>();
  const [showApp, setShowApp] = useState<boolean>(false);
  const [isRejectCalendar, setIsRejectCalendar] = useState<boolean>(false);
  const [isOverTime, setIsOverTime] = useState<boolean>(false);
  const location = useLocation();

  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );

  const href = `haraseikouapp://home/confirmContact${id}`;
  const disableModal = searchParams.get("disableModal");

  function handleBack() {
    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      navigate((location.state as any)?.routeState || -1);
    }
  }

  const onFinish = () => {
    if (form.getFieldValue("time") !== 4) {
      const time = timeSelect[form.getFieldValue("time") - 1].label as string;
      if (moment(time, DATE_TIME_FORMAT) < moment()) {
        setIsOverTime(true);
      } else setShowConfirm(true);
    } else setShowConfirm(true);
  };

  useEffect(() => {
    if (
      !disableModal &&
      (!currentPathname?.length || currentPathname.length === 1) &&
      !StorageService.getDevice()
    ) {
      handleOpenApp(href, setShowApp);
    }
    if (!disableModal) {
      navigate(paramsDisableModal(), { replace: true });
    }
  }, []);
  function getContactDetail() {
    dispatch(setAppLoading(true));
    ProductService.getContactDetail(Number(id))
      .then((res) => {
        dispatch(setAppLoading(false));
        const { isSeller } = res.data.result;
        if (!isSeller) {
          dispatch(
            setError({ message: "M0052", title: "common.modal.userError" })
          );
          navigate("/dashboard");
        } else {
          setContactDetail(res.data.result);
          const { result } = res.data;
          const timeSelect = [];
          const timeSelectInit = [];
          if (result.time1) {
            timeSelect.push({
              label: moment(result.time1).format("YYYY年MM月DD日 HH:mm"),
              value: 1,
            });
            timeSelectInit.push(result.time1);
          }
          if (result.time2) {
            timeSelect.push({
              label: moment(result.time2).format("YYYY年MM月DD日 HH:mm"),
              value: 2,
            });
            timeSelectInit.push(result.time2);
          }
          if (result.time3) {
            timeSelect.push({
              label: moment(result.time3).format("YYYY年MM月DD日 HH:mm"),
              value: 3,
            });
            timeSelectInit.push(result.time3);
          }
          timeSelect.push({
            label: "調整できません",
            value: 4,
          });
          setTimeSelect(timeSelect);
          setTimeSelectInit(timeSelectInit);
          form.setFieldsValue({ time: 1 });
        }
      })
      .catch((err) => {
        dispatch(setAppLoading(false));
      });
  }

  useEffect(() => {
    getContactDetail();
  }, [id]);

  useEffect(() => {
    dispatch(setTitle("コンタクト時間選択"));
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  function confirmContact() {
    setShowConfirm(false);
    if (contactDetail) {
      dispatch(setAppLoading(true));
      ProductService.confirmContact({
        id: Number(id),
        noteConfirmed: form.getFieldValue("note"),
        timeConfirmed: form.getFieldValue("time"),
        timeConfirmedValues: timeSelectInit,
        token: contactDetail?.contactToken,
        orderToken: contactDetail?.orderToken,
      })
        .then((res) => {
          dispatch(setAppLoading(false));

          setSuccessModalVisible(true);
        })
        .catch((err) => {
          dispatch(setAppLoading(false));
          const idError = get(
            err,
            "response.data.error.validationErrors[0].message"
          );
          if (idError === "M0127") {
            injectHandleOk(() =>
              navigate(`/my-page/sales-history/${contactDetail?.orderId}`)
            );
          }
          if (idError === "M0197") {
            injectHandleOk(() => getContactDetail());
          }
        });
    }
  }

  const [disabledBox, setDisabledBox] = useState(true);

  return (
    <AppPullToRefresh
      onRefresh={() => {
        getContactDetail();
      }}
    >
      <AppModal
        visible={showApp}
        title="このページを“マシプラ”で開きますか？"
        cancelText="キャンセル"
        onCancel={() => setShowApp(false)}
        onOk={() => {
          setShowApp(false);
          buildLink(href);
        }}
        okText={
          getOS() === "Android" ? (
            <a href={href}>
              <span style={{ color: "#fff" }}>あける</span>
            </a>
          ) : (
            <span style={{ color: "#fff" }}>あける</span>
          )
        }
      />
      <AppModal
        icon={<Success />}
        visible={successModalVisible}
        title="日時選択が完了しました。"
        content=""
        okText="閉じる"
        cancelText=""
        onOk={() => {
          setSuccessModalVisible(false);
          navigate("/my-page/sales-history?tab=0");
        }}
      />
      <AppModal
        visible={showConfirm}
        title={
          !isRejectCalendar
            ? "選択したコンタクト日時でよろしいですか？"
            : "別の候補日を依頼しますか？"
        }
        content={
          !isRejectCalendar
            ? "選択した日程は購入者へ送信されます。"
            : "入力したメッセージを購入者へ送信して、別の候補日を依頼します。"
        }
        okText="はい"
        cancelText="いいえ"
        onOk={() => {
          confirmContact();
        }}
        onCancel={() => {
          setShowConfirm(false);
        }}
      />
      <AppModal
        visible={isOverTime}
        title="選択した時間は過ぎています。"
        content="別の時間を選択してください。または、「選択できません」を選択して購入者に再調整を依頼してください。"
        okText="OK"
        onOk={() => {
          setIsOverTime(false);
          getContactDetail();
        }}
      />

      <div className="confirm-contact-form">
        <AppNavigation
          onClick={() => {
            handleBack();
          }}
          title="コンタクト時間選択"
        />
        <div className="form-confirm-value">
          <div className="text-confirm-note">
            {contactDetail?.type === ContactMethodEnum.ONLINE &&
              t("contact.confirm.text.guide.online")}
            {contactDetail?.type === ContactMethodEnum.OFFLINE &&
              t("contact.confirm.text.guide.offline")}
          </div>

          <div className="text-confirm-note">
            {contactDetail?.type === ContactMethodEnum.ONLINE &&
              t("contact.step5.description2")}
          </div>

          <div className="buyer-info">
            <div className="bold">購入者情報</div>
            {contactDetail?.partnerCompany && (
              <div className="b-text-info">{contactDetail?.partnerCompany}</div>
            )}
            <div className="b-text-info">
              {contactDetail?.partnerName}
              <span>様</span>
            </div>
          </div>

          <div className="form-contact">
            <Form form={form} className="sort-body" onFinish={onFinish}>
              <AppRadio
                options={timeSelect}
                onChange={(value: RadioChangeEvent) => {
                  setDisabledBox(value.target.value !== 4);
                  if (value.target.value === 4) {
                    setIsRejectCalendar(true);
                    form.setFieldsValue({
                      note: "見学日時の候補に対して、日程を調整する事ができませんでした。お手数ですが別の候補日をご提示ください。",
                    });
                  } else {
                    form.setFieldsValue({ note: "" });
                    setIsRejectCalendar(false);
                  }
                }}
                name="time"
                label=""
              />
              {!disabledBox && (
                <AppTextArea
                  // disabled={disabledBox}
                  name="note"
                  maxLength={1000}
                  shouldUpdate
                  rules={[
                    {
                      required: true,
                      message: "コメントは必須です。",
                    },
                  ]}
                  formgroup={form}
                ></AppTextArea>
              )}
              <div className={classNames("button-group")}>
                <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                  {() => (
                    <AppButton
                      buttontype="primary"
                      htmlType="submit"
                      onClick={() => {
                        CommonService.scrollToError();
                      }}
                    >
                      確認
                    </AppButton>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </AppPullToRefresh>
  );
};

export default ConfirmContact;

ConfirmContact.defaultProps = {};
