import React, { FC, memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Col, Row } from "antd";
import moment from "moment";
import AppTextField from "@components/Form/AppTextField";
import AppDatePicker from "@components/AppDatePicker";
import AppCheckbox from "@components/Form/AppCheckbox";
import AppButton from "@components/AppButton";
import AppNavigation from "@components/AppNavigation";
import AppSelect from "@components/Form/AppSelect";
import AppPassword from "@components/Form/AppPassword";
import {
  CHARACTER_SPECIALS,
  DATE_FORMAT,
  REGEX_PASSWORD,
} from "@configs/index";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import { setAppLoading, setError, setTitle } from "@store/State";
import { remove } from "lodash";
import { checkZipCode, getDistricts, getZipCodes, isEmailExisted } from "@service/API/SignUp";
import { MessageType } from "src/models/common.model";
import {
  SignUpFormValue,
  SignUpStatus,
  SignUpStatusName,
  ZipCodeValue,
} from "../../model";
import { ReactComponent as InfoIcon } from "../../../../components/Icons/info-icon.svg";

interface Props {
  value?: SignUpFormValue;
  setValue?: (value: SignUpFormValue) => void;
  setStatus?: (status: SignUpStatus) => void;
}

const SignUpForm: FC<Props> = memo(({ ...props }) => {
  const { value, setValue, setStatus } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [cityValue, setCityValue] = useState<string>("");
  const [isCanCallZipCode, setIsCanCallZipCode] = useState<boolean>(false);
  const [districts, setDistricts] = useState<string[]>([]);
  const [zipCodeListByDistrict, setZipCodeListByDistrict] = useState<
    ZipCodeValue[]
  >([]);
  const [zipCodeListByProvince, setZipCodeListByProvince] = useState<
    ZipCodeValue[]
  >([]);
  const [urlPrivacyPolicy, setUrlPrivacyPolicy] = useState<any>();
  const [urlTermOfService, setUrlTermOfService] = useState<any>();
  const [isExistZipCode, setIsExistZipCode] = useState<boolean>(true);

  const systemConfig = useSelector(
    (state: RootState) => state.GlobalReducer.systemConfig
  );

  const isApp = (window as any).ReactNativeWebView;

  const currentZipCode = useRef("");

  const { provinces } = useSelector((state: RootState) => state.GlobalReducer);

  useEffect(() => {
    setUrlTermOfService(
      systemConfig.find((item) => item.key === "TermOfService")
    );
    setUrlPrivacyPolicy(
      systemConfig.find((item) => item.key === "PrivacyPolicy")
    );
  }, [systemConfig]);

  const onFinish = async () => {
    dispatch(setAppLoading(true));
    try {
      await isEmailExisted(form.getFieldValue("emailAddress"));
      if (!isExistZipCode) {
        dispatch(setError({ message: "M0198", title: "common.modal.userError" }));
        return;
      }
      const value = form.getFieldsValue();
      setValue?.(value);
      setStatus?.(SignUpStatus.CONFIRM);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    if (value?.dateOfBirth) {
      value.dateOfBirth = moment(value.dateOfBirth, DATE_FORMAT);
    }
    form.setFieldsValue(value);

    form.setFields([
      {
        name: "dateOfBirth",
        touched: true,
      },
      {
        name: "phoneNumber",
        touched: true,
      },
      {
        name: "companyName",
        touched: true,
      },
      {
        name: "department",
        touched: true,
      },
      {
        name: "position",
        touched: true,
      },
      {
        name: "buildingName",
        touched: true,
      },
    ]);
  }, [form]);

  useEffect(() => {
    dispatch(setTitle(SignUpStatusName.SIGNUP_INPUT));
    const scroll = document.getElementById("appLayout") as HTMLDivElement;
    if (scroll) {
      scroll.scrollTo({ top: 0, behavior: "smooth" });
    }
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  const validatePhoneNumber = (_: any, phoneNumber?: string) => {
    const pattern = /^[0][0-9]{9,10}$/;
    if (phoneNumber && !pattern.test(phoneNumber)) {
      return Promise.reject(t("signup.form.validate.phoneNumber.incorrect"));
    }
    return Promise.resolve();
  };


  const checkMatchPassword = (_: any, value: string) => {
    const password = form.getFieldValue("password");
    if (password !== value) {
      return Promise.reject(t("signup.form.validate.confirmPassword.notMatch"));
    }
    return Promise.resolve();
  };

  const checkLengthPassword = (_: any, value: string) => {
    if (!!value.match(REGEX_PASSWORD) === false) {
      return Promise.reject(t("signup.form.validate.password.length"));
    }
    return Promise.resolve();
  };

  const checkPasswordMatch2 = (_: any, value: string) => {
    const password = form.getFieldValue("passwordConfirmation");
    if (password && password !== value) {
      form.validateFields(["passwordConfirmation"]);
    }
    if (password && password === value) {
      form.setFields([
        {
          name: "passwordConfirmation",
          errors: remove(
            form.getFieldError("passwordConfirmation"),
            form.getFieldError("passwordConfirmation")[
              form
                .getFieldError("passwordConfirmation")
                .indexOf(t("signup.form.validate.confirmPassword.notMatch"))
            ]
          ),
        },
      ]);
    }
    return Promise.resolve();
  };

  const checkMatchEmail = (_: any, value: string) => {
    const email = form.getFieldValue("emailAddress");
    if (email !== value) {
      return Promise.reject(t("signup.form.validate.confirmEmail.notMatch"));
    }
    return Promise.resolve();
  };

  const checkMatchEmail2 = (_: any, value: string) => {
    const email = form.getFieldValue("emailAddressConfirmation");
    if (email && email !== value) {
      form.validateFields(["emailAddressConfirmation"]);
    }
    if (email && email === value) {
      form.setFields([
        {
          name: "emailAddressConfirmation",
          errors: remove(
            form.getFieldError("emailAddressConfirmation"),
            form.getFieldError("emailAddressConfirmation")[
              form
                .getFieldError("emailAddressConfirmation")
                .indexOf(t("signup.form.validate.confirmEmail.notMatch"))
            ]
          ),
        },
      ]);
    }
    return Promise.resolve();
  };

  function validateEmail(_: any, value: string = "") {
    const splitChars = (value || "").split("");
    const hasFullSizeChar = splitChars.some((char: string) => {
      const code = char.charCodeAt(0);
      return !(
        (code >= 0x0020 && code <= 0x1fff) ||
        (code >= 0xff61 && code <= 0xff9f)
      );
    });
    const emailPattern =
      /^[A-Za-z0-9!.#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!.#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/;
    const isEmail = value.match(emailPattern);
    if (hasFullSizeChar || !isEmail) {
      return Promise.reject(t("signup.form.validate.email.format"));
    }
    return Promise.resolve();
  }

  function filterZipCodeByKey(data: ZipCodeValue[], key: any) {
    const seen: any = new Set();
    return data.filter((item) => {
      const k: any = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  const handleCheckZipCodeExists = async (zipCode: string) => {
    try {
      await checkZipCode(zipCode);
    } catch (error) {
      setIsExistZipCode(false);
    }
  };

  const handleZipCodeChange = async () => {
    const zipCode = `${form.getFieldValue("zipCode") || ""}`;
    if (
      zipCode.length === 7 &&
      isCanCallZipCode &&
      zipCode !== currentZipCode.current
    ) {
      dispatch(setAppLoading(true));
      try {
        const responseZipCodes = await getZipCodes(zipCode);
        const zipCodeRes = responseZipCodes.data.result;
        currentZipCode.current = zipCode as string;
        if (zipCodeRes.length === 0) {
          handleCheckZipCodeExists(zipCode);
          return;
        }
        setIsExistZipCode(true);
        const filterZipCodeByDistrict = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.district
        );
        setZipCodeListByDistrict(filterZipCodeByDistrict);
        const filterZipCodeByProvince = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.province
        );
        setZipCodeListByProvince(filterZipCodeByProvince);

        if (zipCodeRes.length === 1) {
          form.setFields([
            {
              name: "city",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: zipCodeRes[0].district,
              touched: true,
            },
            {
              name: "street",
              value: zipCodeRes[0].street,
              touched: true,
            },
          ]);
          await form.validateFields(["city", "district"]);
          if (zipCodeRes[0].street) {
            await form.validateFields(["street"]);
          }
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length > 1
        ) {
          form.setFields([
            {
              name: "city",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: "",
              touched: true,
            },
            {
              name: "street",
              value: "",
              touched: true,
            },
          ]);

          await form.validateFields(["city"]);
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length === 1
        ) {
          form.setFields([
            {
              name: "city",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: filterZipCodeByDistrict[0].district,
              touched: true,
            },
            {
              name: "street",
              value: "",
              touched: true,
            },
          ]);
          await form.validateFields(["city", "district"]);
        } else if (zipCodeRes.length === 0) {
          form.setFieldsValue({ city: "", district: "", street: "" });
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setAppLoading(false));
      }
    } else if (zipCode.length !== 7) {
      form.setFieldsValue({ city: "", district: "", street: "" });
      setZipCodeListByProvince([]);
      setZipCodeListByDistrict([]);
      currentZipCode.current = zipCode;
    }
  };

  const handleGetDistricts = async () => {
    try {
      dispatch(setAppLoading(true));
      const response = await getDistricts(cityValue);
      const zipCodeInfo = response.data.result;
      setDistricts(
        zipCodeInfo
          .map((item: any) => item.district)
          .filter((district: null) => district !== null)
      );
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    if (cityValue) {
      handleGetDistricts();
    }
  }, [cityValue]);

  const checkLength = (_: any, value: string) => {
    const length = value?.length;
    const zipCodeLength = 7;
    if (length !== zipCodeLength) {
      return Promise.reject(t("signup.form.validate.zipCode.incorrect"));
    }
    return Promise.resolve();
  };

  const guidelinePassword = (
    <div style={{ marginTop: 4 }}>
      <InfoIcon />
      <span className="guideline-password">
        {t("resetPassword.passwordForm.guideline.password")}
      </span>
    </div>
  );

  return (
    <div className="ui-sign-up-form">
      <div className="ui-sign-up-form__pageTitle">
        {/* <Row gutter={[32, 32]} justify="center">
          <Col xs={24}> */}
        <AppNavigation route="/login" title={t("signup.form.pageTitle")} />
        {/* </Col>
        </Row> */}
      </div>
      <Row gutter={[32, 32]} justify="center" className="row-sign-up-mobile">
        <Col xs={24} md={12} xl={11}>
          <p className="ui-sign-up-form__note">{t("signup.form.note")}</p>
          <p className="ui-sign-up-form__explain">{t("signup.form.warning")}</p>
          <div className="ui-sign-up-form__form">
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppTextField
                    name="emailAddress"
                    label={t("signup.form.email")}
                    placeholder=""
                    formgroup={form}
                    rules={[
                      {
                        required: true,
                        message: t("signup.form.validate.email.required"),
                      },
                      // {
                      //   type: "email",
                      //   message: t("signup.form.validate.email.format"),
                      // },
                      { validator: validateEmail },
                      { validator: checkMatchEmail2 },
                    ]}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppTextField
                    name="emailAddressConfirmation"
                    label={t("signup.form.emailConfirm")}
                    placeholder=""
                    formgroup={form}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "signup.form.validate.confirmEmail.required"
                        ),
                      },
                      // {
                      //   type: "email",
                      //   message: t("signup.form.validate.email.format"),
                      // },
                      { validator: checkMatchEmail },
                      { validator: validateEmail },
                    ]}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppTextField
                    allowonlyalphabet="true"
                    name="fullName"
                    label={t("signup.form.name")}
                    placeholder=""
                    formgroup={form}
                    rules={[
                      {
                        required: true,
                        message: t("signup.form.validate.name.required"),
                      },
                    ]}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24} md={24} xl={24}>
                  <AppDatePicker
                    form={form}
                    values={
                      form.getFieldValue("dateOfBirth") || value?.dateOfBirth
                    }
                    name="dateOfBirth"
                    label={t("signup.form.dateOfBirth")}
                    rules={[]}
                    type="birthday"
                    extra={t("signup.form.note2")}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24} md={24} xl={24}>
                  <AppTextField
                    onlyInputNumber="true"
                    name="phoneNumber"
                    label={t("signup.form.phoneNumber")}
                    placeholder=""
                    formgroup={form}
                    maxLength={11}
                    extra={
                      <>
                        <div>{t("signup.form.note3")}</div>
                        <div>{t("signup.form.note4")}</div>
                      </>
                    }
                    type="number"
                    pattern="[0-9]*"
                    rules={[
                      { validator: validatePhoneNumber }
                    ]}
                    onWheel={(e) => (e.target as any).blur()}
                    onKeyDown={(event) => {
                      if (
                        event.key === "e" ||
                        event.key === "E" ||
                        event.key === "ArrowUp" ||
                        event.key === "ArrowDown"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onKeyPress={(event) => {
                      if (CHARACTER_SPECIALS.includes(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      if (
                        CHARACTER_SPECIALS.some((char) =>
                          e.clipboardData.getData("text").includes(char)
                        )
                      ) {
                        return false;
                      }
                      form.setFieldsValue({
                        phoneNumber: e.clipboardData.getData("text"),
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppTextField
                    name="companyName"
                    label={t("signup.form.companyName")}
                    placeholder=""
                    formgroup={form}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppTextField
                    name="department"
                    label={t("signup.form.department")}
                    placeholder=""
                    formgroup={form}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppTextField
                    name="position"
                    label={t("signup.form.position")}
                    placeholder=""
                    formgroup={form}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppTextField
                    onlyInputNumber="true"
                    name="zipCode"
                    label={t("signup.form.zipCode")}
                    placeholder=""
                    formgroup={form}
                    extra={t("signup.form.validate.zipCode.guideline", {
                      params: '"ー"',
                    })}
                    rules={[
                      {
                        required: true,
                        message: t("signup.form.validate.zipCode.required"),
                      },
                      {
                        validator: checkLength,
                      },
                    ]}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        (event.target as any).blur();
                        event.stopPropagation();
                        event.preventDefault();
                      }

                      if (CHARACTER_SPECIALS.includes(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onWheel={(e) => (e.target as any).blur()}
                    onKeyDown={(event) => {
                      if (
                        event.key === "e" ||
                        event.key === "E" ||
                        event.key === "ArrowUp" ||
                        event.key === "ArrowDown"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    type="number"
                    pattern="[0-9]*"
                    onBlur={() => handleZipCodeChange()}
                    onPaste={(e) => {
                      e.preventDefault();
                      if (
                        CHARACTER_SPECIALS.some((char) =>
                          e.clipboardData.getData("text").includes(char)
                        )
                      ) {
                        return false;
                      }
                      form.setFieldsValue({
                        zipCode: e.clipboardData.getData("text"),
                      });
                    }}
                    onChange={(e) => {
                      setIsCanCallZipCode(!!(e.target.value.length === 7));
                    }}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppSelect
                    options={
                      zipCodeListByProvince.length > 0
                        ? zipCodeListByProvince.map((zipCode) => ({
                            label: zipCode.province,
                            value: zipCode.province,
                          }))
                        : provinces.map((province) => ({
                            label: province,
                            value: province,
                          }))
                    }
                    name="city"
                    label={t("signup.form.city")}
                    placeholder=""
                    rules={[
                      {
                        required: true,
                        message: t("signup.form.validate.city.required"),
                      },
                    ]}
                    onChange={(value) => {
                      setCityValue(value);
                      form.setFieldsValue({ district: null, street: null });
                    }}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppSelect
                    name="district"
                    label={t("signup.form.district")}
                    placeholder=""
                    rules={[
                      {
                        required: true,
                        message: t("signup.form.validate.district.required"),
                      },
                    ]}
                    options={
                      zipCodeListByDistrict.length > 0
                        ? zipCodeListByDistrict.map((zipCode) => ({
                            label: zipCode.district,
                            value: zipCode.district,
                          }))
                        : districts.map((district) => ({
                            label: district,
                            value: district,
                          }))
                    }
                    onChange={() => {
                      form.setFieldsValue({ street: null });
                    }}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppTextField
                    name="street"
                    label={t("signup.form.street")}
                    placeholder=""
                    formgroup={form}
                    rules={[
                      {
                        required: true,
                        message: t("signup.form.validate.street.required"),
                      },
                    ]}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppTextField
                    name="buildingName"
                    label={t("signup.form.buildingName")}
                    placeholder=""
                    formgroup={form}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppPassword
                    name="password"
                    label={t("signup.form.password")}
                    placeholder=""
                    formgroup={form}
                    extra={guidelinePassword}
                    rules={[
                      {
                        required: true,
                        message: t("signup.form.validate.password.required"),
                      },
                      { validator: checkLengthPassword },
                      { validator: checkPasswordMatch2 },
                    ]}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppPassword
                    name="passwordConfirmation"
                    label={t("signup.form.passwordConfirm")}
                    placeholder=""
                    formgroup={form}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "signup.form.validate.confirmPassword.required"
                        ),
                      },
                      {
                        validator: checkMatchPassword,
                      },
                      {
                        validator: checkLengthPassword,
                      },
                    ]}
                    maxLength={255}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="h-p-16">
                <Col xs={24}>
                  <AppCheckbox
                    name="isAgreeWithTerm"
                    label=""
                    type="default"
                    options={[
                      {
                        label: (
                          <>
                            <span style={{ color: "#FF671E" }}>
                              {!isApp ? (
                                <a
                                  href={urlPrivacyPolicy?.value}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("signup.form.privacyPolicy")}
                                </a>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (urlPrivacyPolicy) {
                                      const message = {
                                        type: MessageType.MODAL,
                                        data: urlPrivacyPolicy?.value,
                                      };
                                      (
                                        window as any
                                      ).ReactNativeWebView.postMessage(
                                        JSON.stringify(message)
                                      );
                                    }
                                  }}
                                >
                                  {t("signup.form.privacyPolicy")}
                                </span>
                              )}
                            </span>
                            <span>{t("signup.form.and")}</span>
                            <span style={{ color: "#FF671E" }}>
                              {!isApp ? (
                                <a
                                  href={urlTermOfService?.value}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("signup.form.termOfService")}
                                </a>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (urlTermOfService) {
                                      const message = {
                                        type: MessageType.MODAL,
                                        data: urlTermOfService?.value,
                                      };
                                      (
                                        window as any
                                      ).ReactNativeWebView.postMessage(
                                        JSON.stringify(message)
                                      );
                                    }
                                  }}
                                >
                                  {t("signup.form.termOfService")}
                                </span>
                              )}
                            </span>
                            <span>{t("signup.form.accept")}</span>
                          </>
                        ),
                        value: true,
                      },
                    ]}
                    formgroup={form}
                    rules={[{ required: true, message: "" }]}
                  />
                </Col>
              </Row>

              <div className="ui-sign-up-form__action">
                <Row gutter={[48, 48]} justify="center" className="h-p-8">
                  <Col xs={24} md={12} xl={16}>
                    <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                      {() => (
                        <AppButton
                          disabled={
                            !form.isFieldsTouched(true) ||
                            form
                              .getFieldsError()
                              .filter(({ errors }) => errors.length).length > 0
                          }
                          // onClick={() => {
                          //   CommonService.scrollToError();
                          // }}
                          buttontype="primary"
                          htmlType="submit"
                        >
                          {t("signup.form.register")}
                        </AppButton>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default SignUpForm;

SignUpForm.defaultProps = {};
