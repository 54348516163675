import React, { FC, useState } from "react";
import "./index.scss";
import MyPageMenu from "./components/Menu";

const MyPage: FC = () => (
    <div className="ui-my-page">
      <MyPageMenu />
    </div>
  );

export default MyPage;
