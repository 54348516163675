import React, { FC } from "react";
import classnames from "classnames";
import { PURCHASE_HISTORY_TYPE } from "../../model";

interface Props {
  config: { label: string, value: PURCHASE_HISTORY_TYPE }[],
  currentValue: PURCHASE_HISTORY_TYPE,
  onChange: (value: PURCHASE_HISTORY_TYPE) => void;
}

const Tab: FC<Props> = (({ ...props }) => {
  const { config, currentValue, onChange } = props;

  return (
    <div className="ui-tab">
      {
        config && config.length && config.map(({ label, value }, index) => (
          <div
            onClick={() => onChange(value)}
            key={index}
            className={classnames("ui-tab__pane", value === currentValue && "active")}
          >
            { label }
          </div>
        ))
      }
    </div>
  );
});

export default Tab;

Tab.defaultProps = {};
