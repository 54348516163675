import React from "react";
import "./index.scss";
import AppButton from "@components/AppButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setOffline } from "@store/State";
import { PATH_NAME } from "src/configs";
import { clearAll } from "@store/Product-Filter";

const NetworkError = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");

  return (
    <div className="ui-network-error">
      <div className="ui-network-error__content">
        <div className="ui-network-error__title">
          インターネット接続がありません。
        </div>
        <div className="ui-network-error__action">
          <AppButton
            buttontype="primary"
            onClick={() => {
              if (window.navigator.onLine) {
                dispatch(setOffline(false));
                dispatch(clearAll());
                navigate("/");
              }
            }}
          >
            TOP画面を表示
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default NetworkError;
