import { ReactNode } from "react";

export interface SignUpFormValue {
  emailAddress?: string;
  emailAddressConfirmation?: string;
  fullName?: string;
  dateOfBirth?: any;
  phoneNumber?: string;
  companyName?: string;
  department?: string;
  buildingName?: string;
  position?: string;
  zipCode?: string | number;
  zipCode1?: string | number;
  zipCode2?: string | number;
  district?: string;
  city?: string;
  address?: string;
  street?: string;
  password?: string;
  passwordConfirmation?: string;
  isAgreeWithTerm?: boolean;
}

export interface ZipCodeValue {
  district: string;
  province: string;
  street: string;
  zipCode: string;
}

export enum SignUpStatus {
  INPUT = 0,
  CONFIRM = 1,
  SENT_EMAIL = 2,
  SUCCESS = 3,
}

export enum SignUpStatusName {
  SIGNUP_INPUT = "login.signUp.text",
  SIGNUP_CONFIRM = "login.signUp.confirm",
  SIGNUP_SENT_EMAIL = "login.signUp.completed",
  SIGNUP_SUCCESS = "login.signUp.completed",
}

export enum SignUpFormLabel {
  EMAIL = "signup.form.email",
  USER_NAME = "signup.form.name",
  DATE_OF_BIRTH = "signup.form.dateOfBirth",
  COMPANY_NAME = "signup.form.companyName",
  DEPARTMENT = "signup.form.department",
  BUILD = "signup.form.buildingName",
  POSITION = "signup.form.position",
  POSTAL_CODE = "signup.form.zipCode",
  PHONE_NUMBER = "signup.form.phoneNumber",
  PREFECTURES = "signup.form.district",
  MUNICIPALITIES = "signup.form.city",
  STREET = "signup.form.street",
}

export interface CollapseData {
  title: string;
  content: ReactNode;
}
