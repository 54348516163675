

const TOKEN_KEY = 'user-token';
const MOBILE_DEVICE = 'mobile-device';
const LANGUAGE = 'language';

const saveUserToken = (token: string, remember: boolean) =>
    remember ? localStorage.setItem(TOKEN_KEY, token) : sessionStorage.setItem(TOKEN_KEY, token)
;

const deleteUserToken = () => {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(TOKEN_KEY);
};

const getUserToken = () => localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY) || null;

const saveDevice = (mobile: string) => sessionStorage.setItem(MOBILE_DEVICE, mobile);

const deleteDevice = () => sessionStorage.removeItem(MOBILE_DEVICE);

const getDevice = () => sessionStorage.getItem(MOBILE_DEVICE);

const rememberMe = () => !!localStorage.getItem(TOKEN_KEY);

const getLanguage = () => sessionStorage.getItem(LANGUAGE);

const setLanguage = (language: string) => sessionStorage.setItem(LANGUAGE, language);

const deleteLanguage = () => sessionStorage.removeItem(LANGUAGE);

const StorageService = { saveUserToken, deleteUserToken, getUserToken, saveDevice, deleteDevice, getDevice, rememberMe, getLanguage, setLanguage, deleteLanguage };

export default StorageService;
