import classNames from "classnames";
import { cloneDeep, get } from "lodash";
import React, { FC, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useNavigate, createSearchParams } from "react-router-dom";
import AppImageView from "src/components/AppImageView";
import { PATH_NAME } from "src/configs";
import {
  ProductItem,
  ProductTransactionStatus,
} from "src/models/product.model";
import ProductService from "src/services/API/Product";
import { EyeOutlined, HeartOutlined, HeartFilled } from "@ant-design/icons";
import "./styles.scss";
import { RootState } from "src/store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { updateLike } from "src/store/Product";
import { AppDispatch } from "@store/configureStore";
import AppModal from '@components/AppModal';

interface Props {
  product: ProductItem;
  type?: "SEARCH" | "DASHBOARD";
}

export const ItemDesktop: FC<Props> = ({ ...props }) => {
  const { product, type } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [item, setItem] = useState<ProductItem>(product);
  const [loadingLike, setLoadingLike] = useState(false);
  const isLogined = useSelector(
    (state: RootState) => state.UserReducer.isLogin
  );
  const likeStatus = useSelector(
    (state: RootState) => state.ProductReducer.likeStatus
  );
  const user = useSelector((state: RootState) => state.UserReducer.userInfo);
  const isMyProduct = item?.userId === user?.id;
  const [confirmLogin, setConfirmLogin] = useState<boolean>(false);

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  useEffect(() => {
    if (likeStatus?.id === product.id) {
      setItem((item) => ({
        ...item,
        canRemoveFromFavorite: likeStatus.status,
        favoriteCount: likeStatus.count,
      }));
    }
  }, [likeStatus]);

  async function handleLike() {
    setLoadingLike(true);
    const response = await dispatch(updateLike(item));

    if (updateLike.rejected.match(response)) {
      setLoadingLike(false);
    }
    if (updateLike.fulfilled.match(response)) {
      setLoadingLike(false);
    }
  }

  // function handleLike() {
  //   setLoadingLike(true);
  //   ProductService.updateLike(Number(item.id))
  //     .then((res) => {
  //       setLoadingLike(false);
  //       const itemClone = cloneDeep(item);
  //       itemClone.favoriteCount = item.canRemoveFromFavorite
  //         ? item.favoriteCount - 1
  //         : item.favoriteCount + 1;
  //       itemClone.canRemoveFromFavorite = !item.canRemoveFromFavorite;
  //       setItem({ ...itemClone });
  //     })
  //     .catch((err) => {
  //       setLoadingLike(false);
  //     });
  // }

  function getColor(status: ProductTransactionStatus) {
    return "#FFA726";
  }

  function getBackground(status: ProductTransactionStatus) {
    return "#FFEACC";
  }

  function goToDetail(params: any) {
    navigate(`/product-detail/${params.id}`);
  }

  function getImage() {
    if (!item || !(item.productMedias || []).length) {
      // return "/assets/images/fake3.png";
      return "";
    }
    const firstImage = item.productMedias.filter((f) => f.type === 1)[0];
    // return firstImage ? firstImage.url.concat(sasCode as string) : "/assets/images/fake3.png";
    return firstImage ? firstImage.url.concat(sasCode as string) : "";
  }

  return (
    <>
      <AppModal
        visible={confirmLogin}
        title="この先の機能はログインが必要となります。"
        content=""
        okText="ログイン"
        cancelText="閉じる"
        onOk={() => navigate(PATH_NAME.LOGIN)}
        onCancel={() => {
          setConfirmLogin(false);
        }}
      />
      <div className="list-slider-item-lg">
        {(!isMyProduct || !isLogined) && (
          <div
            className={classNames("button-like", "color-primary", {
              disabled: loadingLike,
            })}
            onClick={() => {
              if (!isLogined) {
                setConfirmLogin(true);
                return;
              }
              handleLike();
            }}
          >
            {item.canRemoveFromFavorite ? <HeartFilled /> : <HeartOutlined />}
          </div>
        )}

        <AppImageView src={getImage()} />

        <div className="product-title color-title bold text-body-4 three-dot">
          {item.title}
        </div>
        <div className="product-des color-title bold text-body-4">
          <span className="three-dot">{product.maker} </span>
          <span className="min-w-110 pl-10">
          { item?.yearOfManufacture ? item?.yearOfManufacture >= 1980
            ? `${item?.yearOfManufacture}年式`
            : "1980年以前" : "年式不明"}
        </span>
        </div>
        <div className="product-price color-primary bold text-body-2">
          <span className="text-caption-2">¥</span>
          <NumberFormat
            value={product.price}
            displayType="text"
            thousandSeparator
          />
        </div>
        <div className="product-action">
          <EyeOutlined />
          <span className="text-value">
          {item.viewCount > 999 ? "999+" : item.viewCount}
        </span>
          <HeartOutlined />
          <span className="text-value">
          {item.favoriteCount > 999 ? "999+" : item.favoriteCount}
        </span>

          {/* <div className="action" onClick={() => goToDetail({ id: product.id })}>
            <ArrowRightOutlined />
          </div> */}
          {item?.orderStatus && (
            <span
              className="text-action-search text-caption-2"
              style={{
                background: getBackground(item?.orderStatus),
                color: getColor(item?.orderStatus),
              }}
            >
            取引中
          </span>
          )}
        </div>

        <div
          className="lg-button-action"
          onClick={() => goToDetail({ id: product.id })}
        >
          詳細を見る
        </div>
      </div>
    </>
  );
};
// eslint-disable-next-line react/default-props-match-prop-types
