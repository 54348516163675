import React, { FC, memo, ReactNode } from "react";
import { Drawer, DrawerProps } from "antd";
import "./styles.scss";

interface Props extends DrawerProps {
  onClose: any;
  body: ReactNode;
}

const AppBottomSheet: FC<Props> = memo(({ ...props }) => (
  <Drawer
    {...props}
    placement="bottom"
    width={500}
    onClose={props.onClose}
    extra={
      <div onClick={props.onClose} className="text-body-4 color-body app-btn-close">
        キャンセル
      </div>
    }
    className="app-bottom-sheet"
  >
    { props.body }
  </Drawer>
));

// });

export default AppBottomSheet;

AppBottomSheet.defaultProps = {};
