import React, { FC, useEffect, useRef, useState } from "react";
import "./index.scss";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Col, Row } from "antd";
import AppTimeline from "@components/AppTimeline";
import AppFieldset from "@components/AppFieldset";
import AppRow, { RowProps } from "@components/AppRow";
import AppButton from "@components/AppButton";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import AppModal, { AppModalProps } from "src/components/AppModal";
import {
  CANCEL_SELL_REASON,
  ContactType,
  ProductHistoryDetail,
  ProductTransactionStatus,
} from "src/models/product.model";
import { useTranslation } from "react-i18next";
import AppNavigation from "@components/AppNavigation";
import moment from "moment";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import { PATH_NAME } from "src/configs";
import { useDispatch, useSelector } from "react-redux";
import { setAppLoading, setError, setTitle } from "src/store/State";
import ProductService from "src/services/API/Product";
import { AppDispatch, RootState } from "src/store/configureStore";
import AppModalConfirmTransfer from "src/components/AppModalConfirmTransfer";
import { Desktop, Mobile } from "src/layouts/break-point";
import { useMediaQuery } from "react-responsive";
import { setRoomId } from "src/store/Chat";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import AppModalCancelTransaction from "src/components/AppModalCancelTransaction";
import { PreCallInfo } from "src/pages/visit-online/Preparation/model";
import { buildLink, getIdError, getOS, handleOpenApp, paramsDisableModal } from "src/helper";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import AppTransactionSkeleton from "src/components/skeleton/AppTransactionSkeleton";
import useScrollTop from "src/hooks/useScrollTop";
import classNames from "classnames";
import { injectHandleOk } from "src/App";
import { TransactionType, TRANSACTION_TIMELINE } from "../../model";
import {
  convertToTransaction,
  convertToTransactionInfo,
} from "../Common/index";
import StorageService from "../../../../services/Storage";

const SaleHistoryDetail: FC = ({ ...props }) => {
  useScrollTop();
  useHideBottomBar(true);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const disableModal = searchParams.get("disableModal");

  const userInfo = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );

  const { id } = useParams();

  const href = `haraseikouapp://home/salesHistory${id}`;

  const { t: translate } = useTranslation();

  const disPatch = useDispatch();

  const dispatch = useDispatch<AppDispatch>();

  const [productDetail, setProductDetail] = useState<ProductHistoryDetail>();

  const [isLoading, setIsLoading] = useState(false);

  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const [enableVideoCall, setEnableVideoCall] = useState(false);

  const [infoWarning, setInfoWarning] = useState<AppModalProps>();

  const [infoSuccess, setInfoSuccess] = useState<AppModalProps>();

  const [productHistory, setProductHistory] = useState<RowProps[]>();

  const [isConfirmTransfer, setIsConfirmTransfer] = useState(false);
  const [successModalConfirmVisible, setSuccessModalConfirmVisible] =
    useState(false);

  const [isConfirmCancelTransaction, setIsConfirmCancelTransaction] =
    useState<boolean>(false);

  const [successModalCancelVisible, setSuccessModalCancelVisible] =
    useState<boolean>(false);

  const location = useLocation();
  let timeOutVisitOnline: NodeJS.Timeout;

  const [transactionStatus, setTransactionStatus] = useState<
    TRANSACTION_TIMELINE[]
  >([]);

  const [currentStatus, setCurrentStatus] = useState<ProductTransactionStatus>(
    ProductTransactionStatus.IN_REQUEST
  );

  const [enableStartCall, setEnableStartCall] = useState(false);

  const [showModalDownload, setShowModalDownload] = useState<boolean>(false);

  const isApp = (window as any).ReactNativeWebView;

  const [isFinishedShipment, setIsFinishedShipment] = useState(false);

  const [showApp, setShowApp] = useState<boolean>(false);

  const timeline = useRef(null);

  function handleBack() {
    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      if (productDetail?.orderStatus === ProductTransactionStatus.DONE) {
        const lastPath = currentPathname[currentPathname.length - 3];
        if (lastPath && lastPath.includes("tab=0")) {
          navigate("/my-page/sales-history?tab=1");
          return;
        }
      }
      navigate((location.state as any)?.routeState || -1);
    }
  }

  useEffect(() => {
    if (
      !disableModal &&
      (!currentPathname?.length || currentPathname.length === 1) &&
      !StorageService.getDevice()
    ) {
      handleOpenApp(href, setShowApp);
    }
    if (!disableModal) {
      navigate(paramsDisableModal(), { replace: true });
    }
  }, []);

  function updateStatusProductHistoryDetail(action: () => void) {
    if (!productDetail) {
      return;
    }

    disPatch(setAppLoading(true));
    ProductService.updateStatusProductHistoryDetail({
      id: productDetail?.id,
      token: productDetail?.orderToken,
    })
      .then((res) => {
        disPatch(setAppLoading(false));
        action();
      })
      .catch((err) => {
        disPatch(setAppLoading(false));
      });
  }

  function convertToView(productHistoryDetail: ProductHistoryDetail) {
    const action = () => {
      navigate(`/product-detail/${productHistoryDetail.productId}`, {
        state: { backUrl: `${location.pathname}${location.search}` },
      });
    };
    setProductHistory(convertToTransactionInfo(productHistoryDetail, action));
  }

  function convertToTransactionStep(productHistory: ProductHistoryDetail) {
    setTransactionStatus(
      convertToTransaction(productHistory, TransactionType.SALE)
    );
  }

  function handleClickVideoCall() {
    if (isApp) {
      if (enableStartCall) {
        ProductService.getContactDetail(productDetail?.contactId)
          .then((res) => {
            const preCallInfo: PreCallInfo = res.data.result;
            const message = {
              type: "JOIN_CALL",
              data: preCallInfo,
            };
            (window as any).ReactNativeWebView.postMessage(
              JSON.stringify(message)
            );
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(setAppLoading(false));
          });
      } else {
        navigate(
          `/visit-online/preparation?contactId=${productDetail?.contactId}&productId=${productDetail?.productId}`
        );
      }
    } else {
      setShowModalDownload(true);
    }
  }

  function checkDisableVideoCall(productDetail: ProductHistoryDetail) {
    setEnableVideoCall(false);
    const timeCall = moment(productDetail?.contactTime).valueOf();
    const now = moment().valueOf();
    const diff = (timeCall - now) / (1000 * 60);
    if (diff > -40 && diff <= 40) {
      setEnableVideoCall(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      if (diff > 0) {
        if (timeOutVisitOnline) {
          clearTimeout(timeOutVisitOnline);
        }
        timeOutVisitOnline = setTimeout(() => {
          setEnableVideoCall(true);
        }, diff * 60 * 1000);
      } else {
        setEnableStartCall(true);
      }
    }
  }

  function getDetail() {
    // disPatch(setAppLoading(true));
    setIsLoading(true);
    ProductService.getProductHistoryDetail(Number(id))
      .then((res) => {
        setIsLoading(false);
        const { buyerId, productId } = res.data.result;
        if (userInfo?.id === buyerId) {
          dispatch(
            setError({ message: "M0052", title: "common.modal.userError" })
          );
          // navigate("/dashboard");
        } else {
          if (productId) {
            ProductService.getProduct(productId).then(response => {
              const product = response.data.result;
              const location = `${product?.city || ''} ${product?.district || ''} ${product?.street || ''}`;
              convertToView({ ...res.data.result, location });
            });
          }
          setProductDetail(res.data.result);
          setCurrentStatus(res.data.result.orderStatus);
          convertToTransactionStep(res.data.result);
          checkDisableVideoCall(res.data.result);
          setTimeout(() => {
            if (timeline.current) {
              (timeline.current as any).scrollIntoView({
                behavior: "smooth",
                // block: "center",
                // inline: "start",
              });
            }
          }, 100);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally(() => {
        // disPatch(setAppLoading(false));
        setIsLoading(false);
      });
  }

  const updateStatus = () => {
    setIsFinishedShipment(false);
    dispatch(setAppLoading(true));
    ProductService.updateStatusTransfer({
      id: Number(productDetail?.id),
      token: productDetail?.orderToken as string,
    })
      .then((res) => {
        if (res.data.result.id) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getDetail();
        }
      })
      .catch((err) => {
        console.log(err);
        if (getIdError(err) === "M0127") {
          injectHandleOk(() => getDetail());
        }
      })
      .finally(() => {
        dispatch(setAppLoading(false));
      });
  };

  useEffect(() => {
    getDetail();
  }, [id]);

  const confirmTransfer = () => {
    setIsConfirmTransfer(true);
  };

  // const confirmContact = () => {
  //   setInfoWarning({
  //     title: translate("transaction.contact.confirm.title"),
  //     content: translate("transaction.contact.confirm.content"),
  //     okText: translate("public.product.save.ok"),
  //     cancelText: translate("transaction.cancel.confirm.ok"),
  //     onOk: () => {
  //       setShowModalConfirm(false);

  //       updateStatusProductHistoryDetail(() => {
  //         setInfoSuccess({
  //           title: translate("transaction.contact.success.title"),
  //           content: translate("transaction.contact.success.content"),
  //           onOk: () => {
  //             getDetail();
  //             setShowModalSuccess(false);
  //           },
  //         });
  //         setShowModalSuccess(true);
  //       });
  //     },
  //   });

  //   setShowModalConfirm(true);
  // };

  function backToDashboard() {
    navigate(`/${PATH_NAME.DASHBOARD}`);
  }

  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  const editProductButton = () => (
    <AppButton
      buttontype="primary"
      onClick={() => {
        navigate(`${PATH_NAME.EDIT_PRODUCT}?id=${productDetail?.productId}`);
      }}
      className={classNames(
        "app-button ant-btn-custom-primary",
        currentStatus === ProductTransactionStatus.IN_CONTACT &&
          productDetail?.contactType === ContactType.OnlineTour &&
          "mt-8"
      )}
    >
      商品を編集する
    </AppButton>
  );

  const renderAction = () => {
    switch (currentStatus) {
      case ProductTransactionStatus.IN_PURCHASE:
        return editProductButton();

      case ProductTransactionStatus.IN_REQUEST:
        return (
          <>
            <AppButton
              buttontype="primary"
              onClick={() => {
                navigate(
                  `/my-page/sales-history/confirm-contact/${productDetail?.contactId}`
                );
              }}
            >
              {translate("sale.history.in_request")}
            </AppButton>

            {editProductButton()}
          </>
        );
      case ProductTransactionStatus.IN_CONTACT:
        return (
          <>
            {(productDetail?.contactType === ContactType.MessageOnly ||
              productDetail?.contactType === ContactType.OnlineTour) && (
              <>
                {productDetail?.contactType === ContactType.MessageOnly && (
                  <AppButton
                    buttontype="primary"
                    onClick={() => {
                      dispatch(
                        setRoomId(
                          `${productDetail.productId}${productDetail?.buyerId}${productDetail.sellerId}`
                        )
                      );
                    }}
                  >
                    {translate("sale.history.in_contact_message")}
                  </AppButton>
                )}

                {productDetail?.contactType === ContactType.OnlineTour && (
                  <>
                    <AppButton
                      buttontype="primary"
                      disabled={!enableVideoCall}
                      onClick={() => {
                        handleClickVideoCall();
                      }}
                    >
                      {translate("sale.history.in_contact_video")}
                    </AppButton>
                    <p className="contact-time_guide-line">
                      見学時間：
                      {moment(productDetail?.contactTime).add(40, "minute") >
                      moment()
                        ? `${moment(productDetail?.contactTime).format(
                            "MM月DD日 HH:mm"
                          )}（5分前から入室できます。）`
                        : moment(productDetail?.contactTime).format("HH時mm分")}
                    </p>
                  </>
                )}
              </>
            )}

            {editProductButton()}
          </>
        );

      case ProductTransactionStatus.IN_CONFIRM:
        return editProductButton();

      case ProductTransactionStatus.PAYMENT_COMPLETE:
        return (
          <>
            <AppButton
              buttontype="primary"
              onClick={() => {
                confirmTransfer();
              }}
            >
              {productDetail?.expectedArrivalDate
                ? translate(
                    "sale.history.edit.estimated.delivery.schedule.button"
                  )
                : translate(
                    "sale.history.setting.estimated.delivery.schedule.button"
                  )}
            </AppButton>

            <AppButton
              disabled={!productDetail?.scheduledDeliveryDate}
              buttontype="primary"
              onClick={() => {
                // updateStatus();
                setIsFinishedShipment(true);
              }}
            >
              {translate("transaction.transfer.button")}
            </AppButton>
          </>
        );

      default:
        return undefined;
    }
  };

  const cancelButton = () => (
    <div className="cancel-button">
      <AppButton
        buttontype="secondary"
        onClick={() => {
          setIsConfirmCancelTransaction(true);
        }}
      >
        {translate("sale.history.cancel")}
      </AppButton>
    </div>
  );

  const renderActionBottom = () => (
      <Row gutter={[16, 16]} justify="center">
        {[ProductTransactionStatus.IN_PURCHASE, ProductTransactionStatus.IN_REQUEST, ProductTransactionStatus.IN_CONTACT, ProductTransactionStatus.IN_CONFIRM].includes(currentStatus) && (
            <Col xs={24} md={8} lg={6}>
              <AppButton
                  buttontype="secondary"
                  onClick={() => {
                    setIsConfirmCancelTransaction(true);
                  }}
              >
                {translate("sale.history.cancel")}
              </AppButton>
            </Col>
        )}
        {currentStatus === ProductTransactionStatus.DONE && (
            <Col xs={24} md={8} lg={6}>
              <AppButton buttontype="primary" onClick={backToDashboard}>
                {translate("transaction.done.button.toDashboard")}
              </AppButton>
            </Col>
        )}
        {![ProductTransactionStatus.CONFIRM_RECEIVED || ProductTransactionStatus.DONE || ProductTransactionStatus.CANCEL].includes(currentStatus) && (
            <Col xs={24} md={8} lg={6}>
              <AppButton
                  buttontype="primary"
                  onClick={() => {
                    dispatch(
                        setRoomId(
                            `${productDetail?.productId}${productDetail?.buyerId}${productDetail?.sellerId}`
                        )
                    );
                  }}
              >
                チャット
              </AppButton>
            </Col>
        )}
      </Row>
  );

  useEffect(() => {
    dispatch(setTitle(translate("my.page.sales.history")));
    return () => {
      dispatch(setTitle(""));
      if (timeOutVisitOnline) {
        clearTimeout(timeOutVisitOnline);
      }
    };
  }, []);

  const textDownload = (
    <div style={{ marginTop: 4 }}>
      <p>{translate("prepration.download.guide1")}</p>
      <p>{translate("prepration.download.guide2")}</p>
      <p>{translate("prepration.download.guide3")}</p>
      <p>{translate("prepration.download.guide4")}</p>
    </div>
  );

  return (
    <>
      <AppModal
        visible={showApp}
        title="このページを“マシプラ”で開きますか？"
        cancelText="キャンセル"
        onCancel={() => setShowApp(false)}
        onOk={() => {
          setShowApp(false);
          buildLink(href);
        }}
        okText={
          getOS() === "Android" ? (
            <a href={href}>
              <span style={{ color: "#fff" }}>あける</span>
            </a>
          ) : (
            <span style={{ color: "#fff" }}>あける</span>
          )
        }
      />
      {isFinishedShipment && (
        <AppModal
          icon={<Warning />}
          visible={true}
          title="搬出を完了します。よろしいでしょうか。"
          content="搬出完了通知が購入者に送信されます。"
          okText="はい"
          cancelText="いいえ"
          onOk={() => {
            updateStatus();
          }}
          onCancel={() => {
            setIsFinishedShipment(false);
          }}
        />
      )}
      {showModalDownload && (
        <AppModal
          icon={<Warning />}
          visible={showModalDownload}
          title="この機能は開発中です。"
          content="ブラウザではオンライン見学をご利用できません。"
          okText="閉じる"
          // cancelText="閉じる"
          // onOk={() => {}}
          onOk={() => {
            setShowModalDownload(false);
          }}
        />
      )}

      {isConfirmCancelTransaction && (
        <AppModalCancelTransaction
          handleCancel={() => getDetail()}
          visible={isConfirmCancelTransaction}
          title={translate("sale.history.transaction.warning.title")}
          content={translate("sale.history.transaction.warning.content")}
          okText={translate("sale.history.transaction.warning.button.ok")}
          cancelText={translate(
            "sale.history.transaction.warning.button.cancel"
          )}
          onCancel={() => {
            setIsConfirmCancelTransaction(false);
          }}
          onOk={() => {
            setIsConfirmCancelTransaction(false);
            setSuccessModalCancelVisible(true);
          }}
          transactionId={Number(productDetail?.id)}
          orderToken={productDetail?.orderToken || ""}
          cancelReasonList={CANCEL_SELL_REASON}
        />
      )}

      <AppModal
        icon={<Success />}
        visible={successModalCancelVisible}
        title={translate("sale.history.transaction.success.title")}
        content={translate("sale.history.transaction.success.content")}
        okText={translate("sale.history.transaction.success.button.close")}
        onOk={() => {
          getDetail();
          setSuccessModalCancelVisible(false);
        }}
      />

      {isConfirmTransfer && (
        <AppModalConfirmTransfer
          handleCancel={() => getDetail()}
          visible={isConfirmTransfer}
          onCancel={() => {
            setIsConfirmTransfer(false);
          }}
          onOk={() => {
            setIsConfirmTransfer(false);
            setSuccessModalConfirmVisible(true);
          }}
          value={{
            expectedArrivalDate: productDetail?.expectedArrivalDate,
            scheduledDeliveryDate: productDetail?.scheduledDeliveryDate,
          }}
          transactionId={Number(productDetail?.id)}
        />
      )}
      <AppModal
        icon={<Success />}
        visible={successModalConfirmVisible}
        title="搬出予定日が購入者に通知されました。"
        content={moment().format("YYYY/MM/DD HH:mm")}
        okText="閉じる"
        onOk={() => {
          getDetail();
          setSuccessModalConfirmVisible(false);
        }}
      />

      <AppModal
        visible={showModalConfirm}
        title={infoWarning?.title}
        content={infoWarning?.content}
        okText={infoWarning?.okText}
        cancelText={infoWarning?.cancelText}
        onCancel={() => setShowModalConfirm(false)}
        onOk={infoWarning?.onOk}
      />

      <AppModal
        icon={<Success />}
        visible={showModalSuccess}
        title={infoSuccess?.title}
        content={infoSuccess?.content}
        okText={translate("contact.modal.warning.confirm")}
        onOk={infoSuccess?.onOk}
      />

      <AppPullToRefresh
        onRefresh={() => {
          getDetail();
        }}
      >
        <div className="ui-my-page-purchase-history-detail">
          <AppNavigation
            title={translate("transaction.sales.history")}
            onClick={() => {
              // navigate((location.state as any)?.routeState || -1);
              handleBack();
            }}
          />

          {isLoading ? (
            <AppTransactionSkeleton></AppTransactionSkeleton>
          ) : (
            <div className="detail-container">
              <Desktop>
                <div className="ui-my-page-purchase-history-detail__timeline">
                  <Row gutter={[32, 32]} justify="center">
                    <Col xs={24}>
                      <AppTimeline
                        dataSource={transactionStatus}
                        currentStatus={currentStatus}
                        actionView={renderAction()}
                      />
                    </Col>
                  </Row>
                </div>
              </Desktop>

              {currentStatus === ProductTransactionStatus.IN_CONTACT && (
                <Row gutter={[32, 32]} justify="center">
                  <Col xs={24}>
                    {productDetail?.contactType === ContactType.OnlineTour && (
                      <div className="ui-my-page-purchase-history-detail__type-contact">
                        この商品の取引にはオンライン見学が指定されています。
                      </div>
                    )}
                    {productDetail?.contactType ===
                      ContactType.LocalPreview && (
                      <div className="ui-my-page-purchase-history-detail__type-contact">
                        この商品の取引には現地内見が指定されています。
                      </div>
                    )}
                  </Col>
                </Row>
              )}

              <Row gutter={[32, 32]} justify="center">
                <Col xs={24}>
                  <AppFieldset title={translate("transaction.detail")}>
                    {productHistory &&
                      productHistory.length &&
                      productHistory.map(
                        (
                          {
                            label,
                            value,
                            valueColor,
                            action,
                            isHidden,
                            labelWidth,
                            valueWidth,
                          },
                          index
                        ) => (
                          <AppRow
                            isHidden={isHidden}
                            action={action}
                            key={index}
                            label={label}
                            value={value}
                            labelWidth={labelWidth}
                            valueWidth={valueWidth}
                            valueColor={valueColor}
                            hasBorderBottom={
                              index !== productHistory.length - 1
                            }
                          />
                        )
                      )}
                  </AppFieldset>
                </Col>
              </Row>

              <Mobile>
                <div
                  className="ui-my-page-purchase-history-detail__timeline"
                  ref={timeline}
                >
                  <Row gutter={[32, 32]} justify="center">
                    <Col xs={24}>
                      <AppTimeline
                        dataSource={transactionStatus}
                        currentStatus={currentStatus}
                        actionView={renderAction()}
                      />
                    </Col>
                  </Row>
                </div>
              </Mobile>

              <div className="ui-my-page-purchase-history-detail__action">
                {renderActionBottom()}
              </div>
            </div>
          )}
        </div>
      </AppPullToRefresh>
    </>
  );
};

export default SaleHistoryDetail;

SaleHistoryDetail.defaultProps = {};
