import AppHttpClient from "@core/http";


const getHeaderSlider = () => AppHttpClient.get(`api/services/app/BannerService/GetHeaderBanners`);
const getBanner = () => AppHttpClient.get(`api/services/app/BannerService/GetFooterBanners`);
const getPriceFilter = () => AppHttpClient.get(`api/services/app/Configuration/GetPriceConfig`);
const getSystemConfig = () => AppHttpClient.get(`api/services/app/Configuration/GetSystemConfig`);
const getPolicyOrderConfig = () => AppHttpClient.get(`api/services/app/Configuration/GetOrderPolicy`);
const handleClickBanner = (bannerId: string) => AppHttpClient.post(`api/services/app/BannerService/Click`, { bannerId });

const GlobalService = { getHeaderSlider, getBanner, getPriceFilter, getSystemConfig, handleClickBanner, getPolicyOrderConfig };
export default GlobalService;