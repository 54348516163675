import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThemeMode } from '@theme/theme.config';

export enum LanguageMode {
    EN = 'en',
    JP = 'jp'
}
interface ConfigState {
    theme: ThemeMode;
    language: LanguageMode;
}

export const initialState: ConfigState = {
    theme: ThemeMode.LIGHT,
    language: LanguageMode.EN
};

const slice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        changeTheme(state, action: PayloadAction<ThemeMode>) {
            state.theme = action.payload;
        },
        changeLanguage(state, action: PayloadAction<LanguageMode>) {
            state.language = action.payload;
        },
    },
});

export const { changeTheme, changeLanguage } = slice.actions;

export default slice.reducer;