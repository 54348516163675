import AppHttpClient from '@core/http/index';
import { ProductFilter, ProductSort } from 'src/store/Product-Filter';
import { get, isEmpty, cloneDeep, clone, uniq, orderBy } from 'lodash';
import { Category, ProductPublicStatus, ProductItem } from '@models/product.model';
import { ProductBody } from 'src/models/product.model';
import { ContactPayloadValue } from "@pages/contact/model";
import AppStore from 'src/store/configureStore';
import { PurchaseHistorySearchParams } from 'src/pages/my-page/model';

function getTypeProduct(isPickup?: boolean, isRecently?: boolean) {
    if (!isPickup && isRecently) {
        return 2;
    }

    if (isPickup && !isRecently) {
        return 1;
    }

    return 3;
}

const getListCategory = () => AppHttpClient.get('/api/services/app/CategoryService/GetAllCategories');
const getCalculateProductFees = (price: number) => AppHttpClient.post(`/api/services/app/Product/CalculateProductFees?price=${price}`, {});
const getListProductDashboard = (isPickup: boolean, isRecently: boolean) => AppHttpClient.get(`/api/services/app/Product/GetProductDashboard?ProductTypeSearch=${getTypeProduct(isPickup, isRecently)}&SkipCount=0&MaxResultCount=3`);
const getListProductByFilter = (filter?: ProductFilter, sort?: ProductSort, skip?: number, get?: number, keyword?: string) => {
    const categories = isEmpty(filter?.categories) ? null : filter?.categories?.join(',');
    const citySearch = isEmpty(filter?.citySearch) ? null : filter?.citySearch?.join(',');
    return AppHttpClient.get(`/api/services/app/Product/GetAllWithCategory?`
        + `SkipCount=${skip || 0}`
        + `&MaxResultCount=${get || 5}`
        + `&Keyword=${encodeURIComponent(keyword || '')}`
        + `&ProductTypeSearch=${getTypeProduct(filter?.isPickup, filter?.isRecently)}`
        // + `&isRecently=${(filter?.isRecently && filter?.isPickup) ? false : !!filter?.isRecently}`
        // + `&isPickup=${(filter?.isRecently && filter?.isPickup) ? false : !!filter?.isPickup}`
        + `${categories ? `&Categories=${categories}` : ''}`
        + `${filter?.maker ? `&Maker=${filter?.maker}` : ''}`
        + `${filter?.yearOfManufactureFrom ? `&YearOfManufactureFrom=${filter?.yearOfManufactureFrom}` : ''}`
        + `${filter?.yearOfManufactureTo ? `&YearOfManufactureTo=${filter?.yearOfManufactureTo}` : ''}`
        + `${filter?.priceFrom ? `&PriceFrom=${filter?.priceFrom}` : ''}`
        + `${filter?.priceTo ? `&PriceTo=${filter?.priceTo}` : ''}`
        + `${citySearch ? `&citySearch=${citySearch}` : ''}`
        + `${sort?.sortByPrice ? `&SortByPrice=${sort?.sortByPrice}` : ''}`
        + `${sort?.sortByRegisterDate ? `&SortByRegisterDate=${sort?.sortByRegisterDate}` : ''}`
        + `${sort?.sortByYearOfManufacture ? `&SortByYearOfManufacture=${sort?.sortByYearOfManufacture}` : ''}`);
};
const getListProductByFilterV2 = (filter?: ProductFilter, sort?: ProductSort, skip?: number, get?: number, keyword?: string) => {
    const categories = isEmpty(filter?.categories) ? null : filter?.categories?.join(',');
    const citySearch = isEmpty(filter?.citySearch) ? null : filter?.citySearch?.join(',');
    return AppHttpClient.get(`/api/services/app/Product/GetAllWithCategory?`
        + `SkipCount=${skip || 0}`
        + `&MaxResultCount=${get || 5}`
        + `&Keyword=${encodeURIComponent(keyword || '')}`
        + `&ProductTypeSearch=${getTypeProduct(filter?.isPickup, filter?.isRecently)}`
        // + `&isRecently=${(filter?.isRecently && filter?.isPickup) ? false : !!filter?.isRecently}`
        // + `&isPickup=${(filter?.isRecently && filter?.isPickup) ? false : !!filter?.isPickup}`
        + `${categories ? `&Categories=${categories}` : ''}`
        + `${filter?.maker ? `&Maker=${filter?.maker}` : ''}`
        + `${filter?.yearOfManufactureFrom ? `&YearOfManufactureFrom=${filter?.yearOfManufactureFrom}` : ''}`
        + `${filter?.yearOfManufactureTo ? `&YearOfManufactureTo=${filter?.yearOfManufactureTo}` : ''}`
        + `${filter?.priceFrom ? `&PriceFrom=${filter?.priceFrom}` : ''}`
        + `${filter?.priceTo ? `&PriceTo=${filter?.priceTo}` : ''}`
        + `${citySearch ? `&citySearch=${citySearch}` : ''}`
        + `${sort?.sortByPrice ? `&SortByPrice=${sort?.sortByPrice}` : ''}`
        + `${sort?.sortByRegisterDate ? `&SortByRegisterDate=${sort?.sortByRegisterDate}` : ''}`
        + `${sort?.sortByYearOfManufacture ? `&SortByYearOfManufacture=${sort?.sortByYearOfManufacture}` : ''}`);
};

const getProduct = (id: string) => AppHttpClient.get(`/api/services/app/Product/Get?Id=${id}`);
const updateProduct = (data: ProductItem) => AppHttpClient.put('/api/services/app/Product/Update', data);
const deleteProduct = (id: string | number) => AppHttpClient.delete(`/api/services/app/Product/Delete?Id=${id}`);
const createContact = (data: ContactPayloadValue) => AppHttpClient.post(`/api/services/app/ContactService/CreateContact`, data);
const editContact = (data: ContactPayloadValue) => AppHttpClient.put(`/api/services/app/ContactService/UpdateContact`, data);
const getSaleHistory = (skipCount: number, maxResultCount: number, tapType: number) => AppHttpClient.get(`/api/services/app/OrderService/GetSellHistory?SkipCount=${skipCount}&MaxResultCount=${maxResultCount}&TapType=${tapType}`);
const getPurchaseHistory = (skipCount: number, maxResultCount: number, tapType: number) => AppHttpClient.get(`/api/services/app/OrderService/GetPurchaseHistory?SkipCount=${skipCount}&MaxResultCount=${maxResultCount}&TapType=${tapType}`);
const requestBuyProduct = (id: string) => AppHttpClient.post(`/api/services/app/OrderService/RequestToBuy`, { id });
const getOrderStatus = (id: string | number) => AppHttpClient.get(`/api/services/app/OrderService/GetOrderStatus?Id=${id}`);

const findAllChild = (listCategory: Category[], category: Category) => {
    const listChild = listCategory.filter(f => f.parentId === category.id);
    listChild.forEach(f => { f.lv = (category.lv || 1) + 1; });
    category.children = listChild;
    category.childrenIds = listChild.map(x => x.id);
    if (get(listChild, 'length')) {
        listChild.forEach(child => {
            findAllChild(listCategory, child);
        });
    }
};

const convertCategoryToTree = (listCategory: Category[]) => {
    const parent = listCategory.filter(f => !f.parentId);

    parent.forEach(f => {
        f.lv = 1;
        findAllChild(listCategory, f);
    });
    return parent;
};

const convertCategoryWithChildren = (listCategory: Category[]) => {
    const cloneCategory = cloneDeep(listCategory);

    cloneCategory.forEach(parent => {
        parent.childrenIds = listCategory.filter(child => child.parentId === parent.id).map(x => x.id);
    });
    return cloneCategory;
};

function checkIsHasChild(listCategory: Category[], id: number) {
    const child = listCategory.filter(f => f.parentId === id);
    return !!((child && child.length)) || (id === 4);
}

const getAllChildCategory = (listCategory: Category[]) => {
    const cloneCategory = cloneDeep(listCategory);
    return cloneCategory.filter(f => !checkIsHasChild(listCategory, f.id));
};

const getCategoryDisplayOnDashboard = (listCategory: Category[]) => {
    const cloneCategory = cloneDeep(listCategory);
    const listDashboard = cloneCategory.filter(f => f.isDisplayOnDashboard);
    // return orderBy(listDashboard, ['displayOrderOnDashboard'], ['asc']);
    return orderBy(listDashboard, ['displayOrderOnDashboard']);
};

const getParentSecondCategory = (listCategory: Category[], category: Category) => {
    let parent = category;

    while (parent && parent.parentId !== 2 && parent.parentId !== 4 && parent.parentId !== parent.id) {
        // eslint-disable-next-line prefer-destructuring, @typescript-eslint/no-loop-func
        parent = listCategory.filter(f => f.id === parent.parentId)[0];

    }
    return parent;
};

const getListChildren = (category: Category, listCategory: Category[]) => {
    const group: Category[] = [];
    // const allChild = getAllChildCategory(listCategory);
    const allChild = cloneDeep(listCategory.filter(f => f.parentId));
    allChild.forEach(f => {
        const parent = getParentSecondCategory(listCategory, f);
        if (parent && parent.id === category.id) {
            group.push(f);
        }
    });
    return group;
};

const getSecondCategory = (listCategory: Category[]) => {

    const cloneCategory = cloneDeep(listCategory);
    const second = cloneCategory.filter(f => f.parentId === 2 || f.parentId === 4);
    second.forEach(category => {
        category.childrenEnd = getListChildren(category, listCategory);
    });
    return second;
};

const getSecondCategoryFromLastCategory = (listCategory: Category[], listSelected: Category[]) => {
    const cloneCategory = [...listCategory];
    const cloneListSelected = [...listSelected];
    const listSecond: Category[] = [];
    cloneListSelected.forEach(f => {
        const parent = getParentSecondCategory(cloneCategory, f);
        let parentInList = listSecond.filter(f => f.id === parent.id)[0];
        if (parentInList) {
            parentInList.childrenEnd?.push(f);
        } else {
            parentInList = { ...parent, childrenEnd: [f] };
            listSecond.push(parentInList);
        }
    });

    return listSecond;
};
const getCategoryIdFromLastCategory = (listCategory: Category[], listSelected: Category[]) => {
    const cloneCategory = [...listCategory];
    const cloneListSelected = [...listSelected];
    const listSecond: number[] = [];
    cloneListSelected.forEach(f => {
        const parent = getParentSecondCategory(cloneCategory, f);
        listSecond.push(parent?.id);
    });

    return uniq(listSecond);
};



const uploadImages = (images: any) => {
    const form = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const file of images) {
        form.append('Files', file);
        form.append('Types', file.type || 'image/heic');
    }

    // let baseUrl;
    // if (!(window as any).ReactNativeWebView) {
    //     baseUrl = "http://haraconvert-api.azurewebsites.net/";
    // }

    return AppHttpClient.postFormData('/api/services/app/ProductMediaService/UploadMediaForNewProduct', form);
};

function getParent(listAllCategory: Category[], categoryId: number) {
    return listAllCategory.filter(f => f.id === categoryId)[0];
}

const getGroupCategoryString = (listAllCategory: Category[], categoryId?: number) => {
    let result = '';
    const category = listAllCategory.filter(f => f.id === categoryId)[0];
    if (!category) {
        return '';
    }
    result = category.name;
    let currentCategory = category;
    let { parentId } = category;
    while (parentId !== null) {
        currentCategory = getParent(listAllCategory, parentId);
        parentId = currentCategory.parentId;
        if (currentCategory.parentId != null) {
            result = `${currentCategory.name} > ${result}`;
        }
    }
    return result;
};

const getCategoryName = (id: number) => {
    // eslint-disable-next-line prefer-destructuring
    const listCategory = AppStore.getState().ProductReducer.listCategory;
    return listCategory.filter(category => category.id === id)[0]?.name;
};

const getCategoryNameFromBE = (name: string) => {
    if (!name) {
        return '';
    }
    const index = name.indexOf(">");
    return name.slice(index + 1, name.length);
};

const convertToListId = (categories: Category[]) => {
    let ids: number[] = [];
    categories.forEach(category => {
        ids.push(category.id);
        if (get(category, 'children.length')) {
            ids = ids.concat(convertToListId(category.children || []));
        }
    });
    return ids;
};

const createProduct = (body: ProductBody) => AppHttpClient.post('/api/services/app/Product/Create', body);

const updateStatusTransfer = (body: { id: number, token: string }) => AppHttpClient.put(`/api/services/app/OrderService/UpdateInspectStatus`, body);

const updateSettingDeliveryTime = (expectedArrivalDate: string, scheduledDeliveryDate: string, id: number) => AppHttpClient.put(`/api/services/app/OrderService/UpdateSettingDeliveryTime`, { id, expectedArrivalDate, scheduledDeliveryDate });

const updateStatusProductProduct = (body: { status: ProductPublicStatus, productId: number, userToken: string }) => AppHttpClient.put('/api/services/app/Product/UpdateStatus', body);

const getProductById = (id: number) => AppHttpClient.get(`/api/services/app/Product/Get?Id=${id}`);

const confirmBuyProduct = (body: { orderId: number, token: string, isAccept?: boolean, zipCode: string, shippingAddress: string }) => AppHttpClient.post(`/api/services/app/OrderService/ConfirmBuyProduct`, body);

const confirmContact = (body: { id: number, timeConfirmed: number, timeConfirmedValues: string[], noteConfirmed: string, token: string, orderToken: string }) => AppHttpClient.post(`/api/services/app/ContactService/ConfirmContact`, body);

const getContactDetail = (id?: number) => AppHttpClient.get(`/api/services/app/ContactService/GetContactDetail?contactId=${id}`);

const updateLike = (body: { id: number, favorite: boolean }) => AppHttpClient.post(`/api/services/app/ProductFavoriteService/AddRemoveFavorite`, body);

const cancelTransactionOrder = (_id: number) => AppHttpClient.post(`/api/services/app/OrderService/CancelOrder`, { id: _id });

const cancelTransactionOrder2 = (body: { id: number, cancelReasonId: number, cancelReason: string, token: string }) => AppHttpClient.post(`/api/services/app/OrderService/CancelOrder`, body);

const getProductHistoryDetail = (id: number) => AppHttpClient.get(`/api/services/app/OrderService/GetOrderDetail?id=${id}`);

const updateStatusProductHistoryDetail = (body: { id: number, token: string }) => AppHttpClient.put(`/api/services/app/OrderService/UpdateNextStatus`, body);

const resendPaymentInfomation = (_id: number) => AppHttpClient.post(`/api/services/app/OrderService/ResendPaymentInformation`, { id: _id });

const getListFavorite = (skipCount: number, maxResultCount: number) => AppHttpClient.get(`/api/services/app/ProductFavoriteService/GetFavoriteList?SkipCount=${skipCount}&MaxResultCount=${maxResultCount}`);

const getCategoryByid = (id: number) => AppHttpClient.get(`/api/services/app/CategoryService/GetCategoryName?id=${id}`);

const getNoticeTransactionDashboard = () => AppHttpClient.get('api/services/app/OrderService/GetUserDashboard');

const getAllCategoryHasValue = () => AppHttpClient.get('api/services/app/CategoryService/GetAllProductCategory');

const unPublishProduct = (productId: string) => AppHttpClient.post('/api/services/app/Product/UnPublish', { id: productId });

const checkAnyJoinCall = (contactId: string) => AppHttpClient.post('/api/services/app/VideoCall/CheckAnyJoinCall', { contactId: contactId });

const checkRemainTimeCall = (contactId: string) => AppHttpClient.get(`/api/services/app/VideoCall/GetVideoCallStatus?ContactId=${contactId}`);

const getAllCategoryOnDashboard = () => AppHttpClient.get('/api/services/app/CategoryService/GetAllCategoryOnDashboard');

const ProductService = {
    getListCategory, getListProductDashboard,
    getListProductByFilter, convertCategoryToTree,
    uploadImages, getGroupCategoryString, createProduct,
    getProductById, updateStatusProductProduct, getProduct,
    updateProduct, deleteProduct, getAllChildCategory,
    confirmBuyProduct, createContact, editContact, getSaleHistory,
    getPurchaseHistory, confirmContact, getContactDetail,
    getCategoryName, updateLike, cancelTransactionOrder,
    cancelTransactionOrder2,
    getProductHistoryDetail, updateStatusProductHistoryDetail,
    resendPaymentInfomation, convertToListId,
    updateStatusTransfer, getCategoryNameFromBE, getSecondCategory,
    getSecondCategoryFromLastCategory, getCategoryIdFromLastCategory,
    getListProductByFilterV2, getListFavorite,
    getCategoryByid, getCalculateProductFees, getNoticeTransactionDashboard,
    convertCategoryWithChildren, getCategoryDisplayOnDashboard, getListChildren,
    requestBuyProduct, getAllCategoryHasValue, unPublishProduct, updateSettingDeliveryTime, getOrderStatus, checkAnyJoinCall, checkRemainTimeCall, getAllCategoryOnDashboard
};

export default ProductService;
