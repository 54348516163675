import React, { FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Mobile } from 'src/layouts/break-point';
import { ReactComponent as ArrowLeft } from "../Icons/arrow-left.svg";
import "./index.scss";

interface Props {
  title?: string;
  route?: string;
  onClick?: () => void;
}

const AppNavigation: FC<Props> = memo(({ ...props }) => {
  const { title, route, onClick } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    if (route) {
      navigate(route as string);
    }
    onClick?.();
  };

  return (
    <Mobile>
      <div className="ui-navigation">
        {
          (route || onClick) && (
            <div className="ui-navigation__icon" onClick={handleClick}>
              <ArrowLeft />
            </div>
          )
        }
        <div className="ui-navigation__title">{ title }</div>
      </div>
    </Mobile>
  );
});

export default AppNavigation;

AppNavigation.defaultProps = {};
