import React, { FC } from "react";
import classnames from "classnames";
import { Tag } from "antd";
import { NOTICE_TYPE } from "../../model";

interface Props {
  config: { label: string; value: NOTICE_TYPE, unreadQuantity: number }[];
  currentValue: NOTICE_TYPE;
  onChange: (value: NOTICE_TYPE) => void;
}

const Tab: FC<Props> = ({ ...props }) => {
  const { config, currentValue, onChange } = props;

  return (
    <div className="ui-tab">
      {config &&
        config.length &&
        config.map(({ label, value, unreadQuantity }, index) => (
          <div
            onClick={() => onChange(value)}
            key={index}
            className={classnames(
              "ui-tab__pane",
              value === currentValue && "active"
            )}
          >
            {label}
            {
              !!unreadQuantity && (
                <Tag
                  style={{ marginLeft: 10, height: 20 }}
                  color={value === currentValue ? "#FF671E" : "#A3A2A2"}
                >
                  {unreadQuantity > 99 ? "99+" : unreadQuantity}
                </Tag>
              )
            }
          </div>
        ))}
    </div>
  );
};

export default Tab;

Tab.defaultProps = {};
