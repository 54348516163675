import { useRef, useEffect } from 'react';

const useOnMessage = (fn: any) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onMessage = (...args: any) => cb.current?.(...args);

    window.addEventListener("message", onMessage);
    document.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
      document.removeEventListener("message", onMessage);
    };
  }, []);
};

export default useOnMessage;
