import React, { FC, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { verifyToken } from "@service/API/SignUp";

import { ReactComponent as Logo } from "@components/Icons/logo.svg";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { ReactComponent as AppStore } from "@components/Icons/app-store.svg";
import { ReactComponent as GooglePlay } from "@components/Icons/google-play.svg";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/configureStore";
import { setAppLoading, setTitle } from "@store/State";
import ExpiredToken from "@pages/sign-up/components/Expired";
import AppButton from "src/components/AppButton";
import { buildLink, getOS, handleOpenApp, paramsDisableModal } from "src/helper";
import AppModal from "@components/AppModal";
import { SignUpStatusName } from "../../model";
import StorageService from "../../../../services/Storage";

const SignUpComplete: FC = ({ ...props }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expiredToken, setExpiredToken] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const token = searchParams.get("token");
  const disableModal = searchParams.get("disableModal");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.AppStateReducer.loading
  );
  const systemConfig = useSelector(
    (state: RootState) => state.GlobalReducer.systemConfig
  );

  const [urlAppleStore, setAppleStore] = useState<any>();
  const [urlGooglePlay, setUrlGooglePlay] = useState<any>();
  const [showApp, setShowApp] = useState<boolean>(false);

  useEffect(() => {
    setAppleStore(systemConfig.find((item) => item.key === "AppleStore"));
    setUrlGooglePlay(systemConfig.find((item) => item.key === "GooglePlay"));
  }, [systemConfig]);

  const openApp = (params = "success") => {
    if (!disableModal) {
      handleOpenApp(
        `haraseikouapp://home/signUpWithToken${params}`,
        setShowApp
      );
      navigate(paramsDisableModal(), { replace: true });
    }
  };

  useEffect(() => {
    if (token !== "success") {
      dispatch(setAppLoading(true));
      (async () => {
        if (token) {
          try {
            const res = await verifyToken(token);
            if (!res.data.result) {
              setIsVerified(!res.data.result);
            }
            openApp();
          } catch (e) {
            // console.log(e);
            openApp(token);
            setExpiredToken(true);
          } finally {
            dispatch(setAppLoading(false));
          }
        }
      })();
    }
  }, []);

  useEffect(() => {
    dispatch(setTitle(SignUpStatusName.SIGNUP_SENT_EMAIL));
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  return !loading ? (
    !expiredToken && !isVerified ? (
      <>
        <AppModal
          visible={showApp}
          title="このページを“マシプラ”で開きますか？"
          cancelText="キャンセル"
          onCancel={() => setShowApp(false)}
          onOk={() => {
            setShowApp(false);
            buildLink("haraseikouapp://home/signUpWithTokensuccess");
          }}
          okText={
            getOS() === "Android" ? (
              <a href="haraseikouapp://home/signUpWithTokensuccess">
                <span style={{ color: "#fff" }}>あける</span>
              </a>
            ) : (
              <span style={{ color: "#fff" }}>あける</span>
            )
          }
        />
        <div className="ui-sign-up-complete">
          <Row gutter={[32, 32]} justify="center">
            <Col xs={24} md={12} xl={8}>
              <div className="ui-sign-up-complete__logo">
                <Logo />
              </div>
              <div className="ui-sign-up-complete__logo-title">
                {t("signup.complete.logoTitle")}
              </div>
              <div className="ui-sign-up-complete__icon">
                <Success />
              </div>
              <div>
                <div className="ui-sign-up-complete__title">
                  {t("signup.complete.title")}
                </div>
                <div className="ui-sign-up-complete__description">
                  {t("signup.complete.description.text1")}
                </div>
              </div>
              <div className="ui-sign-up-complete__action">
                <AppButton
                  buttontype="primary"
                  onClick={() => navigate("/login")}
                >
                  {t("signup.success.signin")}
                </AppButton>
              </div>
              <div className="ui-sign-up-complete__introduce">
                {t("signup.complete.introduce.text1")} <br />
                {t("signup.complete.introduce.text2")}
                <br />
                {t("signup.complete.introduce.text3")}
              </div>
              <div className="ui-sign-up-complete__download">
                <div className="ui-sign-up-complete__app-store">
                  <a
                    href={urlAppleStore?.value}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppStore />
                  </a>
                </div>
                <div className="ui-sign-up-complete__google-play">
                  <a
                    href={urlGooglePlay?.value}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GooglePlay />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    ) : (
      <>
        <AppModal
          visible={showApp}
          title="このページを“マシプラ”で開きますか？"
          cancelText="キャンセル"
          onCancel={() => setShowApp(false)}
          onOk={() => {
            setShowApp(false);
            buildLink(`haraseikouapp://home/signUpWithToken${token}`);
          }}
          okText={
            getOS() === "Android" ? (
              <a href={`haraseikouapp://home/signUpWithToken${token}`}>
                <span style={{ color: "#fff" }}>あける</span>
              </a>
            ) : (
              <span style={{ color: "#fff" }}>あける</span>
            )
          }
        />
        <ExpiredToken isVerified={isVerified} />
      </>
    )
  ) : null;
};

export default SignUpComplete;

SignUpComplete.defaultProps = {};
