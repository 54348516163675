import "./styles.scss";

import { Radio } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { cloneDeep, get } from "lodash";
import { ReactComponent as ArrowRight } from "@components/Icons/arrow-right.svg";
import { ReactComponent as ArrowDown } from "@components/Icons/arrow-down-2.svg";
import { Category } from "@models/product.model";

interface TreeProps {
  options: Category[];
  value: number;
  onChange: (value: any) => void;
}

interface TreeChildProp {
  treeNote: Category;
  isExpand?: boolean;
}
interface TreeChildState {
  value: boolean;
  isExpand?: boolean;
}

class TreeChildRadio extends React.Component<TreeChildProp, TreeChildState> {
  constructor(props: TreeChildProp) {
    super(props);
    this.state = {
      value: this.props.treeNote.value,
      isExpand: this.props.isExpand || false,
    };
  }

  componentDidUpdate(props: TreeChildProp) {
    if (this.state.value !== props.treeNote.value) {
      this.setState({ value: props.treeNote.value });
    }
  }

  render() {
    return (
      <div className="tree-container">
        <div
          className={classNames(
            {
              "tree-parent": get(this.props.treeNote, "children.length") > 0,
            },
            {
              "tree-normal": get(this.props.treeNote, "children.length") <= 0,
            },
            {
              "tree-last": this.props.treeNote.lv === 4,
            }
          )}
        >
          {get(this.props.treeNote, "children.length") > 0 && (
            <div
              className="tree-icon"
              onClick={() => {
                this.setState((prevState) => ({
                  isExpand: !prevState.isExpand,
                }));
              }}
            >
              {!this.state.isExpand ? <ArrowRight /> : <ArrowDown />}
            </div>
          )}

          {/* {get(this.props.treeNote, "children.length") <= 0 ? ( */}
            <Radio key={this.props.treeNote.id} value={this.props.treeNote.id}>
              {this.props.treeNote.name}
            </Radio>
          {/* ) : ( */}
            {/* <div
              className="text-body-4"
              onClick={() => {
                this.setState((prevState) => ({
                  isExpand: !prevState.isExpand,
                }));
              }}
            >
              {this.props.treeNote.name}
            </div> */}
          {/* )} */}
        </div>

        {get(this.props.treeNote, "children.length") > 0 &&
          this.state.isExpand && (
            <div className="tree-child">
              {this.props.treeNote.children?.map((treeChild: Category) => (
                <TreeChildRadio
                  // isExpand={this.state.isExpand}
                  treeNote={treeChild}
                  key={treeChild.id}
                ></TreeChildRadio>
              ))}
            </div>
          )}
      </div>
    );
  }
}

const AppTreeRadio: FC<TreeProps> = ({ ...props }) => {
  const [listOption, setListOption] = useState(props.options);
  const [value, setValue] = useState<number>();
  useEffect(() => {
    if (props.options.length >= 0) {
      setListOption(cloneDeep(props.options));
    }
  }, [props.options]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Radio.Group
      className="app-category-group"
      onChange={(event) => {
        props.onChange(event);
        setValue(event.target.value);
      }}
      value={value}
    >
      {listOption.map((treeNote: Category, index) => (
        <TreeChildRadio
          // isExpand={index === 0}
          treeNote={treeNote}
          key={treeNote.id}
        ></TreeChildRadio>
      ))}
    </Radio.Group>
  );
};

export default AppTreeRadio;
