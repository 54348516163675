/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "antd";
import moment from "moment";
import { DATE_FORMAT } from "src/configs";
import AppVideoPlayer from "src/components/AppVideoPlayer";

import NumberFormat from "react-number-format";
import AppDatePicker1 from "@components/AppDatePicker";
import AppInputNumber from "@components/Form/AppInputNumber";
import AppTextField from "../../components/Form/AppTextField";
import AppPassword from "../../components/Form/AppPassword";
import AppTextArea from "../../components/Form/AppTextArea";
import AppSelect from "../../components/Form/AppSelect";
import AppButton from "../../components/AppButton";
import AppDatePicker from "../../components/Form/AppDatePicker";
import AppTimePicker from "../../components/Form/AppTimePicker";
import AppRow from "../../components/AppRow";
import AppFieldset from "../../components/AppFieldset";
import AppModal from "../../components/AppModal";
import AppSlider from "../../components/Form/AppSlider";
import AppCheckbox from "../../components/Form/AppCheckbox";
import AppRadio from "../../components/Form/AppRadio";
import AppSteps from "../../components/AppSteps";
import AppTag from "../../components/AppTag";

const Demo: FC = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [checklist, setChecklist] = useState(["A"]);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const showModal = () => setVisible(true);

  const onCancel = () => setVisible(false);
  const onOk = () => setVisible(false);

  const onFinish = (values: any) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    console.log(hasErrors);
    console.log(values);
    console.log(values.name.format(DATE_FORMAT));
  };

  const mock = [
    { label: "Email", value: "haraseiko@gmail.com", valueColor: "#1762D7" },
    { label: "Name", value: "Nakata", valueColor: "" },
    { label: "Date of birth", value: "22/02/1992", valueColor: "" },
    { label: "Company name", value: "Sale department", valueColor: "" },
  ];

  const controls = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    {
      label: (
        <>
          <div style={{ fontWeight: "bold" }}>Pickup Products</div>
          <div>Picked up the products were registered to the TOP screen.</div>
        </>
      ),
      value: "C",
    },
  ];

  const radios = [
    { label: "Buyer", value: 0 },
    { label: "Seller", value: 1 },
  ];

  const steps = [
    { label: "コンタクト選択", order: 1 },
    { label: "候補日", order: 2 },
    { label: "メッセージ", order: 3 },
    { label: "メッセージ", order: 4 },
    { label: "メッセージ", order: 5 },
    { label: "完了", order: 6 },
  ];

  const navigate = useNavigate();

  const onStepChange = (step: number) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    form.setFieldsValue({ name: moment("2021/02/02", "YYYY/MM/DD") });
  }, []);

  return (
    <div>
      <video width="750" height="500" controls src="https://haraseikoustorageaccount.blob.core.windows.net/upload-videos/20220727T091509016805.mp4">
        {/* <source src="https://haraseikoustorageaccount.blob.core.windows.net/upload-videos/20220727T091509016805.mp4" type="video/mp4" /> */}
      </video>

      <div>
        <AppVideoPlayer width="200px" height="200px" url="https://haraseikoustorageaccount.blob.core.windows.net/upload-videos/20220727T091509016805.mp4"/>
      </div>

      <video src="https://haraseikoustorageaccount.blob.core.windows.net/upload-videos/20220727T091509016805.mp4" preload="auto" controls  width="200px" height="200px"></video>
    </div>
  );
};

export default Demo;
