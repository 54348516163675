import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import moment from "moment";
import "./index.scss";
import { useTranslation } from "react-i18next";
import AppButton from "@components/AppButton";
import { useSelector } from 'react-redux';
import { RootState } from '@store/configureStore';
import DateTimePicker from '@pages/contact/components/DateTimePicker';
import { Desktop, Mobile } from 'src/layouts/break-point';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "../../../../configs";
import { ContactMethodEnum, ContactStatus, ContactValue } from "../../model";

interface Props {
  setStatus?: (status: ContactStatus) => void;
  setValue?: (value: ContactValue) => void;
  value?: ContactValue;
  setVisibleWarningModal?: () => void;
  timeClickBuy: string;
}

const ContactTime: FC<Props> = memo(({ ...props }) => {
  const [ form ] = Form.useForm();
  const { setStatus, setValue, value, setVisibleWarningModal, timeClickBuy } = props;
  const { t } = useTranslation();
  const [ isDuplicated, setDuplicated ] = useState<boolean>(false);
  const { productDetail } = useSelector((state: RootState) => state.ProductReducer);

  const isDuplicatedTime = () => {
    const candidateDates: { datetime: moment.Moment }[] = form.getFieldValue("candidateDates").filter((candidateDate: { datetime: moment.Moment }) => Object.values(candidateDate).every(item => item));
    const transformedCandidateDates = candidateDates.map(candidateDate => candidateDate.datetime.toISOString());
    const uniqueCandidateDates = Array.from(new Set([ ...transformedCandidateDates ]));
    return uniqueCandidateDates.length !== transformedCandidateDates.length;
  };

  const onFinish = (values: any) => {
    setDuplicated(false);
    if (isDuplicatedTime()) {
      setDuplicated(true);
      return;
    }

    const value = form.getFieldsValue();
    setStatus?.(ContactStatus.LEAVE_MESSAGE);
    setValue?.(value);
  };

  const onValueChanges = () => {
    const value = form.getFieldsValue();
    setValue?.(value);
  };

  const validateDate = ({ field }: any, value: any) => {
    const index = field.split(".")[1];
    const datetimeValue = form.getFieldValue("candidateDates")[index].datetime;

    if (datetimeValue === undefined && index !== 0) {
      return Promise.reject(t("contact.step2.calendar.validate1"));
    }

    if (datetimeValue && datetimeValue.isBefore(moment().format(DATE_TIME_FORMAT))) {
      return Promise.reject(t("contact.step2.calendar.validate2"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (value?.candidateDates && value?.candidateDates.length) {
      form.setFieldsValue(value);
    }
  }, [value]);

  return (
    <div className="ui-contact-time">
      <Row gutter={ [ 32, 32 ] } justify="center">
        <Col xs={ 24 } md={ 14 }>
          <div className="ui-contact-time__note">
            { t(`contact.step2.${ value?.method === ContactMethodEnum.ONLINE ? 'online' : 'offline' }.note1`) }
            <br/>
            { t(`contact.step2.${ value?.method === ContactMethodEnum.ONLINE ? 'online' : 'offline' }.note2`) }
            <br/>
            { t(`contact.step2.${ value?.method === ContactMethodEnum.ONLINE ? 'online' : 'offline' }.note3`) }
          </div>

          <div className="ui-contact-time__seller-information">
            <div className="ui-contact-time__seller-title">{ t("contact.step2.sellerTitle") }</div>
            <div>
            { productDetail?.sellerCompany && 
              <div
                className="ui-contact-time__seller-company">{ productDetail?.sellerCompany }</div>
            }
              <div
                className="ui-contact-time__seller-name">{ `${ productDetail?.sellerName }${ t("contact.step2.sellerName") }` }</div>
            </div>
          </div>

          <Form
            form={ form }
            onFinish={ onFinish }
            onValuesChange={ onValueChanges }
            layout="vertical"
            initialValues={ {
              candidateDates: [
                { datetime: "" },
                { datetime: "" },
                { datetime: "" }
              ]
            } }
          >
            <Form.List name="candidateDates">
              {
                (fields) => (
                  fields.map((field, index) => (
                      <Row key={ index }>
                        <Col xs={ 24 } md={ 14 }>
                          <DateTimePicker
                            form={ form }
                            values={ form.getFieldValue("candidateDates")?.[index].datetime }
                            name={ [ field.name, "datetime" ] }
                            label={ t("contact.step2.datetimeLabel", { number: index + 1 }) }
                            rules={ [
                              { required: index === 0, message: t("contact.step2.calendar.required") },
                              { validator: validateDate }
                            ] }
                            checkDuplicate={() => {
                              setDuplicated(false);
                              if (isDuplicatedTime()) {
                                setDuplicated(true);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    )
                  )
                )
              }
            </Form.List>

            {
              isDuplicated && (
                <div className="ui-contact-time__warning">
                  {
                    t("contact.step2.duplicated")
                  }
                </div>
              )
            }


            <div className="ui-contact-time__calculate-date">
              <div className="ui-contact-time__purchase-request-date">
                { `${ t("contact.step2.purchaseRequestDatetime") }：${ timeClickBuy }` }
              </div>
              <div className="ui-contact-time__scheduled-date">
                { `${ t("contact.step2.scheduleCandidateDeadline") }：${ moment().add(7, "day").set({
                  hour: 20,
                  minute: 0
                }).format(DATE_TIME_FORMAT) }` }
              </div>
            </div>

            <Mobile>
              <div className="ui-contact-method__action">
                <Row gutter={[32, 32]} justify="center">
                  <Col xs={12} md={6} xl={4}>
                    <AppButton buttontype="secondary" onClick={setVisibleWarningModal}>{ t("contact.action.close") }</AppButton>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <AppButton buttontype="primary" htmlType="submit">{ t("contact.action.next") }</AppButton>
                  </Col>
                </Row>
              </div>
            </Mobile>

            <Desktop>
              <Row gutter={[32, 32]} justify="center">
                <Col xs={6}>
                  <AppButton buttontype="secondary" onClick={setVisibleWarningModal}>{ t("contact.action.close") }</AppButton>
                </Col>
                <Col xs={6}>
                  <AppButton buttontype="primary" htmlType="submit">{ t("contact.action.next") }</AppButton>
                </Col>
              </Row>
            </Desktop>
          </Form>
        </Col>
      </Row>
    </div>
  );
});

export default ContactTime;

ContactTime.defaultProps = {};
