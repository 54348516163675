import React from "react";
import { Outlet } from "react-router-dom";
import { Desktop } from "src/layouts/break-point";
import MyPageMenu from "src/pages/my-page/components/Menu";
import TitleMenu from "src/pages/my-page/components/TitleMenu";
import { Mobile } from "../break-point";
import "./styles.scss";

export default function MyPageLayout() {
  return (
    <>
      <Mobile>
        <div style={{ height: "100%" }}>
          <Outlet></Outlet>
        </div>
      </Mobile>
      <Desktop>
        <div style={{ height: "100%" }} className="my-page-layout">
          <div className="left-view">
            <MyPageMenu />
          </div>
          <div className="right-view">
            <TitleMenu />
            <Outlet></Outlet>
          </div>
        </div>
      </Desktop>
    </>
  );
}
