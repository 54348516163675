import { Skeleton } from "antd";
import "./styles.scss";
import React from "react";
import classNames from "classnames";

const AppSkeletonSlider = ({ ...props }) => {
  const { className, style } = props;
  return (
    <div style={style} className={classNames("slider-skeleton-container", className)}>
      <Skeleton.Avatar
        active
        shape="square"
        className="slider-image-skeleton"
      />
      {/* <Skeleton  active paragraph={{ rows: 1 }} /> */}
    </div>
  );
};

export default AppSkeletonSlider;
