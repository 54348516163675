import React, { FC } from "react";
import "./index.scss";
import classnames from "classnames";
import { Tag } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT, PATH_NAME, TIME_FORMAT } from "src/configs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import { NoticeItems, NOTICE_SYSTEM_TYPE } from "../../model";

interface noticeProps {
  type?: string;
  item?: NoticeItems;
  onClick?: () => void;
  onReload: () => void;
}

const NoticeBox: FC<noticeProps> = ({ ...props }) => {
  const { type, item } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );
  const formatDate = () => {
    let newDateFormat = "";
    if (
      moment().format(DATE_FORMAT) !==
      moment(item?.sentDate).format(DATE_FORMAT)
    ) {
      newDateFormat = moment(item?.sentDate).format(DATE_FORMAT);
    }
    return newDateFormat;
  };

  const countTotalUnread: number = 100;

  return (
    <div
      className="ui-notice-box"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
        navigate(`system/${item?.notificationId}`);
      }}
    >
      <div
        className={classnames(
          "ui-notice-box__container",
          !item?.isSeen && "message-unseen",
          type === "personal" && "flex"
        )}
      >
        {!item?.isSeen && type === "system" && (
          <div className="badge-unseen"></div>
        )}
        {!item?.isSeen && type === "personal" && (
          <Tag className="count-notice-unseen">
            {countTotalUnread > 99 ? "99+" : countTotalUnread}
          </Tag>
        )}
        {type === "personal" && (
          <div className="app-image-container">
            <img alt="" src={item?.notification?.image?.concat(sasCode as string)}></img>
          </div>
        )}

        <div className="content-wrapper">
          <div className="notification-title color-title bold">
            {item?.notification?.title}
          </div>
          {item?.notification?.notificationType ===
            NOTICE_SYSTEM_TYPE.TRADE && (
            <div className="notification-content text-body-4">
              <div className="three-dot">{item?.notification?.content}</div>
            </div>
          )}
          {item?.notification?.notificationType ===
            NOTICE_SYSTEM_TYPE.ADMIN && (
            <div className="notification-content text-body-4">
              <div className="three-dot">{t("notification.content.admin")}</div>
            </div>
          )}
          <div className="notification-date text-body-1">
            <span className="notification-date__day">{formatDate()}</span>
            <span className="notification-date__hour">
              {moment(item?.sentDate).format(TIME_FORMAT)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeBox;
