import React, { FC } from "react";
import StorageService from "@service/Storage";
import { PATH_NAME } from "src/configs";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";

const AuthGuard: FC = ({ children }: any) => {
  const isAuth = StorageService.getUserToken();
  const location = useLocation();

  const isQuitMember = useSelector(
    (state: RootState) => state.GlobalReducer.isQuitMembership
  );

  if (!isAuth) {
    const url = encodeURIComponent(`${location.pathname}${location.search}`);
    return !isQuitMember ? (
      <Navigate to={`${PATH_NAME.LOGIN}?returnUrl=${url}`} />
    ) : (
      <Navigate to={`${PATH_NAME.DASHBOARD}`} />
    );
  }

  return <> {children} </>;
};
export default AuthGuard;
