export enum Collection {
  ROOMS = 'rooms',
  MESSAGES = 'messages',
  USERS = 'users'
}

interface Room {
  roomId: string;
  productId: number;
  productCreatedBy: number;
  roomImage: string;
  roomTitle: string;
  members: Array<{ id: number, name: string, companyName: string }>;
  memberIds: Array<number>;
  onlineMembers: Array<number>;
  createdAt: number;
  createdBy: number;
  unreadMessage: object;
  totalMessage: number;
  lastUpdatedAt: number;
}

interface Message {
  messageId: number;
  token: string | null;
  roomId: string;
  createdAt: number;
  senderId: number;
  receiverId: number;
  content: string;
  isMyProduct: boolean;
  productTitle: string;
  shouldSendNotice: boolean;
  file: {
    medias: Array<{ type: string, id: string, url: string }>,
    documents: Array<{ type: string, id: string, url: string, name?: string }>
  }
}

interface File {
  documents: Array<{ id: string; type: string, url: string, name?: string }>,
  medias: Array<{ id: string; type: string, url: string }>
}

interface User {
  userId: number;
  tokens: Array<string>;
}

export type { Room, Message, File, User };
