// export interface ProductItem {
//     image: string;
//     name: string;
//     type: string;
//     year: string;
//     view: number;
//     like: number;
//     price: number;

import { t } from "i18next";
import { LabeledValue } from "antd/lib/select";

// }
export interface Category {
  displayOrder: number;
  id: number;
  name: string;
  parentId: number;
  value: boolean;
  children?: Category[];
  childrenIds?: number[];
  childrenEnd?: Category[];
  imageUrl?: string;
  description?: string;
  isDisplayOnDashboard?: boolean;
  displayOrderOnDashboard?: number;
  lv?: number;
}

export interface ProductMedia {
  id: number;
  creationTime: Date;
  creatorUserId: number;
  lastModificationTime: Date;
  lastModifierUserId: number;
  isDeleted: boolean;
  deleterUserId: number;
  deletionTime: Date;
  productId: number;
  name: string;
  type: number;
  url: string;
  displayOrder: number;
}

export interface ProductItem {
  id: number | string;
  canPublish: boolean;
  categoryId: number;
  userId: number;
  name: string;
  title: string;
  maker: string;
  yearOfManufacture: any;
  keyword: string;
  comment: string;
  patternNumber: string;
  zipCode: string;
  city: string;
  district: string;
  street: string;
  buildingName: string;
  price: number;
  carryingCost: number;
  isPickupProduct: boolean;
  status: number;
  endDate: any;
  estimationDate: any;
  isKobayashiProduct: boolean | [];
  isPostVideoToYoutube: boolean | [];
  categoryName: string;
  productMedias: ProductMedia[];
  viewCount: number;
  favoriteCount: number;
  canBookContact: boolean;
  canDelete: boolean;
  canRemoveFromFavorite: boolean;
  commissionAmount: number;
  commissionAmountOnTax: number;
  consumptionTaxAmount: number;
  isAllowOnlineTours: boolean | [];
  isNeedTakeBackQuote: boolean | [];
  orderStatus: number;
  sellerName: string;
  sellerCompany: string;
  totalAmount: number;
  settingDaysEndDate: number;
  publicDate: string;
  userToken: string;
  buyerId: number;
  transactionId: number;
}

export interface ProductBody {
  categoryId?: number;
  userId?: number;
  maker?: string;
  title?: string;
  keyword?: string;
  comment?: string;
  city?: string;
  district?: string;
  price?: number;
  endDate?: string;
  carryingCost?: number;
  isNeedTakeBackQuote?: boolean;
  estimationDate?: string;
  isPostVideoToYoutube?: boolean;
  yearOfManufacture?: number;
  isAllowOnlineTours?: boolean;
  medias?: string[];
  zipCode?: string;
  settingDaysEndDate?: number;
  street?: string;
}

export interface ProductHistory {
  categoryId: number;
  userId: number;
  name: string;
  title: string;
  maker: string;
  yearOfManufacture: number;
  keyword: string;
  comment: string;
  patternNumber?: any;
  zipCode?: any;
  city?: any;
  district?: any;
  street?: any;
  buildingName?: any;
  price: number;
  carryingCost: number;
  isNeedTakeBackQuote?: any;
  isPickupProduct: boolean;
  status: number | ProductTransactionStatus;
  endDate?: any;
  estimationDate?: any;
  isKobayashiProduct: boolean;
  isPostVideoToYoutube: boolean;
  isAllowOnlineTours?: any;
  categoryName?: any;
  creationTime: Date;
  lastModificationTime?: Date;
  viewCount?: any;
  favoriteCount?: any;
  canDelete?: any;
  canRemoveFromFavorite?: any;
  canBookContact?: any;
  totalCommissionAmount: number;
  totalConsumptionTaxAmount: number;
  totalCommissionAmountOnTax: number;
  totalAmount: number;
  productId: number;
  id: number;
  contactId: number;
  contactType?: ContactType;
  contactTime?: Date;
  buyerId: number;
  sellerId: number;
  productStatus: number;
  scheduledDeliveryDate?: string;
  expectedArrivalDate?: string;
  orderToken: string;
}

export interface OrderHistory {
  status: number;
  creationTime: Date;
}

export interface ProductHistoryDetail {
  sellerId: number;
  buyerId: number;
  productId: number;
  productTitle: string;
  productAmount: number;
  commissionAmount: number;
  consumptionTaxAmount: number;
  totalAmount: number;
  categoryName: string;
  location: string;
  contactTime: Date;
  purchaseRequestTime: Date;
  orderStatus: ProductTransactionStatus;
  orderHistories: OrderHistory[];
  id: number;
  contactId: number;
  contactType: ContactType;
  commissionAmountOnTax: number;
  scheduledDeliveryDate: string;
  expectedArrivalDate: string;
  canceler?: Canceler;
  purchaseConfirmationDate: Date;
  orderToken: string;
}

export enum Canceler {
  SELLER = "seller",
  BUYER = "buyer",
  ADMIN = "admin",
}
export enum ProductPublicStatus {
  Unpublish = 0,
  Published = 1,
  Closed = 2,
}

export enum ProductTransactionStatus {
  CANCEL = 110,
  IN_PURCHASE = 10,
  IN_REQUEST = 20,
  IN_CONTACT = 30,
  IN_CONFIRM = 40,
  IN_PAYMENT = 50,
  PAYMENT_COMPLETE = 60,
  IN_CHECKING = 70,
  CONFIRM_RECEIVED = 80,
  DONE = 100,
  UNPUBLISH = 200,
  PUBLISH = 201,
}

export enum ContactType {
  OnlineTour = 1,
  LocalPreview = 2,
  MessageOnly = 3,
}

export enum CategoryParent {
  Machine = 2,
  Tool = 4,
}

export const CANCEL_SELL_REASON = [
  {
    label: t("sale.history.transaction.warning.reason.1") as String,
    value: 1,
  },
  {
    label: t("sale.history.transaction.warning.reason.2") as String,
    value: 2,
  },
  {
    label: t("sale.history.transaction.warning.reason.3") as String,
    value: 3,
  },
  {
    label: t("sale.history.transaction.warning.reason.4") as String,
    value: 4,
  },
];

export const CANCEL_PURCHASE_REASON: LabeledValue[] = [
  {
    label: t("purchase.history.transaction.warning.reason.1") as String,
    value: 1,
  },
  {
    label: t("purchase.history.transaction.warning.reason.2") as String,
    value: 2,
  },
  {
    label: t("purchase.history.transaction.warning.reason.3") as String,
    value: 3,
  },
  {
    label: t("purchase.history.transaction.warning.reason.4") as String,
    value: 4,
  },
];
