import React, { FC, memo, useEffect } from "react";
import "./index.scss";
import { Col, Form, Row } from "antd";
import AppNavigation from "@components/AppNavigation";
import { useTranslation } from "react-i18next";
import { ReactComponent as Fail } from "@components/Icons/fail.svg";
import AppButton from "@components/AppButton";
import { resendEmail } from "@service/API/SignUp";
import AppTextField from "@components/Form/AppTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import { setAppLoading, setTitle } from "@store/State";
import { useNavigate } from "react-router-dom";
import { SignUpStatus, SignUpStatusName } from "../../model";

interface Props {
  isVerified?: boolean;
}

const ExpiredToken: FC<Props> = memo(({ ...props }) => {
  const { isVerified } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(
    (state: RootState) => state.AppStateReducer.loading
  );
  const navigate = useNavigate();

  const onFinish = async (values: { email: string }) => {
    dispatch(setAppLoading(true));
    try {
      await resendEmail(values.email);
      navigate("/login");
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    dispatch(setTitle(SignUpStatusName.SIGNUP_INPUT));
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  return !loading ? (
    <div className="ui-expired-token">
      <div className="ui-expired-token__pageTitle">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24}>
            <AppNavigation title={t("signup.fail.pageTitle")} />
          </Col>
        </Row>
      </div>
      <div className="ui-expired-token__content">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={12} xl={8}>
            <div className="ui-expired-token__icon">
              <Fail />
            </div>
            <div className="ui-expired-token__title">
              {t(
                !isVerified
                  ? "signup.fail.title"
                  : "signup.fail.title.re.confirm"
              )}
            </div>
            <div className="ui-expired-token__description">
              {t(
                !isVerified
                  ? "signup.fail.description"
                  : "signup.fail.description.re.confirm"
              )}
            </div>
            <Form
              form={form}
              layout="vertical"
              onFinish={!isVerified ? onFinish : () => navigate("/login")}
            >
              <div className={`${isVerified ? "isVerified" : ""}`}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppTextField
                      name="email"
                      label={t("signup.form.email")}
                      placeholder=""
                      formgroup={form}
                      rules={[
                        {
                          required: !isVerified,
                          message: t("signup.form.validate.email.required"),
                        },
                        {
                          type: "email",
                          message: t("signup.form.validate.email.format"),
                        },
                      ]}
                      maxLength={255}
                    />
                  </Col>
                </Row>
              </div>

              <div className="ui-expired-token__action">
                <Form.Item style={{ margin: 0 }}>
                  <Row gutter={[48, 48]} justify="center">
                    <Col xs={24} md={12} xl={24}>
                      <div className="ui-expired-token__action-button">
                        <AppButton buttontype="primary" htmlType="submit">
                          {t(
                            !isVerified
                              ? "signup.fail.retry"
                              : "signup.fail.login"
                          )}
                        </AppButton>
                      </div>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  ) : null;
});

export default ExpiredToken;
