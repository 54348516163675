import React, { FC, memo } from "react";
import "./index.scss";

interface Props {
  src: string;
  className?: string;
  onClick?: () => void;
}

const AppImageView: FC<Props> = memo(({ ...props }) => (
  <div className="app-image-container" onClick={props.onClick}>
    <img alt="" src={props.src} className={props.className} />
  </div>
));

export default AppImageView;

AppImageView.defaultProps = {};
    