import { ReactNode } from "react";

export interface NoticeItems {
  id?: number;
  receiverId?: number;
  senderId?: number;
  notificationId?: number;
  isSent?: boolean;
  sentDate?: string;
  isSeen?: boolean;
  seenDate?: string;
  notification?: NoticeItem;
}

export interface NoticeItem {
  id?: number;
  isSeen?: boolean;
  creationTime?: string;
  creatorUserId?: number;
  lastModificationTime?: string;
  lastModifierUserId?: number;
  isDeleted?: boolean;
  deleterUserId?: number;
  deletionTime?: string;
  notificationType?: number;
  title?: string;
  content?: string;
  url?: string;
  image?: string;
  intendDate?: string;
  isShowNotification?: boolean;
  isPushNotification?: boolean;
  isSent?: boolean;
  sentDate?: string;
  objectId: number;
}

export enum NOTICE_TYPE {
  SYSTEM,
  PERSONAL,
}

export enum NOTICE_SYSTEM_TYPE {
  TRADE = 1,
  ADMIN = 2,
}
