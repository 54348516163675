import React, { FC, useEffect, useState } from "react";
import { Col, Form, Popover, Row, Select } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppTag from "@components/AppTag";
import { ReactComponent as Action } from "@components/Icons/three-dots.svg";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { ReactComponent as Close } from "@components/Icons/close-small.svg";
import { ReactComponent as ButtonClose } from "@components/Icons/button-cancel.svg";
import {
  CANCEL_PURCHASE_REASON,
  ContactType,
  ProductHistory,
  ProductHistoryDetail,
  ProductTransactionStatus,
} from "src/models/product.model";
import moment from "moment";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import AppModal, { AppModalProps } from "src/components/AppModal";
import { Desktop, Mobile } from "src/layouts/break-point";
import AppModalCancelTransaction from "src/components/AppModalCancelTransaction";
import AppButton from "src/components/AppButton";
import { AppDispatch } from "src/store/configureStore";
import { setRoomId } from "src/store/Chat";
import { PATH_NAME } from "src/configs";
import { setAppLoading, setTitle } from "src/store/State";
import ProductService from "src/services/API/Product";
import { RowProps } from "src/components/AppRow";
import { ReactComponent as ConfirmArrivalIcon } from "@components/Icons/confirm-arrival-icon.svg";
import { ReactComponent as ResendInfoIcon } from "@components/Icons/resend-info-icon.svg";
import { ReactComponent as ConfirmPurchaseIcon } from "@components/Icons/confirm-purchase-icon.svg";
import { ReactComponent as CompletedContactIcon } from "@components/Icons/completed-contact-icon.svg";
import { ReactComponent as OnlineContactIcon } from "@components/Icons/online-contact-icon.svg";
import { ReactComponent as ReContactIcon } from "@components/Icons/re-contact-icon.svg";
import { ReactComponent as ContactIcon } from "@components/Icons/contact-icon.svg";
import { ReactComponent as Edit } from "@components/Icons/edit.svg";
import JoinContactOnline from "src/components/Icons/JoinContactOnline";
import classNames from "classnames";
import { PreCallInfo } from "src/pages/visit-online/Preparation/model";
import { getIdError } from "src/helper";
import { injectHandleOk } from "src/App";

interface Props {
  item: ProductHistory;
  onReload?: () => void;
}

const PurchaseHistoryItem: FC<Props> = ({ ...props }) => {
  const { item, onReload } = props;

  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const disPatch = useDispatch();
  const location = useLocation();
  const [visible, setVisible] = useState<boolean>(false);
  const [successModalCancelVisible, setSuccessModalCancelVisible] =
    useState<boolean>(false);
  const [isConfirmCancelTransaction, setIsConfirmCancelTransaction] =
    useState<boolean>(false);

  const [enableVideoCall, setEnableVideoCall] = useState(false);

  const [startVideoCall, setStartVideoCall] = useState(false);

  const [infoWarning, setInfoWarning] = useState<AppModalProps>();

  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [infoSuccess, setInfoSuccess] = useState<AppModalProps>();

  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const [showModalDownload, setShowModalDownload] = useState<boolean>(false);

  let timeOutVisitOnline: NodeJS.Timeout;

  function handleWhenClickStatus(status: ProductTransactionStatus) {
    navigate(`/my-page/purchase-history/${item.id}`);
  }

  const requestContactButton = () => (
    <div className="ui-button-more-sale-history">
      <div className="content__title">
        {translate("purchase.history.action.request.contact")}
      </div>
      <div className="content__icon">
        <ContactIcon />
      </div>
    </div>
  );

  const editContactButton = () => (
    <div className="ui-button-more-sale-history">
      <div className="content__title">
        {translate("purchase.history.action.edit.request.contact")}
      </div>
      <div className="content__icon">
        <Edit />
      </div>
    </div>
  );

  const joinContactOnlineButton = () => (
    <div
      className={classNames(
        "ui-button-more-sale-history",
        !enableVideoCall && "icon-disable"
      )}
    >
      <div className="content__title">
        {translate("purchase.history.action.join.contact.online")}
      </div>
      <div className="content__icon">
        <JoinContactOnline disable={!enableVideoCall} />
      </div>
    </div>
  );

  const reContactButton = () => (
    <div className="ui-button-more-sale-history">
      <div className="content__title">
        {translate("purchase.history.action.re.request.contact")}
      </div>
      <div className="content__icon">
        <ReContactIcon />
      </div>
    </div>
  );

  const joinMessageButton = () => (
    <div className="ui-button-more-sale-history">
      <div className="content__title">
        {translate("purchase.history.action.join.contact.message")}
      </div>
      <div className="content__icon">
        <OnlineContactIcon />
      </div>
    </div>
  );

  const confirmContactButton = () => (
    <div className="ui-button-more-sale-history">
      <div className="content__title">
        {translate("purchase.history.action.complete.contact")}
      </div>
      <div className="content__icon">
        <CompletedContactIcon />
      </div>
    </div>
  );

  const confirmOrderButton = () => (
    <div className="ui-button-more-sale-history">
      <div className="content__title">
        {translate("purchase.history.action.confirm.purchase")}
      </div>
      <div className="content__icon">
        <ConfirmPurchaseIcon />
      </div>
    </div>
  );

  const reSendInfoButton = () => (
    <div className="ui-button-more-sale-history">
      <div className="content__title">
        {translate("purchase.history.action.resend.payment.info")}
      </div>
      <div className="content__icon">
        <ResendInfoIcon />
      </div>
    </div>
  );

  const confirmCheckingButton = () => (
    <div className="ui-button-more-sale-history">
      <div className="content__title">
        {translate("purchase.history.action.complete.arrival.confirmation")}
      </div>
      <div className="content__icon">
        <ConfirmArrivalIcon />
      </div>
    </div>
  );

  const cancelButton = () => (
    <div className="ui-button-more-sale-history">
      <div
        className="content__title"
        onClick={(e) => {
          e.stopPropagation();
          setIsConfirmCancelTransaction(true);
          setVisible(false);
        }}
      >
        {translate("sale.history.cancel")}
      </div>
      <div className="content__icon">
        <Close
          onClick={(e) => {
            e.stopPropagation();
            setVisible(false);
          }}
          style={{ border: "1px solid #333333", borderRadius: "50%" }}
        />
      </div>
    </div>
  );

  function getColor(status: ProductTransactionStatus) {
    switch (status) {
      case ProductTransactionStatus.IN_PURCHASE:
        return "#F04438";

      case ProductTransactionStatus.IN_REQUEST:
        return "#69E72E";

      case ProductTransactionStatus.IN_CONTACT:
        return "#1976D2";

      case ProductTransactionStatus.IN_CONFIRM:
        return "#FFC122";

      case ProductTransactionStatus.IN_PAYMENT:
        return "#06B6C1";

      case ProductTransactionStatus.PAYMENT_COMPLETE:
        return "#7F56D9";

      case ProductTransactionStatus.IN_CHECKING:
        return "#FFA726";

      case ProductTransactionStatus.CONFIRM_RECEIVED:
        return "#B71289";

      case ProductTransactionStatus.DONE:
        return "#12B76A";

      case ProductTransactionStatus.CANCEL:
        return "#A3A2A2";

      case ProductTransactionStatus.UNPUBLISH:
        return "#1976D2";

      case ProductTransactionStatus.PUBLISH:
        return "#12B76A";

      default:
        return "#1976D2";
    }
  }

  function getBackground(status: ProductTransactionStatus) {
    switch (status) {
      case ProductTransactionStatus.IN_PURCHASE:
        return "#FFF0EF";

      case ProductTransactionStatus.IN_REQUEST:
        return "#E4F8CB";

      case ProductTransactionStatus.IN_CONTACT:
        return "#E9F5FF";

      case ProductTransactionStatus.IN_CONFIRM:
        return "#FFF9C6";

      case ProductTransactionStatus.IN_PAYMENT:
        return "#EBFFFF";

      case ProductTransactionStatus.PAYMENT_COMPLETE:
        return "#F4EBFF";

      case ProductTransactionStatus.IN_CHECKING:
        return "#FFEACC";

      case ProductTransactionStatus.CONFIRM_RECEIVED:
        return "#FFE9F9";

      case ProductTransactionStatus.DONE:
        return "#D1FADF";

      case ProductTransactionStatus.CANCEL:
        return "#EFF0F1";

      case ProductTransactionStatus.PUBLISH:
        return "#D1FADF";

      case ProductTransactionStatus.UNPUBLISH:
        return "#E9F5FF";

      default:
        return "#E9F5FF";
    }
  }

  function checkDisableVideoCall(productDetail: ProductHistory) {
    setEnableVideoCall(false);
    const timeCall = moment(productDetail?.contactTime).valueOf();
    const now = moment().valueOf();
    const diff = (timeCall - now) / (1000 * 60);
    if (diff > -30 && diff <= 30) {
      setEnableVideoCall(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      if (diff > 0) {
        if (timeOutVisitOnline) {
          clearTimeout(timeOutVisitOnline);
        }
        timeOutVisitOnline = setTimeout(() => {
          setEnableVideoCall(true);
        }, diff * 60 * 1000);
      } else setStartVideoCall(true);
    }
  }

  useEffect(
    () => () => {
      if (timeOutVisitOnline) {
        clearTimeout(timeOutVisitOnline);
      }
    },
    []
  );

  useEffect(() => {
    checkDisableVideoCall(item);
  }, [item]);

  function updateStatusProductHistoryDetail(action: () => void) {
    if (!item) {
      return;
    }

    disPatch(setAppLoading(true));
    ProductService.updateStatusProductHistoryDetail({
      id: item?.id,
      token: item?.orderToken,
    })
      .then((res) => {
        disPatch(setAppLoading(false));
        action();
      })
      .catch((err) => {
        disPatch(setAppLoading(false));
        if (getIdError(err) === "M0127") {
          injectHandleOk(() => onReload?.());
        }
      });
  }

  const onVisibleChange = (visible: boolean) => setVisible(visible);

  const confirmContact = () => {
    const hasExpectedSchedule =
      moment(item.contactTime).add(30, "minute") > moment() ||
      item?.contactType !== ContactType.MessageOnly;
    setInfoWarning({
      title: hasExpectedSchedule
        ? "予定されている見学があります。見学を無視してコンタクトを完了しますか？"
        : "コンタクトを完了しますか。",
      content: hasExpectedSchedule
        ? "コンタクトが完了したら、購入にお進みください。"
        : "完了後に対象商品の購入確定を実施してください。",
      okText: "確認",
      cancelText: "キャンセル",
      onOk: () => {
        setShowModalConfirm(false);
        updateStatusProductHistoryDetail(() => {
          setInfoSuccess({
            title: "コンタクトが完了しました。",
            content:
              "再度コンタクトしたい場合、別のコンタクトをご依頼ください。",
            onOk: () => {
              onReload?.();
              setShowModalSuccess(false);
            },
          });
          setShowModalSuccess(true);
        });
      },
    });
    setVisible(false);
    setShowModalConfirm(true);
  };

  const confirmOrder = () => {
    navigate(
      `${PATH_NAME.PRODUCT_CONFIRM}?id=${item?.id}&productId=${item?.productId}`
    );
  };

  const sendPaymentInfo = (action: () => void) => {
    if (!item) {
      return;
    }
    disPatch(setAppLoading(true));
    ProductService.resendPaymentInfomation(item?.id)
      .then((res) => {
        disPatch(setAppLoading(false));
        action();
      })
      .catch((err) => {
        disPatch(setAppLoading(false));
      });
  };

  const sendInfo = () => {
    setInfoWarning({
      title: "購入代金のお振込先を再送信しますか。",
      content: "購入代金のお振込先はメールで送信されます。",
      okText: "はい",
      cancelText: "キャンセル",
      onOk: () => {
        setShowModalConfirm(false);
        sendPaymentInfo(() => {
          setInfoSuccess({
            title: "購入代金のお振込先がメールで再送信されました。",
            content: "あなたのメールアドレスを確認してください。",
            onOk: () => {
              setShowModalSuccess(false);
            },
            okText: "閉じる",
          });
          setShowModalSuccess(true);
        });
      },
    });
    setVisible(false);
    setShowModalConfirm(true);
  };

  const confirmChecking = () => {
    setInfoWarning({
      title: "商品は到着しましたか?",
      content: "",
      okText: "はい",
      cancelText: "キャンセル",
      onOk: () => {
        setShowModalConfirm(false);
        updateStatusProductHistoryDetail(() => {
          setInfoSuccess({
            okText: "閉じる",
            title: "到着確認が完了しました。",
            content: "到着確認が終わり取引がすべて完了しました。",
            onOk: () => {
              onReload?.();
              setShowModalSuccess(false);
            },
          });
          setShowModalSuccess(true);
        });
      },
    });
    setVisible(false);
    setShowModalConfirm(true);
  };

  const renderAction = () => {
    switch (item.status) {
      case ProductTransactionStatus.IN_PURCHASE:
        return (
          <>
            <Desktop>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/product/${item?.productId}/contact/create?orderId=${item?.id}`
                  );
                }}
              >
                {translate("purchase.history.action.request.contact")}
              </AppButton>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsConfirmCancelTransaction(true);
                }}
              >
                {translate("purchase.history.action.cancel")}
              </AppButton>
            </Desktop>
            <Mobile>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/product/${item?.productId}/contact/create?orderId=${item?.id}`
                  );
                }}
              >
                {requestContactButton()}
              </div>
              <div>{cancelButton()}</div>
            </Mobile>
          </>
        );

      case ProductTransactionStatus.IN_REQUEST:
        return (
          <>
            <Desktop>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/product/${item?.productId}/contact/edit?contactId=${item?.contactId}`
                  );
                }}
              >
                {translate("purchase.history.action.edit.request.contact")}
              </AppButton>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsConfirmCancelTransaction(true);
                }}
              >
                {translate("purchase.history.action.cancel")}
              </AppButton>
            </Desktop>
            <Mobile>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/product/${item?.productId}/contact/edit?contactId=${item?.contactId}`
                  );
                }}
              >
                {editContactButton()}
              </div>
              <div>{cancelButton()}</div>
            </Mobile>
          </>
        );

      case ProductTransactionStatus.IN_CONTACT:
        return (
          <>
            {(item?.contactType === ContactType.MessageOnly ||
              item?.contactType === ContactType.OnlineTour) && (
              <>
                {item?.contactType === ContactType.OnlineTour && (
                  <>
                    <Desktop>
                      <AppButton
                        buttontype="secondary"
                        disabled={!enableVideoCall}
                        onClick={(e) => {
                          e.stopPropagation();
                          if ((window as any).ReactNativeWebView) {
                            if (startVideoCall) {
                              dispatch(setAppLoading(true));
                              ProductService.getContactDetail(item.contactId)
                                .then((res) => {
                                  dispatch(setAppLoading(false));
                                  const message = {
                                    type: "JOIN_CALL",
                                    data: res.data.result as PreCallInfo,
                                  };
                                  (
                                    window as any
                                  ).ReactNativeWebView.postMessage(
                                    JSON.stringify(message)
                                  );
                                })
                                .catch((err) => {
                                  dispatch(setAppLoading(false));
                                });
                            } else
                              navigate(
                                `/visit-online/preparation?contactId=${item.contactId}&productId=${item.productId}`
                              );
                          } else setShowModalDownload(true);
                        }}
                      >
                        {translate(
                          "purchase.history.action.join.contact.online"
                        )}
                      </AppButton>
                      {/* {isVisibleContactOnline && (
                        <AppButton
                          buttontype="secondary"
                          onClick={() =>
                            navigate(`/product/${item?.productId}/re-contact`)
                          }
                        >
                          {translate(
                            "purchase.history.action.re.request.contact"
                          )}
                        </AppButton>
                      )} */}
                    </Desktop>
                    <Mobile>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            (window as any).ReactNativeWebView &&
                            enableVideoCall
                          ) {
                            if (startVideoCall) {
                              dispatch(setAppLoading(true));
                              ProductService.getContactDetail(item.contactId)
                                .then((res) => {
                                  dispatch(setAppLoading(false));
                                  const message = {
                                    type: "JOIN_CALL",
                                    data: res.data.result as PreCallInfo,
                                  };
                                  (
                                    window as any
                                  ).ReactNativeWebView.postMessage(
                                    JSON.stringify(message)
                                  );
                                })
                                .catch((err) => {
                                  dispatch(setAppLoading(false));
                                });
                            } else
                              navigate(
                                `/visit-online/preparation?contactId=${item.contactId}&productId=${item.productId}`
                              );
                          } else if (
                            !(window as any).ReactNativeWebView &&
                            enableVideoCall
                          ) {
                            setShowModalDownload(true);
                            setVisible(false);
                          }
                        }}
                      >
                        {joinContactOnlineButton()}
                      </div>
                      {/* {isVisibleContactOnline && (
                        <div
                        onClick={() =>
                          navigate(`/product/${item?.productId}/re-contact`)
                        }
                        >
                          {reContactButton()}
                        </div>
                      )} */}
                    </Mobile>
                  </>
                )}

                {item?.contactType === ContactType.MessageOnly && (
                  <>
                    <Desktop>
                      <AppButton
                        buttontype="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(
                            setRoomId(
                              `${item.productId}${item?.buyerId}${item.sellerId}`
                            )
                          );
                        }}
                      >
                        {translate(
                          "purchase.history.action.join.contact.message"
                        )}
                      </AppButton>

                      {/* <AppButton
                        buttontype="secondary"
                        onClick={() =>
                          navigate(`/product/${item?.productId}/re-contact`)
                        }
                      >
                        {translate(
                          "purchase.history.action.re.request.contact"
                        )}
                      </AppButton> */}
                    </Desktop>
                    <Mobile>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(
                            setRoomId(
                              `${item.productId}${item?.buyerId}${item.sellerId}`
                            )
                          );
                          setVisible(false);
                        }}
                      >
                        {joinMessageButton()}
                      </div>
                      {/* <div
                        onClick={() =>
                          navigate(`/product/${item?.productId}/re-contact`)
                        }
                      >
                        {reContactButton()}
                      </div> */}
                    </Mobile>
                  </>
                )}
              </>
            )}
            <Desktop>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmContact();
                }}
              >
                {translate("purchase.history.action.complete.contact")}
              </AppButton>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsConfirmCancelTransaction(true);
                }}
              >
                {translate("purchase.history.action.cancel")}
              </AppButton>
            </Desktop>
            <Mobile>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  confirmContact();
                }}
              >
                {confirmContactButton()}
              </div>
              <div>{cancelButton()}</div>
            </Mobile>
          </>
        );

      case ProductTransactionStatus.IN_CONFIRM:
        return (
          <>
            <Desktop>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmOrder();
                }}
              >
                {translate("purchase.history.action.confirm.purchase")}
              </AppButton>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsConfirmCancelTransaction(true);
                }}
              >
                {translate("purchase.history.action.cancel")}
              </AppButton>
            </Desktop>
            <Mobile>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  confirmOrder();
                }}
              >
                {confirmOrderButton()}
              </div>
              <div>{cancelButton()}</div>
            </Mobile>
          </>
        );

      case ProductTransactionStatus.IN_PAYMENT:
        return (
          <>
            <Desktop>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  sendInfo();
                }}
              >
                {translate("purchase.history.action.resend.payment.info")}
              </AppButton>
            </Desktop>
            <Mobile>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  sendInfo();
                }}
              >
                {reSendInfoButton()}
              </div>
            </Mobile>
          </>
        );

      case ProductTransactionStatus.IN_CHECKING:
        return (
          <>
            <Desktop>
              <AppButton
                buttontype="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmChecking();
                }}
              >
                {translate(
                  "purchase.history.action.complete.arrival.confirmation"
                )}
              </AppButton>
            </Desktop>
            <Mobile>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  confirmChecking();
                }}
              >
                {confirmCheckingButton()}
              </div>
            </Mobile>
          </>
        );

      case ProductTransactionStatus.PAYMENT_COMPLETE:
      case ProductTransactionStatus.DONE:
      case ProductTransactionStatus.CANCEL:
      default:
        return undefined;
    }
  };

  const textDownload = (
    <div style={{ marginTop: 4 }}>
      <p>{translate("prepration.download.guide1")}</p>
      <p>{translate("prepration.download.guide2")}</p>
      <p>{translate("prepration.download.guide3")}</p>
      <p>{translate("prepration.download.guide4")}</p>
    </div>
  );

  return (
    <>
      {showModalDownload && (
        <AppModal
          icon={<Warning />}
          visible={showModalDownload}
          title="この機能は開発中です。"
          content="ブラウザではオンライン見学をご利用できません。"
          okText="閉じる"
          // cancelText="閉じる"
          // onOk={() => {}}
          onOk={() => {
            setShowModalDownload(false);
          }}
        />
      )}
      {isConfirmCancelTransaction && (
        <AppModalCancelTransaction
          handleCancel={onReload}
          visible={isConfirmCancelTransaction}
          title={translate("purchase.history.transaction.warning.title")}
          content={
            <>
              <div>{translate("purchase.history.transaction.warning.content1")}</div>
              <div>{translate("purchase.history.transaction.warning.content2")}</div>
            </>
          }
          okText={translate("purchase.history.transaction.warning.button.ok")}
          cancelText={translate(
            "purchase.history.transaction.warning.button.cancel"
          )}
          onCancel={() => setIsConfirmCancelTransaction(false)}
          onOk={() => {
            setIsConfirmCancelTransaction(false);
            setSuccessModalCancelVisible(true);
          }}
          transactionId={Number(item.id)}
          orderToken={item?.orderToken}
          cancelReasonList={CANCEL_PURCHASE_REASON}
        />
      )}
      <AppModal
        icon={<Success />}
        visible={successModalCancelVisible}
        title={translate("purchase.history.transaction.success.title")}
        content={translate("purchase.history.transaction.success.content")}
        okText={translate("purchase.history.transaction.success.button.close")}
        onOk={() => {
          setSuccessModalCancelVisible(false);
          onReload?.();
        }}
      />
      <AppModal
        visible={showModalConfirm}
        title={infoWarning?.title}
        content={infoWarning?.content}
        okText={infoWarning?.okText}
        cancelText={infoWarning?.cancelText}
        onCancel={() => setShowModalConfirm(false)}
        onOk={infoWarning?.onOk}
      />

      <AppModal
        icon={<Success />}
        visible={showModalSuccess}
        title={infoSuccess?.title}
        content={infoSuccess?.content}
        okText="閉じる"
        onOk={infoSuccess?.onOk}
      />
      <Col xs={24}>
        <div className="sale-ui-history-item">
          <div className="row-name">
            <div className="row-content">
              <div
                className="row-time"
                onClick={() => {
                  handleWhenClickStatus(item.status);
                }}
              >
                <div className="sale-ui-history-item__date">
                  {moment(
                    item?.lastModificationTime || item?.creationTime
                  ).format("YYYY年MM月DD日")}
                </div>
                <div className="sale-ui-history-item__time">
                  {moment(
                    item?.lastModificationTime || item?.creationTime
                  ).format("HH:mm")}
                </div>
              </div>

              <div
                className="row-name"
                onClick={() => {
                  handleWhenClickStatus(item.status);
                }}
              >
                <div className="sale-ui-history-item__name">
                  <div className="sale-ui-history-item__name-title three-dot">
                    {item?.title}
                  </div>
                  <div className="sale-ui-history-item__name-maker">
                    <div className="three-dot">{item?.maker}</div>
                    <div>
                      {item?.yearOfManufacture
                        ? item?.yearOfManufacture >= 1980
                          ? `${item?.yearOfManufacture}年式`
                          : "1980年以前"
                        : "年式不明"}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="sale-ui-history-item__price"
                onClick={() => {
                  handleWhenClickStatus(item.status);
                }}
              >
                <div className="bold color-primary box-price">
                  <span>{translate("product.detail.price.asking")}: </span>
                  <div className="box-price__content">
                    <span className="text-caption-2 ">¥</span>
                    <NumberFormat
                      value={item?.price}
                      displayType="text"
                      thousandSeparator
                    />
                  </div>
                </div>
              </div>

              <div
                className="sale-ui-history-item__price"
                onClick={() => {
                  handleWhenClickStatus(item.status);
                }}
              >
                <div className="bold color-primary box-price">
                  <span>{translate("common.commission")}: </span>
                  <div className="box-price__content">
                    <span className="text-caption-2 ">¥</span>
                    <NumberFormat
                      value={item?.totalCommissionAmountOnTax}
                      displayType="text"
                      thousandSeparator
                    />
                  </div>
                </div>
              </div>

              <div
                className="sale-ui-history-item__price"
                onClick={() => {
                  handleWhenClickStatus(item.status);
                }}
              >
                <div className="bold color-primary box-price">
                  <div className="box-price__content">
                    <span className="internal-tax">
                      (うち、消費税{" "}
                      <NumberFormat
                        value={item?.totalConsumptionTaxAmount}
                        displayType="text"
                        thousandSeparator
                      />
                      円)
                    </span>
                  </div>
                </div>
              </div>

              <div className="sale-ui-history-item__price price-row-end">
                <div
                  className="bold color-primary w-total-price"
                  onClick={() => {
                    handleWhenClickStatus(item.status);
                  }}
                >
                  <div className="box-price">
                    <span>{translate("product.confirm.total.amount")}: </span>
                    <div className="box-price__content">
                      <span className="text-caption-2 ">¥</span>
                      <NumberFormat
                        value={item?.totalAmount}
                        displayType="text"
                        thousandSeparator
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row-action"
              onClick={() => {
                handleWhenClickStatus(item.status);
              }}
            >
              <div className="sale-ui-history-item__status">
                <AppTag
                  color={getColor(item?.status)}
                  backgroundColor={getBackground(item?.status)}
                  label={translate(`sale.history.status.${item?.status}`)}
                />
              </div>
              <div className="sale-ui-history-item__action">
                <Desktop>{renderAction()}</Desktop>
                {(item.status === ProductTransactionStatus.IN_PURCHASE ||
                  item.status === ProductTransactionStatus.IN_REQUEST ||
                  item.status === ProductTransactionStatus.IN_CONTACT ||
                  item.status === ProductTransactionStatus.IN_CONFIRM ||
                  item.status === ProductTransactionStatus.IN_PAYMENT ||
                  item.status === ProductTransactionStatus.IN_CHECKING) && (
                  <Mobile>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Popover
                        visible={visible}
                        onVisibleChange={onVisibleChange}
                        content={() => renderAction()}
                        trigger="click"
                        placement="bottomRight"
                      >
                        <div className="sale-ui-history-item__action-icon">
                          <Action />
                        </div>
                      </Popover>
                    </div>
                  </Mobile>
                )}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default PurchaseHistoryItem;

PurchaseHistoryItem.defaultProps = {};
