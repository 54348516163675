import React, { FC, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { forgotPassword } from "@service/API/ResetPassword";
import { Col, Form, Row } from "antd";
import AppNavigation from "@components/AppNavigation";
import AppTextField from "@components/Form/AppTextField";
import AppDatePicker from "@components/AppDatePicker";
import AppButton from "@components/AppButton";
import "./index.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/configureStore";
import { setTitle } from "src/store/State";
import { Desktop } from "src/layouts/break-point";
import { useNavigate } from "react-router-dom";
import { getTimeZone } from "src/helper";
import {
  ResetPasswordFormValue,
  ResetPasswordStatus,
  ResetPasswordStatusName,
} from "../../model";

interface Props {
  value?: ResetPasswordFormValue;
  setStatus?: (status: ResetPasswordStatus) => void;
  setValue?: (value: ResetPasswordFormValue) => void;
}

const EmailForm: FC<Props> = memo(({ ...props }) => {
  const { value, setStatus, setValue } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const onFinish = async () => {
    const value: ResetPasswordFormValue = form.getFieldsValue();
    const formData: ResetPasswordFormValue = {
      emailAddress: value.emailAddress,
      // dateOfBirth: moment(value.dateOfBirth).startOf("day").add("hour", getTimeZone()).toISOString(),
    };
    setLoading(true);
    try {
      await forgotPassword(formData);
      setStatus?.(ResetPasswordStatus.SENT_EMAIL);
      setValue?.(value);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  function validateEmail(_: any, value: string = "") {
    const splitChars = (value || "").split("");
    const hasFullSizeChar = splitChars.some((char: string) => {
      const code = char.charCodeAt(0);
      return !(
        (code >= 0x0020 && code <= 0x1fff) ||
        (code >= 0xff61 && code <= 0xff9f)
      );
    });
    const emailPattern =
      /^[A-Za-z0-9!.#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!.#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/;
    const isEmail = value.match(emailPattern);
    if (hasFullSizeChar || !isEmail) {
      return Promise.reject(t("resetPassword.emailForm.validate.email.format"));
    }
    return Promise.resolve();
  }

  useEffect(() => {
    dispatch(setTitle(ResetPasswordStatusName.INPUT_EMAIL));
    return () => {
      dispatch(setTitle(""));
    };
  }, []);

  return (
    <div className="ui-reset-password-email-form">
      <div className="ui-reset-password-email-form__pageTitle">
        <AppNavigation
          route="/login"
          title={t("resetPassword.emailForm.pageTitle")}
        />
      </div>

      <div className="ui-reset-password-email-form__form">
        <div className="ui-reset-password-email-form__explain">
          {t("resetPassword.emailForm.explain")}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={[16, 16]} className="row-input">
            <Col xs={24}>
              <AppTextField
                name="emailAddress"
                label={t("resetPassword.emailForm.email")}
                placeholder=""
                formgroup={form}
                rules={[
                  {
                    required: true,
                    message: t(
                      "resetPassword.emailForm.validate.email.required"
                    ),
                  },
                  { validator: validateEmail },
                ]}
                maxLength={255}
              />
            </Col>
          </Row>

          <div className="ui-reset-password-email-form__action">
            <Form.Item style={{ margin: 0 }}>
              <div className="ui-reset-password-email-form__action-button">
                <Row gutter={[48, 48]} justify="center">
                  <Desktop>
                    <Col xs={24} md={12} xl={12}>
                      <AppButton
                        buttontype="secondary"
                        onClick={() => navigate("/login")}
                      >
                        {t("resetPassword.emailForm.back.login")}
                      </AppButton>
                    </Col>
                  </Desktop>
                  <Col xs={24} md={12} xl={12}>
                    <AppButton
                      buttontype="primary"
                      loading={loading}
                      htmlType="submit"
                    >
                      {t("resetPassword.emailForm.send")}
                    </AppButton>
                  </Col>
                </Row>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
});

export default EmailForm;

EmailForm.defaultProps = {};
