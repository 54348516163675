export const DefaultDuration = 1800;
export const DefaultTimeCall = 2400;

export const IsNotPlaying = false;

export interface PreCallInfo {
  partnerName?: string;
  partnerCompany?: string;
}

export enum StatusCall {
  EARLY = 0, 
  PRECALL = 1, 
  UPCOMING = 2, 
  CALL = 3, 
  LATELY = 4,
}
