import React, { FC, memo, useEffect, useRef } from "react";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as Checked } from "../Icons/step-checked.svg";
import "./index.scss";

interface Step {
  label: string;
  order: number;
}

interface Props {
  steps: Step[];
  currentStep: number;
  onChange?: (step: number) => void;
  isContact?: boolean;
}

const AppSteps: FC<Props> = memo(({ ...props }) => {
  const { steps, currentStep, onChange, isContact } = props;
  const stepsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // handle drag scroll
    let isDown = false;
    let startX: number;
    let scrollLeft: number;
    const steps = stepsRef.current;

    steps?.addEventListener("mousedown", (e) => {
      isDown = true;
      startX = e.pageX - steps.offsetLeft;
      scrollLeft = steps?.scrollLeft;
    });

    steps?.addEventListener("mouseleave", () => {
      isDown = false;
    });

    steps?.addEventListener("mouseup", () => {
      isDown = false;
    });

    steps?.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - steps.offsetLeft;
      const walk = x - startX;
      steps.scrollLeft = scrollLeft - walk;
    });

    return () => {
      steps?.removeEventListener("mousedown", () => {});
      steps?.removeEventListener("mouseup", () => {});
      steps?.removeEventListener("mouseleave", () => {});
      steps?.removeEventListener("mousemove", () => {});
    };
  }, []);

  const handleChange = (step: number) => {
    onChange?.(step);
  };

  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <div className="ui-steps" ref={stepsRef}>
      {steps &&
        steps.length &&
        steps.map(({ label, order }, index) => (
          <div
            className="ui-steps__step"
            key={index}
            style={{
              maxWidth: index === steps.length - 1 ? "32px" : "100%",
              minWidth:
                index === steps.length - 1
                  ? "32px"
                  : index === 0
                  ? isDesktop
                    ? "130px"
                    : currentStep === order
                    ? "90px"
                    : "70px"
                  : isDesktop
                  ? "130px"
                  : currentStep === order
                  ? `${(window.innerWidth - 64) / 4 + 12}px`
                  : "70px",
            }}
          >
            <div
              className={classNames(
                "ui-steps__step-label",
                currentStep >= order && "active",
                index === steps.length - 1 && currentStep === order && "last",
                isContact && index === steps.length - 1 && "isContact",
                isContact && index === steps.length - 1 && currentStep === order && "isContactComplete"
              )}
              style={{
                // width: "max-content",
                position: "relative",
                left:
                  index !== 0 && index !== steps.length - 1
                    ? `${
                        -1 * 6 * label.length + (currentStep === order ? 16 : 8)
                      }px`
                    : "0px",
              }}
            >
              {label}
            </div>
            <div className="ui-steps__step-content">
              <div
                className="ui-steps__step-order"
                onClick={() => handleChange(order)}
              >
                <div
                  className={classNames(
                    "ui-steps__step-order-number",
                    currentStep === order && "active",
                    currentStep > order && "done"
                  )}
                  style={{
                    borderColor: currentStep >= order ? "#ffffff" : "#cacaca",
                  }}
                >
                  {currentStep > order ? <Checked /> : order}
                </div>
              </div>
              {index !== steps.length - 1 && (
                <div
                  className={classNames(
                    "ui-steps__step-tail",
                    currentStep >= order && "active"
                  )}
                />
              )}
            </div>
          </div>
        ))}
    </div>
  );
});

export default AppSteps;

AppSteps.defaultProps = { steps: [], currentStep: 1 };
