import React, { FC, useEffect, useRef, useState } from "react";
import "./index.scss";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Col, Row } from "antd";
import AppTimeline from "@components/AppTimeline";
import AppFieldset from "@components/AppFieldset";
import AppRow, { RowProps } from "@components/AppRow";
import AppButton from "@components/AppButton";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import AppModal, { AppModalProps } from "src/components/AppModal";
import {
  CANCEL_PURCHASE_REASON,
  ContactType,
  ProductHistoryDetail,
  ProductTransactionStatus,
} from "src/models/product.model";
import AppModalCancelTransaction from "src/components/AppModalCancelTransaction";
import { useTranslation } from "react-i18next";
import AppNavigation from "@components/AppNavigation";
import moment from "moment";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import { PATH_NAME } from "src/configs";
import ProductService from "src/services/API/Product";
import { setAppLoading, setError, setTitle } from "src/store/State";
import { AppDispatch, RootState } from "src/store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { setRoomId } from "src/store/Chat";
import { Desktop, Mobile } from "src/layouts/break-point";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import { PreCallInfo } from "src/pages/visit-online/Preparation/model";
import { buildLink, getIdError, getOS, handleOpenApp, paramsDisableModal } from "src/helper";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import AppTransactionSkeleton from "src/components/skeleton/AppTransactionSkeleton";
import useScrollTop from "src/hooks/useScrollTop";
import classNames from "classnames";
import { injectHandleOk } from "src/App";
import { TransactionType, TRANSACTION_TIMELINE } from "../../model";
import { convertToTransaction, convertToTransactionInfo } from "../Common";
import StorageService from "../../../../services/Storage";

const PurchaseHistoryDetail: FC = ({ ...props }) => {
  useScrollTop();
  useHideBottomBar(true);

  const navigate = useNavigate();

  const disPatch = useDispatch();

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const href = `haraseikouapp://home/purchaseHistory${id}`;
  const disableModal = searchParams.get("disableModal");

  const { t: translate } = useTranslation();

  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  const [showApp, setShowApp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const [infoWarning, setInfoWarning] = useState<AppModalProps>();

  const [infoSuccess, setInfoSuccess] = useState<AppModalProps>();

  const [productDetail, setProductDetail] = useState<ProductHistoryDetail>();

  const [productHistory, setProductHistory] = useState<RowProps[]>();

  const [enableVideoCall, setEnableVideoCall] = useState(false);

  const [isConfirmCancelTransaction, setIsConfirmCancelTransaction] =
    useState(false);
  const [successModalCancelVisible, setSuccessModalCancelVisible] =
    useState(false);

  let timeOutVisitOnline: NodeJS.Timeout;

  const [transactionStatus, setTransactionStatus] = useState<
    TRANSACTION_TIMELINE[]
  >([]);

  const [currentStatus, setCurrentStatus] = useState<ProductTransactionStatus>(
    ProductTransactionStatus.IN_PURCHASE
  );

  const [enableStartCall, setEnableStartCall] = useState(false);

  const [showModalDownload, setShowModalDownload] = useState<boolean>(false);

  const isApp = (window as any).ReactNativeWebView;

  const userInfo = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );

  const timeline = useRef(null);

  function handleBack() {
    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      if (productDetail?.orderStatus === ProductTransactionStatus.DONE) {
        const lastPath = currentPathname[currentPathname.length - 3];
        if (lastPath && lastPath.includes("tab=0")) {
          navigate("/my-page/purchase-history?tab=1");
          return;
        }
      }
      navigate((location.state as any)?.routeState || -1);
    }
  }

  useEffect(() => {
    if (
      !disableModal &&
      (!currentPathname?.length || currentPathname.length === 1) &&
      !StorageService.getDevice()
    ) {
      handleOpenApp(href, setShowApp);
    }
    if (!disableModal) {
      navigate(paramsDisableModal(), { replace: true });
    }
  }, []);

  function convertToView(productHistoryDetail: ProductHistoryDetail) {
    const action = () => {
      navigate(`/product-detail/${productHistoryDetail.productId}`, {
        state: { backUrl: `${location.pathname}${location.search}` },
      });
    };
    setProductHistory(convertToTransactionInfo(productHistoryDetail, action));
  }

  function convertToTransactionStep(productHistory: ProductHistoryDetail) {
    setTransactionStatus(
      convertToTransaction(productHistory, TransactionType.PURCHASE)
    );
  }

  function checkDisableVideoCall(productDetail: ProductHistoryDetail) {
    setEnableVideoCall(false);
    const timeCall = moment(productDetail?.contactTime).valueOf();
    const now = moment().valueOf();
    const diff = (timeCall - now) / (1000 * 60);
    if (diff > -40 && diff <= 40) {
      setEnableVideoCall(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      if (diff > 0) {
        if (timeOutVisitOnline) {
          clearTimeout(timeOutVisitOnline);
        }
        timeOutVisitOnline = setTimeout(() => {
          setEnableVideoCall(true);
        }, diff * 60 * 1000);
      } else {
        setEnableStartCall(true);
      }
    }
  }

  function getDetail() {
    // disPatch(setAppLoading(true));
    setIsLoading(true);
    ProductService.getProductHistoryDetail(Number(id))
      .then((res) => {
        const { sellerId, productId } = res.data.result;
        if (userInfo?.id === sellerId) {
          dispatch(
            setError({ message: "M0052", title: "common.modal.userError" })
          );
          // navigate("/dashboard");
        } else {
          setProductDetail(res.data.result);
          if (
            res.data.result.orderStatus === ProductTransactionStatus.DONE &&
            userInfo?.id === res.data.result.buyerId
          ) {
            setCurrentStatus(ProductTransactionStatus.CONFIRM_RECEIVED);
          } else setCurrentStatus(res.data.result.orderStatus);
          if (productId) {
            ProductService.getProduct(productId).then(response => {
              const product = response.data.result;
              const location = `${product?.city || ''} ${product?.district || ''} ${product?.street || ''}`;
              convertToView({ ...res.data.result, location });
            });
          }
          convertToTransactionStep(res.data.result);
          checkDisableVideoCall(res.data.result);
          setTimeout(() => {
            if (timeline.current) {
              (timeline.current as any).scrollIntoView({
                behavior: "smooth",
                // block: "center",
                // inline: "start",
              });
            }
          }, 100);
        }
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      })
      .finally(() => {
        // disPatch(setAppLoading(false));
        setIsLoading(false);
      });
  }

  function updateStatusProductHistoryDetail(action: () => void) {
    if (!productDetail) {
      return;
    }

    disPatch(setAppLoading(true));
    ProductService.updateStatusProductHistoryDetail({
      id: productDetail?.id,
      token: productDetail?.orderToken,
    })
      .then((res) => {
        disPatch(setAppLoading(false));
        action();
      })
      .catch((err) => {
        disPatch(setAppLoading(false));
        if (getIdError(err) === "M0127") {
          injectHandleOk(() => getDetail());
        }
      });
  }

  useEffect(() => {
    getDetail();
  }, [id]);

  const cancelOrder = () => {
    setIsConfirmCancelTransaction(true);
  };

  const sendPaymentInfo = (action: () => void) => {
    if (!productDetail) {
      return;
    }
    disPatch(setAppLoading(true));
    ProductService.resendPaymentInfomation(productDetail?.id)
      .then((res) => {
        disPatch(setAppLoading(false));
        action();
      })
      .catch((err) => {
        disPatch(setAppLoading(false));
      });
  };

  const sendInfo = () => {
    setInfoWarning({
      title: "購入代金のお振込先を再送信しますか。",
      content: "購入代金のお振込先はメールで送信されます。",
      okText: "はい",
      cancelText: "キャンセル",
      onOk: () => {
        setShowModalConfirm(false);
        sendPaymentInfo(() => {
          setInfoSuccess({
            title: "購入代金のお振込先がメールで再送信されました。",
            content: "あなたのメールアドレスを確認してください。",
            onOk: () => {
              setShowModalSuccess(false);
            },
            okText: "閉じる",
          });
          setShowModalSuccess(true);
        });
      },
    });
    setShowModalConfirm(true);
  };

  const confirmChecking = () => {
    setInfoWarning({
      title: "商品は到着しましたか?",
      content: "",
      okText: "はい",
      cancelText: "キャンセル",
      onOk: () => {
        setShowModalConfirm(false);
        updateStatusProductHistoryDetail(() => {
          setInfoSuccess({
            okText: "閉じる",
            title: "到着確認が完了しました。",
            content: "到着確認が終わり取引がすべて完了しました。",
            onOk: () => {
              getDetail();
              setShowModalSuccess(false);
            },
          });
          setShowModalSuccess(true);
        });
      },
    });

    setShowModalConfirm(true);
  };

  function backToDashboard() {
    navigate(`/${PATH_NAME.DASHBOARD}`);
  }

  const confirmOrder = () => {
    navigate(
      `${PATH_NAME.PRODUCT_CONFIRM}?id=${productDetail?.id}&productId=${productDetail?.productId}`
    );
  };

  const confirmContact = () => {
    const hasExpectedSchedule =
      moment(productDetail?.contactTime).add(40, "minute") > moment() ||
      productDetail?.contactType !== ContactType.MessageOnly;
    setInfoWarning({
      title: hasExpectedSchedule
        ? "予定されている見学があります。見学を無視してコンタクトを完了しますか？"
        : "コンタクトを完了しますか。",
      content: hasExpectedSchedule
        ? "コンタクトが完了したら、購入にお進みください。"
        : "完了後に対象商品の購入確定を実施してください。",
      okText: "確認",
      cancelText: "キャンセル",
      onOk: () => {
        setShowModalConfirm(false);
        updateStatusProductHistoryDetail(() => {
          setInfoSuccess({
            title: "コンタクトが完了しました。",
            content:
              "再度コンタクトしたい場合、別のコンタクトをご依頼ください。",
            onOk: () => {
              getDetail();
              setShowModalSuccess(false);
            },
          });
          setShowModalSuccess(true);
        });
      },
    });
    setShowModalConfirm(true);
  };

  useEffect(() => {
    dispatch(setTitle(translate("my.page.purchase.history")));
    return () => {
      dispatch(setTitle(""));
      if (timeOutVisitOnline) {
        clearTimeout(timeOutVisitOnline);
      }
    };
  }, []);

  function handleClickVideoCall() {
    if (isApp) {
      if (enableStartCall) {
        ProductService.getContactDetail(productDetail?.contactId)
          .then((res) => {
            const preCallInfo: PreCallInfo = res.data.result;
            const message = {
              type: "JOIN_CALL",
              data: preCallInfo,
            };
            (window as any).ReactNativeWebView.postMessage(
              JSON.stringify(message)
            );
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(setAppLoading(false));
          });
      } else {
        navigate(
          `/visit-online/preparation?contactId=${productDetail?.contactId}&productId=${productDetail?.productId}`
        );
      }
    } else {
      setShowModalDownload(true);
    }
  }

  const renderAction = () => {
    switch (currentStatus) {
      case ProductTransactionStatus.IN_PURCHASE:
        return (
          <AppButton
            buttontype="primary"
            onClick={() =>
              navigate(
                `/product/${productDetail?.productId}/contact/create?orderId=${productDetail?.id}`
              )
            }
          >
            コンタクト依頼
          </AppButton>
        );
      case ProductTransactionStatus.IN_REQUEST:
        return (
          <AppButton
            buttontype="primary"
            onClick={() =>
              navigate(
                `/product/${productDetail?.productId}/contact/edit?contactId=${productDetail?.contactId}`
              )
            }
          >
            {translate("purchase.history.action.edit.request.contact")}
          </AppButton>
        );
      case ProductTransactionStatus.PAYMENT_COMPLETE:
        return undefined;

      case ProductTransactionStatus.IN_CONTACT:
        return (
          <>
            {(productDetail?.contactType === ContactType.MessageOnly ||
              productDetail?.contactType === ContactType.OnlineTour) && (
              <>
                {productDetail?.contactType === ContactType.MessageOnly && (
                  <AppButton
                    buttontype="primary"
                    onClick={() => {
                      dispatch(
                        setRoomId(
                          `${productDetail.productId}${productDetail?.buyerId}${productDetail.sellerId}`
                        )
                      );
                    }}
                  >
                    {translate("sale.history.in_contact_message")}
                  </AppButton>
                )}

                {productDetail?.contactType === ContactType.OnlineTour && (
                  <>
                    <AppButton
                      buttontype="primary"
                      disabled={!enableVideoCall}
                      onClick={() => {
                        handleClickVideoCall();
                      }}
                    >
                      {translate("sale.history.in_contact_video")}
                    </AppButton>
                    <p className="contact-time_guide-line">
                      見学時間：
                      {moment(productDetail?.contactTime).add(40, "minute") >
                      moment()
                        ? `${moment(productDetail?.contactTime).format(
                            "MM月DD日 HH:mm"
                          )}（5分前から入室できます。）`
                        : moment(productDetail?.contactTime).format("HH時mm分")}
                    </p>
                  </>
                )}
              </>
            )}
            <AppButton
              buttontype="primary"
              className={classNames(
                "app-button ant-btn-custom-primary",
                currentStatus === ProductTransactionStatus.IN_CONTACT &&
                  productDetail?.contactType === ContactType.OnlineTour &&
                  "mt-8"
              )}
              onClick={confirmContact}
            >
              コンタクトを完了
            </AppButton>
          </>
        );

      case ProductTransactionStatus.IN_CONFIRM:
        return (
          <AppButton buttontype="primary" onClick={confirmOrder}>
            購入を確定する
          </AppButton>
        );

      case ProductTransactionStatus.IN_PAYMENT:
        return (
          <AppButton buttontype="primary" onClick={sendInfo}>
            代金振込情報を再送信
          </AppButton>
        );
      case ProductTransactionStatus.IN_CHECKING:
        return (
          <AppButton buttontype="primary" onClick={confirmChecking}>
            到着確認を完了
          </AppButton>
        );

      default:
        return undefined;
    }
  };

  const cancelButton = () => (
    <div className="cancel-button">
      <AppButton
        buttontype="secondary"
        onClick={() => {
          setIsConfirmCancelTransaction(true);
        }}
      >
        {translate("sale.history.cancel")}
      </AppButton>
    </div>
  );

  const renderActionBottom = () => (
        <Row gutter={[16, 16]} justify="center">
          {[ProductTransactionStatus.IN_PURCHASE, ProductTransactionStatus.IN_REQUEST, ProductTransactionStatus.IN_CONTACT, ProductTransactionStatus.IN_CONFIRM].includes(currentStatus) && (
              <Col xs={24} md={8} lg={6}>
                <AppButton
                    buttontype="secondary"
                    onClick={() => {
                      setIsConfirmCancelTransaction(true);
                    }}
                >
                  {translate("sale.history.cancel")}
                </AppButton>
              </Col>
          )}
          {currentStatus === ProductTransactionStatus.CONFIRM_RECEIVED && (
              <Col xs={24} md={8} lg={6}>
                <AppButton buttontype="primary" onClick={backToDashboard}>
                  {translate("transaction.done.button.toDashboard")}
                </AppButton>
              </Col>
          )}
          {![ProductTransactionStatus.CONFIRM_RECEIVED || ProductTransactionStatus.DONE || ProductTransactionStatus.CANCEL].includes(currentStatus) && (
              <Col xs={24} md={8} lg={6}>
                <AppButton
                    buttontype="primary"
                    onClick={() => {
                      dispatch(
                          setRoomId(
                              `${productDetail?.productId}${productDetail?.buyerId}${productDetail?.sellerId}`
                          )
                      );
                    }}
                >
                  チャット
                </AppButton>
              </Col>
          )}
        </Row>
    );
  const textDownload = (
    <div style={{ marginTop: 4 }}>
      <p>{translate("prepration.download.guide1")}</p>
      <p>{translate("prepration.download.guide2")}</p>
      <p>{translate("prepration.download.guide3")}</p>
      <p>{translate("prepration.download.guide4")}</p>
    </div>
  );

  return (
    <>
      <AppModal
        visible={showApp}
        title="このページを“マシプラ”で開きますか？"
        cancelText="キャンセル"
        onCancel={() => setShowApp(false)}
        onOk={() => {
          setShowApp(false);
          buildLink(href);
        }}
        okText={
          getOS() === "Android" ? (
            <a href={href}>
              <span style={{ color: "#fff" }}>あける</span>
            </a>
          ) : (
            <span style={{ color: "#fff" }}>あける</span>
          )
        }
      />
      {showModalDownload && (
        <AppModal
          icon={<Warning />}
          visible={showModalDownload}
          title="この機能は開発中です。"
          content="ブラウザではオンライン見学をご利用できません。"
          okText="閉じる"
          // cancelText="閉じる"
          // onOk={() => {}}
          onOk={() => {
            setShowModalDownload(false);
          }}
        />
      )}

      {isConfirmCancelTransaction && (
        <AppModalCancelTransaction
          handleCancel={() => getDetail()}
          visible={isConfirmCancelTransaction}
          title={translate("purchase.history.transaction.warning.title")}
          content={
            <>
              <div>{translate("purchase.history.transaction.warning.content1")}</div>
              <div>{translate("purchase.history.transaction.warning.content2")}</div>
            </>
          }
          okText={translate("purchase.history.transaction.warning.button.ok")}
          cancelText={translate(
            "purchase.history.transaction.warning.button.cancel"
          )}
          onCancel={() => {
            setIsConfirmCancelTransaction(false);
          }}
          onOk={() => {
            setIsConfirmCancelTransaction(false);
            setSuccessModalCancelVisible(true);
          }}
          transactionId={Number(productDetail?.id)}
          orderToken={productDetail?.orderToken || ""}
          cancelReasonList={CANCEL_PURCHASE_REASON}
        />
      )}

      <AppModal
        icon={<Success />}
        visible={successModalCancelVisible}
        title={translate("purchase.history.transaction.success.title")}
        content={translate("purchase.history.transaction.success.content")}
        okText={translate("purchase.history.transaction.success.button.close")}
        onOk={() => {
          setSuccessModalCancelVisible(false);
          getDetail();
        }}
      />

      <AppModal
        visible={showModalConfirm}
        title={infoWarning?.title}
        content={infoWarning?.content}
        okText={infoWarning?.okText}
        cancelText={infoWarning?.cancelText}
        onCancel={() => setShowModalConfirm(false)}
        onOk={infoWarning?.onOk}
      />

      <AppModal
        icon={<Success />}
        visible={showModalSuccess}
        title={infoSuccess?.title}
        content={infoSuccess?.content}
        okText="閉じる"
        onOk={infoSuccess?.onOk}
      />

      <div className="ui-my-page-purchase-history-detail">
        <AppNavigation
          title={translate("transaction.purchase.history")}
          onClick={() => {
            handleBack();
          }}
        />
        <AppPullToRefresh
          onRefresh={() => {
            getDetail();
          }}
        >
          {isLoading ? (
            <AppTransactionSkeleton></AppTransactionSkeleton>
          ) : (
            <div className="detail-container">
              <Desktop>
                <div className="ui-my-page-purchase-history-detail__timeline">
                  <Row gutter={[32, 32]} justify="center">
                    <Col xs={24}>
                      <AppTimeline
                        dataSource={transactionStatus}
                        currentStatus={currentStatus}
                        actionView={renderAction()}
                      />
                    </Col>
                  </Row>
                </div>
              </Desktop>

              {currentStatus === ProductTransactionStatus.IN_CONTACT && (
                <Row gutter={[32, 32]} justify="center">
                  <Col xs={24}>
                    {productDetail?.contactType === ContactType.OnlineTour && (
                      <div className="ui-my-page-purchase-history-detail__type-contact">
                        この商品の取引にはオンライン見学が指定されています。
                      </div>
                    )}
                    {productDetail?.contactType ===
                      ContactType.LocalPreview && (
                      <div className="ui-my-page-purchase-history-detail__type-contact">
                        この商品の取引には現地内見が指定されています。
                      </div>
                    )}
                  </Col>
                </Row>
              )}

              <Row gutter={[32, 32]} justify="center">
                <Col xs={24}>
                  <AppFieldset title={translate("transaction.detail")}>
                    {productHistory &&
                      productHistory.length &&
                      productHistory.map(
                        (
                          {
                            label,
                            value,
                            valueColor,
                            action,
                            isHidden,
                            labelWidth,
                            valueWidth,
                          },
                          index
                        ) => (
                          <AppRow
                            isHidden={isHidden}
                            action={action}
                            key={index}
                            label={label}
                            value={value}
                            labelWidth={labelWidth}
                            valueWidth={valueWidth}
                            valueColor={valueColor}
                            hasBorderBottom={
                              index !== productHistory.length - 1
                            }
                          />
                        )
                      )}
                  </AppFieldset>
                </Col>
              </Row>

              <Mobile>
                <div
                  className="ui-my-page-purchase-history-detail__timeline"
                  ref={timeline}
                >
                  <Row gutter={[32, 32]} justify="center">
                    <Col xs={24}>
                      <AppTimeline
                        dataSource={transactionStatus}
                        currentStatus={currentStatus}
                        actionView={renderAction()}
                      />
                    </Col>
                  </Row>
                </div>
              </Mobile>

              <div className="ui-my-page-purchase-history-detail__action">
                {renderActionBottom()}
              </div>
            </div>
          )}
        </AppPullToRefresh>
      </div>
    </>
  );
};

export default PurchaseHistoryDetail;

PurchaseHistoryDetail.defaultProps = {};
