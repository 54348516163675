import { Col, Row } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AppNavigation from "src/components/AppNavigation";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "./index.scss";
import NotificationService from "src/services/API/Notification";
import { useDispatch, useSelector } from "react-redux";
import { setAppLoading } from "src/store/State";
import AppButton from "src/components/AppButton";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "src/configs";
import moment from "moment";
import { Desktop } from "src/layouts/break-point";
import AppStore, { AppDispatch, RootState } from "src/store/configureStore";
import ProductService from "src/services/API/Product";
import { getTotalUnreadNotice } from "src/store/Global";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import { MessageType } from "src/models/common.model";
import { PreCallInfo } from "src/pages/visit-online/Preparation/model";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import AppModal from "src/components/AppModal";
import { ReactComponent as ArrowLeft2 } from "../../../../components/Icons/arrow-left-2.svg";
import { NoticeItem, NOTICE_SYSTEM_TYPE } from "../../model";

export default function NotificationDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useHideBottomBar(true);
  const user = useSelector((state: RootState) => state.UserReducer.userInfo);
  const dispatch = useDispatch<AppDispatch>();
  const [noticeDetail, setNoticeDetail] = useState<NoticeItem>();
  const [sentDate, setSentDate] = useState<string>();
  const [idSeller, setIdSeller] = useState<number>();
  const [contactId, setContactId] = useState<number>();
  const [productId, setProductId] = useState<number>();
  const [contactTime, setContactTime] = useState<string>();
  const [showModalWarning, setShowModalWarning] = useState<boolean>(false);
  const [showModalOvertime, setShowModalOvertime] = useState<boolean>(false);

  const { id } = useParams();
  const disPatch = useDispatch();
  const isApp = (window as any).ReactNativeWebView;
  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  useEffect(() => {
    setTimeout(() => {
      const anchors = document.querySelectorAll(".inner-html a");
      anchors.forEach((item) => {
        const hyperLink = item?.getAttribute("href");
        if (hyperLink) {
          item.addEventListener("click", (e) => {
            if (isApp) {
              e.preventDefault();
              const message = {
                type: MessageType.MODAL,
                data: hyperLink,
              };
              (window as any).ReactNativeWebView?.postMessage(
                JSON.stringify(message)
              );
            }
          });
        }
      });
    }, 0);
  }, [id, noticeDetail]);

  function getDetail() {
    disPatch(setAppLoading(true));
    NotificationService.getNotification(Number(id))
      .then((res) => {
        const response = res.data.result;
        setNoticeDetail(response.notification);
        setSentDate(response?.sentDate);
        AppStore.dispatch(getTotalUnreadNotice());
        if (
          response.notification.notificationType === NOTICE_SYSTEM_TYPE.TRADE
        ) {
          ProductService.getProductHistoryDetail(
            Number(response.notification?.objectId)
          )
            .then((res) => {
              const { sellerId, contactId, productId, contactTime } =
                res.data.result;
              setIdSeller(sellerId);
              setContactId(contactId);
              setProductId(productId);
              setContactTime(contactTime);
              disPatch(setAppLoading(false));
            })
            .catch((err) => {
              console.log(err);
              disPatch(setAppLoading(false));
            });
        } else disPatch(setAppLoading(false));
      })
      .catch((err) => {
        disPatch(setAppLoading(false));
      });
  }

  const formatTime = useMemo(() => {
    const result =
      moment().format(DATE_FORMAT) !== moment(sentDate).format(DATE_FORMAT)
        ? moment(sentDate).format(DATE_TIME_FORMAT)
        : moment(sentDate).format(TIME_FORMAT);
    return result;
  }, [sentDate]);

  useEffect(() => {
    getDetail();
  }, [id]);

  useEffect(() => {
    const imgList: any = document?.querySelectorAll(
      ".ui-notification-system-detail__view img"
    );
    if (sasCode && imgList.length > 0) {
      imgList?.forEach((item: any) => {
          if (!item.getAttribute("src").includes(sasCode)) {
            item.setAttribute("src", item.src.concat(sasCode as string));
          }
          item.classList.add("img-size");
        }
      );
    }
  }, [noticeDetail, sasCode]);

  const handleAction = () => {
    if (noticeDetail && noticeDetail.url?.includes("visit-online")) {
      if (isApp) {
        if (
          moment(contactTime).diff(moment()) * 0.001 <= 0 &&
          moment(contactTime).diff(moment()) * 0.001 >= -2400
        ) {
          dispatch(setAppLoading(true));
          ProductService.getContactDetail(contactId)
            .then((res) => {
              dispatch(setAppLoading(false));
              const message = {
                type: "JOIN_CALL",
                data: res.data.result as PreCallInfo,
              };
              (window as any).ReactNativeWebView.postMessage(
                JSON.stringify(message)
              );
            })
            .catch((err) => {
              dispatch(setAppLoading(false));
            });
        } else if (moment(contactTime).diff(moment()) * 0.001 < -2400) {
          setShowModalOvertime(true);
        } else
          navigate(
            `/visit-online/preparation?contactId=${contactId}&productId=${productId}`
          );
      } else setShowModalWarning(true);
    } else if (idSeller && user?.id === idSeller) {
      navigate(`/my-page/sales-history/${noticeDetail?.objectId}`);
    } else if (idSeller && user?.id !== idSeller)
      navigate(`/my-page/purchase-history/${noticeDetail?.objectId}`);
  };

  const afterEndTime = useMemo(
    () => (
      <>
        <div>{moment(contactTime).add(40, "minutes").format("HH:mm")}</div>
        <div>過ぎています</div>
      </>
    ),
    [contactTime]
  );

  return (
    <div className="ui-notification-system-detail">
      <AppModal
        icon={<Warning />}
        visible={showModalOvertime}
        title="エラーが発生しました。"
        content={afterEndTime}
        okText={t("common.ok")}
        onOk={() => setShowModalOvertime(false)}
      />
      <AppModal
        icon={<Warning />}
        visible={showModalWarning}
        title="この機能は開発中です。"
        content="ブラウザではオンライン見学をご利用できません。"
        okText="閉じる"
        onOk={() => {
          setShowModalWarning(false);
        }}
      />
      <AppNavigation
        route="/notification?type=system"
        title={t("notification.system.title")}
      />

      <Desktop>
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24}>
            <div className="ui-notification-system-detail__navigation">
              <ArrowLeft2
                onClick={() => {
                  navigate("/notification?type=system");
                }}
              />
              <span> {t("notification.system.title")} </span>
            </div>
            <div className="ui-notification-system-detail__line"></div>
          </Col>
        </Row>
      </Desktop>

      <AppPullToRefresh
        onRefresh={() => {
          getDetail();
        }}
      >
        <div className="ui-notification-system-detail__view">
          <Row gutter={[32, 32]} justify="center">
            <Col xs={24}>
              <div className="ui-notification-system-detail__content">
                <div className="ui-notification-system-detail__content-time">
                  {sentDate && <p>{formatTime}</p>}
                </div>
                <div className="ui-notification-system-detail__content-text">
                  <div className="ui-notification-system-detail__content-text-title">
                    {noticeDetail?.title}
                  </div>
                  {noticeDetail && (
                    <p
                      className="inner-html"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: (noticeDetail?.content as string).replace(
                          /href/g,
                          "target='_blank' href"
                        ),
                      }}
                    ></p>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {noticeDetail &&
            noticeDetail.notificationType === NOTICE_SYSTEM_TYPE.TRADE && (
              <div className="ui-notification-system-detail__action">
                <Row gutter={[48, 48]} justify="center">
                  <Col xs={24} md={12} xl={8}>
                    <AppButton buttontype="primary" onClick={handleAction}>
                      {t(
                        noticeDetail.url?.includes("visit-online")
                          ? "purchase.history.action.join.contact.online"
                          : "notification.system.detail.to.transaction"
                      )}
                    </AppButton>
                  </Col>
                </Row>
              </div>
            )}
        </div>
      </AppPullToRefresh>
    </div>
  );
}
