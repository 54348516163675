import React, { FC, memo, ReactNode, useState } from "react";
import { LabeledValue } from "antd/es/select";
import classnames from "classnames";
import { Form, Modal, ModalProps } from "antd";
import { useTranslation } from "react-i18next";
import ProductService from "src/services/API/Product";
import { useDispatch } from "react-redux";
import { setAppLoading } from "src/store/State";
import { injectHandleOk } from "src/App";
import { ReactComponent as Warning } from "../Icons/warning.svg";
import AppButton from "../AppButton";
import "./index.scss";
import AppSelect from "../Form/AppSelect";
import AppTextArea from "../Form/AppTextArea";

export interface AppModalProps extends ModalProps {
  icon?: ReactNode;
  title?: string | ReactNode;
  content?: ReactNode;
  okText?: string;
  cancelText?: string;
  orderToken: string;
  validateFields?: string[];
  cancelReasonList: LabeledValue[];
  transactionId: number;
  handleCancel?: () => void;
}

const AppModalCancelTransaction: FC<AppModalProps> = memo(({ ...props }) => {
  const { t: translate } = useTranslation();
  const disPatch = useDispatch();
  const {
    visible,
    icon,
    title,
    onOk,
    onCancel,
    className,
    cancelReasonList,
    okText,
    cancelText,
    transactionId,
    content,
    orderToken,
    handleCancel,
  } = props;

  const [form] = Form.useForm();
  const [showOtherReason, setShowOtherReason] = useState(false);

  const handleCancelTransaction = () => {
    disPatch(setAppLoading(true));
    ProductService.cancelTransactionOrder2({
      id: transactionId,
      token: orderToken,
      cancelReasonId: form.getFieldValue("reasonDefault"),
      cancelReason:
        form.getFieldValue("reasonDefault") === 4
          ? form.getFieldValue("reasonOther")
          : cancelReasonList[form.getFieldValue("reasonDefault") - 1].label,
    })
      .then((res) => {
        disPatch(setAppLoading(false));
        if (onOk) {
          onOk({} as any);
        }
      })
      .catch((err) => {
        disPatch(setAppLoading(false));
        if (onCancel) {
          onCancel({} as any);
          injectHandleOk(() => handleCancel?.());
        }
      });
  };

  function onFinish() {
    handleCancelTransaction();
  }

  return (
    <Modal
      className={classnames("app-modal", className)}
      visible={visible}
      centered
      footer={null}
      closable={false}
      onOk={onOk}
      destroyOnClose
      // maskClosable={false}
    >
      <div className="ui-modal ">
        <div className="ui-modal__icon">{icon}</div>
        <div className="ui-modal__title">{title}</div>
        {content && <div className="ui-modal__content">{content}</div>}

        <div className="ui-modal__action">
          <Form form={form} onFinish={onFinish}>
            <AppSelect
              options={cancelReasonList.map((reason) => ({
                label: reason.label,
                value: reason.value,
              }))}
              name="reasonDefault"
              label={translate(
                "purchase.history.transaction.warning.reason.title"
              )}
              placeholder=""
              onChange={(value) => {
                setShowOtherReason(value === 4);
              }}
              rules={[
                {
                  required: true,
                  message: translate(
                    "purchase.history.transaction.warning.reason.required"
                  ),
                },
              ]}
            />

            {showOtherReason && (
              <AppTextArea
                name="reasonOther"
                maxLength={1000}
                formgroup={form}
              ></AppTextArea>
            )}

            <div className="ui-modal__ok-btn">
              <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                {() => (
                  <AppButton buttontype="primary" htmlType="submit">
                    {okText}
                  </AppButton>
                )}
              </Form.Item>
            </div>

            <div className="ui-modal__cancel-btn" onClick={onCancel}>
              <AppButton buttontype="secondary">{cancelText}</AppButton>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
});

export default AppModalCancelTransaction;

AppModalCancelTransaction.defaultProps = {
  icon: <Warning />,
  title: "This is title",
  content: undefined,
  okText: "Confirm",
  cancelText: undefined,
};
