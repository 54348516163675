import React, { FC, memo, useEffect, useMemo } from "react";
import { Col, Form, Row } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "@store/configureStore";
import AppRadio from "@components/Form/AppRadio";
import AppButton from "@components/AppButton";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { Desktop, Mobile } from 'src/layouts/break-point';
import { ContactMethodEnum, ContactStatus, ContactValue } from "../../model";

interface Props {
  setStatus?: (status: ContactStatus) => void;
  setValue?: (value: ContactValue) => void;
  value?: ContactValue;
  setVisibleWarningModal?: () => void;
}

const ContactMethod: FC<Props> = memo(({ ...props }) => {
  const [form] = Form.useForm();
  const { setStatus, setValue, value, setVisibleWarningModal } = props;
  const product = useSelector((state: RootState) => state.ProductReducer.productDetail);
  const { t } = useTranslation();

  const titleStyle = { fontSize: "15px", fontWeight: "bold", color: "#333" };
  const descriptionStyle = { fontSize: "12px", color: "#666", fontWeight: 400 };

  const options = useMemo(() => {
    const methods = [
      {
        label: (
          <>
            <div style={titleStyle}>{t("contact.step1.method2.title")}</div>
            <div style={descriptionStyle}>
              <div>{`${product?.city || ''} ${product?.district || ''} ${product?.street || ''}`}</div>
              <div>{t("contact.step1.method2.description")}</div>
            </div>
          </>
        ),
        value: ContactMethodEnum.OFFLINE
      },
      {
        label: (
          <>
            <div style={titleStyle}>{t("contact.step1.method3.title")}</div>
            <div style={descriptionStyle}>{t("contact.step1.method3.description")}</div>
          </>
        ),
        value: ContactMethodEnum.MESSAGE
      }
    ];

    if (product.isAllowOnlineTours) {
      methods.unshift({
        label: (
          <>
            <div style={titleStyle}>{t("contact.step1.method1.title")}</div>
            <div style={descriptionStyle}>{t("contact.step1.method1.description")}</div>
          </>
        ),
        value: ContactMethodEnum.ONLINE
      });
    }
    return methods;
  }, [product.isAllowOnlineTours]);

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);

  const onFinish = () => {
    const value = form.getFieldsValue();
    setStatus?.(ContactStatus.SETTING_TIME);
    setValue?.(value);
  };

  const onValueChanges = (value: ContactValue) => {
    setValue?.(value);
  };

  return (
    <div className="ui-contact-method">
      <Row gutter={[32, 32]} justify="center">
        <Col xs={24} md={14}>
          <div className="ui-contact-method__note">{ t("contact.step1.note") }</div>
          <div className="ui-contact-method__form">
            <Form form={form} onFinish={onFinish} onValuesChange={onValueChanges}>
              <AppRadio
                name="method"
                options={options}
                rules={[{ required: true, message: "Please select method" }]} />

              <Mobile>
                <div className="ui-contact-method__action">
                  <Row gutter={[32, 32]} justify="center">
                    <Col xs={12} md={6} xl={4}>
                      <AppButton buttontype="secondary" onClick={setVisibleWarningModal}>{ t("contact.action.close") }</AppButton>
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                      <AppButton buttontype="primary" htmlType="submit">{ t("contact.action.next") }</AppButton>
                    </Col>
                  </Row>
                </div>
              </Mobile>

              <Desktop>
                <Row gutter={[32, 32]} justify="center">
                  <Col xs={6}>
                    <AppButton buttontype="secondary" onClick={setVisibleWarningModal}>{ t("contact.action.close") }</AppButton>
                  </Col>
                  <Col xs={6}>
                    <AppButton buttontype="primary" htmlType="submit">{ t("contact.action.next") }</AppButton>
                  </Col>
                </Row>
              </Desktop>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default ContactMethod;

ContactMethod.defaultProps = {};
