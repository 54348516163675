import React from "react";
import { Outlet } from "react-router-dom";
import AppFooter from "src/components/AppFooter";
import AppToolbar from "src/components/AppToolbar";
import "./styles.scss";

export default function DesktopLayout() {
  return (
    <div className="app-layout" id="appLayout">
      <div className="app-toolbar">
        <AppToolbar title="ログイン" />
      </div>

      <div className="app-body">
        <div  className="outlet">
          <Outlet></Outlet>
        </div>
      </div>

      <div className="app-footer">
        <AppFooter />
      </div>
    </div>
  );
}
