import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { signUp } from "@service/API/SignUp";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@store/configureStore";
import { getTimeZone } from "src/helper";
import { setTitle } from "src/store/State";
import SignUpForm from "./components/Form";
import Confirmation from "./components/Confirmation";
import Success from "./components/Success";
import { SignUpFormValue, SignUpStatus, SignUpStatusName } from "./model";
import "./sign-up.scss";

export default function SignUpScreen() {
  const [value, setValue] = useState<SignUpFormValue>({});
  const [status, setStatus] = useState<SignUpStatus>(SignUpStatus.INPUT);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, _] = useSearchParams();
  const success = searchParams.get("success");

  const setSignUpValue = (value: SignUpFormValue) => setValue(value);
  const setSignUpStatus = (status: SignUpStatus) => setStatus(status);

  useEffect(() => {
    if (success) {
      setStatus(SignUpStatus.SUCCESS);
    }
  }, [success]);

  const onSignUp = async () => {
    const formData: SignUpFormValue = {
      emailAddress: value.emailAddress,
      fullName: value.fullName,
      dateOfBirth: moment(value.dateOfBirth).startOf("day").add("hour", getTimeZone()).toISOString(),
      companyName: value.companyName,
      department: value.department,
      position: value.position,
      zipCode: value.zipCode,
      city: value.city,
      district: value.district,
      street: value.street,
      buildingName: value.buildingName,
      phoneNumber: value.phoneNumber,
      password: value.password,
      isAgreeWithTerm: true,
    };
    setLoading(true);
    try {
      await signUp(formData);
      if (!loading) {
        setStatus?.(SignUpStatus.SENT_EMAIL);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const render = (signUpStatus: number) => {
    switch (signUpStatus) {
      case SignUpStatus.INPUT:
        return (
          <SignUpForm
            value={value}
            setValue={setSignUpValue}
            setStatus={setSignUpStatus}
          />
        );
      case SignUpStatus.CONFIRM:
        return (
          <Confirmation
            value={value}
            setStatus={setSignUpStatus}
            onSignUp={onSignUp}
            loading={loading}
          />
        );
      case SignUpStatus.SENT_EMAIL:
        return <Success value={value} />;
      default:
        return <SignUpForm />;
    }
  };

  return <div className="ui-sign-up">{render(status)}</div>;
}
