import React, { FC, ReactNode, useEffect } from "react";
import { ReactComponent as OrangeDot } from "../Icons/orange-dot.svg";
import "./index.scss";


interface Props {
  label: string;
  color?: string;
  backgroundColor?: string;
  icon?: ReactNode;
}

const AppTag: FC<Props> = ({ ...props }) => {
  const { label, color, backgroundColor, icon } = props;

  return (
    <div
      className="ui-tag"
      style={{
        backgroundColor: backgroundColor || "#FFEACC",
      }}
    >
      {icon ? (
        <div className="ui-tag__icon">{icon}</div>
      ) : (
        <div
          style={{
            backgroundColor: color || "#FF671E",
            width: "6px",
            height: "6px",
            borderRadius: "50%",
          }}
          className="ui-tag__icon"
        >
          &nbsp;
        </div>
      )}
      <div
        className="ui-tag__label"
        style={{
          color: color || "#FF671E",
        }}
      >
        {label}
      </div>
    </div>
  );
};

export default AppTag;

AppTag.defaultProps = { };
