import { Col, Row, Skeleton } from "antd";
import "./styles.scss";
import React from "react";

const AppTransactionSkeleton = () => (
  <Row gutter={[32, 32]} justify="center" className="skeleton-product">
    <Col xs={24}>
      <Skeleton active paragraph={{ rows: 8 }} />
    </Col>

    <Col xs={24}>
      <Skeleton.Avatar
        active
        shape="square"
        className="product-skeleton-avatar"
      />
    </Col>
  </Row>
);

export default AppTransactionSkeleton;
