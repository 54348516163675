import React, { FC, memo } from "react";
import { Form, InputNumber, InputNumberProps } from "antd";
import classNames from "classnames";
import "./index.scss";

interface Props extends InputNumberProps {
  label?: string;
  rules?: object[];
  formatter?: any;
  extra?: React.ReactNode;
}

const AppInputNumber: FC<Props> = memo(({ ...props }) => {
  const { name, label, rules, className, formatter, extra } = props;

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      className="input-number-form-cs"
      name={name}
      label={label}
      rules={rules}
      validateTrigger={["onBlur"]}
      extra={extra}
    >
      <InputNumber
        addonAfter={undefined}
        // formatter={formatter}
        inputMode="numeric"
        className={classNames("app-input-number", className)}
        autoComplete="off"
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => {
          const nextValue = value?.replace("-", "").replace(".", "");
          return nextValue!.replace(/\$\s?|(,*)/g, "");
        }}


        {...props}
      />
    </Form.Item>
  );
});

export default AppInputNumber;
