import React, { FC } from "react";
import "./index.scss";
import AppNavigation from "@components/AppNavigation";
import PurchaseHistoryList from "./PurchaseHistoryList";

const MyPagePurchaseHistory: FC = ({ ...props }) => 
  // useHideBottomBar(true);
   (
    <div className="ui-my-page-purchase-history">
      <AppNavigation route="/my-page" title="購入履歴" />
      <PurchaseHistoryList />
    </div>
  )
;

export default MyPagePurchaseHistory;

MyPagePurchaseHistory.defaultProps = {};
