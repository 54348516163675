import { ReactNode } from "react";

export interface ResetPasswordFormValue {
  emailAddress?: string;
  dateOfBirth?: any;
  newPassword?: string;
  confirmNewPassword?: string;
}

export enum ResetPasswordStatus {
  INPUT_EMAIL,
  SENT_EMAIL,
  INPUT_PASSWORD,
  SUCCESS,
  FAIL,
}

export enum ResetPasswordStatusName {
  INPUT_EMAIL = "resetPassword.emailForm.pageTitle",
  SENT_EMAIL = "resetPassword.success.pageTitle",
  INPUT_PASSWORD = "resetPassword.emailForm.pageTitle",
}

export enum SuccessType {
  SIGN_UP,
  RESET_PASSWORD,
}

export interface CollapseData {
  title: string;
  content: ReactNode;
}
