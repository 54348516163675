import { Button, Col, Form, Menu, Popover, Row } from "antd";
import "./styles.scss";
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { LabeledValue } from "antd/es/select";
import { setFilter, setPage } from "src/store/Product-Filter";
import { AppDispatch } from "@store/configureStore";
import AppDatePicker from "@components/Form/AppDatePicker";
import AppTextField from "@components/Form/AppTextField";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import classNames from "classnames";
import { DownOutlined } from "@ant-design/icons";
import { getPriceConfig } from "src/store/Global";
import { ReactComponent as ArrowDown } from "@components/Icons/arrow-down.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppTagSelection from "../AppTagSelection";
import CategorySelectForm from "../core/category-select";
import PrefectureSelectForm from "../core/prefecture-select";
import AppButton from "../AppButton";
import AppSelect from "../Form/AppSelect";
import AppSelectYear from "../Form/AppYearSelect";

interface FilterProp {
  onCancel: () => void;
  onOk: () => void;
}
const SearchFilterForm: FC<FilterProp> = ({ ...props }) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t: translate } = useTranslation();

  const productFilter = useSelector(
    (state: RootState) => state.ProductFilterReducer.filter
  );

  const stateListCity = useSelector(
    (state: RootState) => state.GlobalReducer.provinces
  );

  const [listCity, setListCity] = useState<LabeledValue[]>([]);

  const filterCategory = useRef<number[]>([]);
  // const [filterCategory, setFilterCategory] = useState<number[]>([]);

  const [showPrefecture, setShowPrefecture] = useState(false);

  const [filterPrefecture, setFilterPrefecture] = useState(
    [] as LabeledValue[]
  );

  const [form] = Form.useForm();

  const priceConfig = useSelector(
    (state: RootState) => state.GlobalReducer.priceConfig
  );

  useEffect(() => {
    if (!priceConfig || priceConfig.length <= 0) {
      dispatch(getPriceConfig());
    }
  }, [priceConfig]);

  useEffect(() => {
    const cites = stateListCity.map((x: string) => ({
      value: x,
      label: x,
    }));
    setListCity(cites);
  }, [stateListCity]);

  useLayoutEffect(() => {
    const startPrice = productFilter?.priceFrom;
    const endPrice = productFilter?.priceTo;
    const startPriceDetect = priceConfig?.find(
      (item: any) => Number(item.value) === Number(startPrice)
    );
    const endPriceDetect = priceConfig?.find(
      (item: any) => Number(item.value) === Number(endPrice)
    );

    form.setFieldsValue({
      maker: productFilter?.maker,
      isRecently: [productFilter?.isRecently],
      isPickup: [productFilter?.isPickup],
      endPrice: endPriceDetect?.key,
      startPrice: startPriceDetect?.key,
      startDate: productFilter?.yearOfManufactureFrom
        ? productFilter?.yearOfManufactureFrom
        : "",
      endDate: productFilter?.yearOfManufactureTo
        ? productFilter?.yearOfManufactureTo
        : "",
      prefecture: productFilter?.citySearch,
      category: productFilter?.categories,
    });

    const listCityTag = (productFilter?.citySearch || []).map((x) => ({
      value: x,
      label: x || "全国",
    }));
    setFilterPrefecture(listCityTag);
    filterCategory.current = productFilter?.categories || [];
  }, [productFilter, priceConfig]);

  function onPrefectureTag() {
    setShowPrefecture(true);
  }

  function setProductFilter() {
    const startPrice = form.getFieldValue("startPrice");
    const endPrice = form.getFieldValue("endPrice");
    const endPriceValue = priceConfig.find((item) => item.key === endPrice);
    const startPriceValue = priceConfig.find((item) => item.key === startPrice);
    const filter = {
      maker: form.getFieldValue("maker"),
      isRecently: true,
      isPickup: true,
      isFilterByType: false,
      priceTo: endPriceValue?.value,
      priceFrom: startPriceValue?.value,
      yearOfManufactureFrom: form.getFieldValue("startDate")
        ? form.getFieldValue("startDate")
        : undefined,
      yearOfManufactureTo: form.getFieldValue("endDate")
        ? form.getFieldValue("endDate")
        : undefined,
      citySearch: form.getFieldValue("prefecture"),
      categories: filterCategory.current,
    };
    dispatch(setFilter(filter));
    if (location.pathname.includes(`/category/${id}`)) {
      navigate("/dashboard");
    }
  }

  function clearData() {
    form.setFieldsValue({
      category: [],
      maker: undefined,
      startDate: "",
      endDate: "",
      startPrice: undefined,
      endPrice: undefined,
      prefecture: undefined,
    });
    setFilterPrefecture([]);
    filterCategory.current = [];
  }

  const handleVisibleChange = (newVisible: boolean) => {
    setShowPrefecture(newVisible);
  };

  const isFormValid = () =>
    form.getFieldsError().filter(({ errors }) => errors.length > 0).length ===
    0;

  return (
    <div className="search-filter-form-lg">
      <div className="filter-header">
        <div className="text-body-1 color-title bold">検索</div>
        <div className="filter-action">
          <AppButton
            buttontype="secondary"
            onClick={() => {
              clearData();
              setProductFilter();
            }}
          >
            {translate("filter.cancel")}
          </AppButton>
          <AppButton
            id="searchButton"
            buttontype="primary"
            onClick={() => {
              if (isFormValid()) {
                setProductFilter();
                props.onOk();
              } else {
                form.validateFields();
              }
            }}
          >
            {translate("filter.search")}
          </AppButton>
        </div>
      </div>

      <CategorySelectForm
        value={form.getFieldValue("category")}
        onSubmit={(listFilter: any[]) => {
          filterCategory.current = listFilter.map((x) => x.value);
          // form.setFieldsValue({ category: listFilter.map((x) => x.value) });
        }}
      />

      <Form form={form} className="form-filter">
        {/* <Form.Item name="category">
          <div></div>
        </Form.Item> */}

        <Row gutter={[16, 16]} className="row-filter">
          <Col xs={24}>
            <AppTextField
              name="maker"
              label={translate("product.detail.maker")}
              placeholder=""
              formgroup={form}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="form-multi first">
          <Col xs={11}>
            <AppSelectYear
              label={translate("editProduct.form.modelYear")}
              name="startDate"
              rules={[
                {
                  validator(_: any, value: any) {
                    const endDate = form.getFieldValue("endDate");
                    if (!endDate || !value || value <= endDate) {
                      form.setFields([
                        {
                          name: "endDate",
                          errors: [],
                        },
                      ]);
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "左側の年式は右側の年式以下で指定してください。"
                    );
                  },
                },
              ]}
            ></AppSelectYear>
          </Col>

          <Col xs={2}>
            <div className="text-break">
              <div className="br">&nbsp;</div>
            </div>
          </Col>

          <Col xs={11}>
            <AppSelectYear
              label="&nbsp;"
              name="endDate"
              rules={[
                {
                  validator(_: any, value: any) {
                    const startDate = form.getFieldValue("startDate");
                    if (!startDate || !value || startDate <= value) {
                      form.setFields([
                        {
                          name: "startDate",
                          errors: [],
                        },
                      ]);
                      return Promise.resolve();
                    }
                    form.validateFields(["startDate"]);
                    return Promise.reject("");
                  },
                },
              ]}
            ></AppSelectYear>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="row-filter form-multi">
          <Col xs={11}>
            <AppSelect
              label="希望価格"
              name="startPrice"
              options={priceConfig.map((x) => ({
                label: x.key,
                value: x.key,
              }))}
              rules={[
                {
                  validator(_: any, value: any) {
                    const endPrice = form.getFieldValue("endPrice");
                    const endPriceValue = priceConfig.find(
                      (item) => item.key === endPrice
                    );
                    const startPriceValue = priceConfig.find(
                      (item) => item.key === value
                    );
                    if (
                      !endPriceValue ||
                      !value ||
                      Number(startPriceValue?.value) <=
                        Number(endPriceValue?.value)
                    ) {
                      form.setFields([
                        {
                          name: "endPrice",
                          errors: [],
                        },
                      ]);
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "左側の価格は右側の価格以下で指定してください。"
                    );
                  },
                },
              ]}
            ></AppSelect>
          </Col>
          <Col xs={2}>
            <div className="text-break">
              <div className="br">&nbsp;</div>
            </div>
          </Col>
          <Col xs={11}>
            <AppSelect
              name="endPrice"
              label="&nbsp;"
              options={priceConfig.map((x) => ({
                label: x.key,
                value: x.key,
              }))}
              rules={[
                {
                  validator(_: any, value: any) {
                    const startPrice = form.getFieldValue("startPrice");
                    const startPriceValue = priceConfig.find(
                      (item) => item.key === startPrice
                    );
                    const endPriceValue = priceConfig.find(
                      (item) => item.key === value
                    );
                    if (
                      !startPriceValue ||
                      !value ||
                      Number(endPriceValue?.value) >=
                        Number(startPriceValue?.value)
                    ) {
                      form.setFields([
                        {
                          name: "startPrice",
                          errors: [],
                        },
                      ]);
                      return Promise.resolve();
                    }
                    form.validateFields(["startPrice"]);
                    return Promise.reject("");
                  },
                },
              ]}
            ></AppSelect>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="row-filter" id="prefecture">
          <Col xs={24} className="col-prefecture">
            <div className="bold text-body-4 text-title">
              {translate("signup.form.city")}
            </div>
            <Popover
              getPopupContainer={() =>
                document.getElementById("prefecture") as HTMLElement
              }
              content={
                <PrefectureSelectForm
                  value={filterPrefecture.map((x) => x.value)}
                  list={listCity}
                  onSubmit={(listFilter: any[]) => {
                    setFilterPrefecture(listFilter);
                  }}
                />
              }
              overlayClassName="custom-popup-select-province"
              title=""
              trigger="click"
              visible={showPrefecture}
              placement="top"
              onVisibleChange={handleVisibleChange}
            >
              <div
                className={classNames("fake-select", { focus: showPrefecture })}
                onClick={() => {
                  setShowPrefecture(true);
                }}
              >
                <span>都道府県を選択してください</span>
                <ArrowDown />
              </div>
            </Popover>

            <AppTagSelection
              label={undefined}
              name="prefecture"
              options={filterPrefecture}
              onAddTag={onPrefectureTag}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchFilterForm;
