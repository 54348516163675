import "./styles.scss";
import { Col, Form, Row } from "antd";
import { LabeledValue } from "antd/lib/select";
import React, { FC, useEffect, useLayoutEffect, useMemo } from "react";
import AppButton from "src/components/AppButton";
import AppRadio from "src/components/Form/AppRadio";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/store/configureStore";
import { ProductSort, setSort } from "src/store/Product-Filter";
import { useTranslation } from "react-i18next";

interface Props {
  onSortChange: () => void;
}
const AppSortForm: FC<Props> = ({ ...props }) => {
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const productSort = useSelector(
    (state: RootState) => state.ProductFilterReducer.sort
  );

  useLayoutEffect(() => {
    const formValue = productSort?.sortByPrice
      ? productSort.sortByPrice
      : productSort?.sortByRegisterDate
      ? productSort.sortByRegisterDate + 2
      : productSort?.sortByYearOfManufacture
      ? productSort.sortByYearOfManufacture + 4
      : undefined;
    form.setFieldsValue({
      sort: formValue,
    });
  }, [productSort]);

  const radio1: LabeledValue[] = [
    {
      label: "価格の安い順",
      value: 1,
    },

    {
      label: "価格の高い順",
      value: 2,
    },

    {
      label: "登録日時の古い順",
      value: 3,
    },
    {
      label: "登録日時の新しい順",
      value: 4,
    },
    {
      label: "年式の古い順",
      value: 5,
    },
    {
      label: "年式の新しい順",
      value: 6,
    },
  ];

  function onSortChange() {
    props.onSortChange();
    const sort = {} as ProductSort;
    const sortValue = form.getFieldValue("sort");
    switch (sortValue) {
      case 1:
      case 2:
        sort.sortByPrice = sortValue;
        break;

      case 3:
      case 4:
        sort.sortByRegisterDate = sortValue - 2;
        break;

      case 5:
      case 6:
        sort.sortByYearOfManufacture = sortValue - 4;
        break;

      default:
        break;
    }

    dispatch(setSort(sort));
  }

  return (
    <div className="sort-form-bottom-sheet">
      <Form form={form} className="sort-body sort-radio">
        <AppRadio options={radio1} name="sort" label="" />

        {/* <AppRadio options={radio2} name="date" label="登録日時" type="normal" />

        <AppRadio options={radio3} name="year" label="年式" type="normal" /> */}
      </Form>

      <div className="sort-button-container">
        <Row gutter={[16, 16]}>
          {/* <Col xs={12}>
            <AppButton
              buttontype="secondary"
              onClick={() => {
                form.setFieldsValue({
                  sort: undefined,
                });
              }}
            >
              クリア
            </AppButton>
          </Col> */}
          <Col xs={24}>
            <AppButton
              buttontype="primary"
              onClick={() => {
                onSortChange();
              }}
            >
              {translate("search.sort")}
            </AppButton>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AppSortForm;
