import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProductService from '@service/API/Product';

export interface ProductFilterState {
    filter?: ProductFilter;
    sort?: ProductSort;
    keyword?: string;
    statusFilter: boolean;
    page?: number;
    currentCategorySelect?: string;
    currentScreenMobile?: { count: number, position: number, page: number } | null;
}

export interface ProductFilter {
    categories?: number[];
    maker?: string;
    yearOfManufactureFrom?: any;
    yearOfManufactureTo?: any;
    priceFrom?: number;
    priceTo?: number;
    citySearch?: any[];
    isPickup?: boolean;
    isRecently?: boolean;
    isFilterByType?: boolean;
    page?: number;
}
export interface ProductSort {
    sortByPrice?: number;
    sortByRegisterDate?: number;
    sortByYearOfManufacture?: number;
}

export const getListCategory = createAsyncThunk(
    'product/category',
    // eslint-disable-next-line consistent-return
    async () => {
        const response = await ProductService.getListCategory();
        if (response) {
            return response.data;
        }
    },
);


const initialState: ProductFilterState = { statusFilter: false, keyword: '', sort: {}, page: 1 };
const slice = createSlice({
    name: "ProductFilter",
    initialState,
    reducers: {

        setAllState: (state, { payload: stateFilter }: { payload: ProductFilterState }) => {
            state.keyword = stateFilter.keyword;
            state.statusFilter = stateFilter.statusFilter;
            state.filter = stateFilter.filter;
        },

        setFilter: (state, { payload: filter }: { payload: ProductFilter }) => {
            state.filter = filter;
            state.page = 1;
        },

        setStatePosition: (state, { payload: currentScreenMobile }: { payload: { count: number, position: number, page: number } | null }) => {
            state.currentScreenMobile = currentScreenMobile;
        },


        setPage: (state, { payload: page }: { payload: number }) => {
            state.page = page;
        },

        clearAll: (state) => {
            state.filter = undefined;
            state.sort = undefined;
            state.statusFilter = false;
            state.page = 1;
            state.keyword = '';
            state.currentCategorySelect = undefined;
        },

        setSort: (state, { payload: sort }: { payload: ProductSort }) => {
            state.sort = sort;

        },

        setKeyword: (state, { payload: keyword }: { payload: string }) => {
            state.keyword = keyword;
            if (keyword && keyword !== '') {
                state.filter = { ...state.filter, isFilterByType: false, isPickup: true, isRecently: true };
                state.statusFilter = true;
                state.page = 1;
            }
        },

        setStatusFilter: (state, { payload: { status, categoryId, categories } }: { payload: { status: boolean, categoryId?: number, categories?: number[] } }) => {
            state.statusFilter = status;
            state.currentCategorySelect = undefined;
            if (!status) {
                state.keyword = '';
                state.filter = undefined;
                state.sort = undefined;
            }

            if (status && categoryId) {
                state.keyword = '';
                state.filter = { categories: categories };
                state.currentCategorySelect = `${categoryId}`;
            }

        },

        setCurrentCategory: (state, { payload: { categoryId } }: { payload: { categoryId?: number } }) => {
            state.currentCategorySelect = `${categoryId}`;
        },

        filterByType: (state, { payload: type }: { payload: string }) => {
            state.filter = type === 'PICKUP' ? { isPickup: true, isRecently: false, isFilterByType: true } : { isRecently: true, isPickup: false, isFilterByType: true };
            state.statusFilter = true;
        },


        clearFilterByType: (state) => {
            state.filter = { ...state.filter, isFilterByType: false, isPickup: true, isRecently: true };
            state.statusFilter = true;
        },

    },

});

export const { setFilter, setSort, setKeyword, setStatusFilter, clearAll, filterByType, setPage, setCurrentCategory, clearFilterByType, setAllState, setStatePosition } = slice.actions;

export default slice.reducer;
