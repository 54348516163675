import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProductService from "src/services/API/Product";

interface UserInformation {
  id: number | string;
  name: string;
  emailAddress: string;
  dateOfBirth: any;
  companyName: string;
  department: string;
  position: string;
  zipCode: string;
  city: string;
  district: string;
  street: string;
  address: string;
  buildingName: string;
  phoneNumber: string;
}

interface NoticeTransactionDashboard {
  purchaseHistoryTotal: number;
  saleHistoryTotal: number;
}

interface MyPageState {
  userInformation: UserInformation;
  favoriteList: Array<object>;
  purchaseHistoryList: Array<object>;
  purchaseHistoryDetail: object;
  noticeTransactionDashboard: NoticeTransactionDashboard;
}

const initialState: MyPageState = {
  userInformation: {} as UserInformation,
  favoriteList: [],
  purchaseHistoryList: [],
  purchaseHistoryDetail: {},
  noticeTransactionDashboard: {} as NoticeTransactionDashboard,
};

export const getNoticeTransactionDashboard = createAsyncThunk(
  "my-page/notice-transaction",
  // eslint-disable-next-line consistent-return
  async () => {
    const response = await ProductService.getNoticeTransactionDashboard();
    if (response) {
      return response.data;
    }
  }
);

const slice = createSlice({
  name: "MyPage",
  initialState,
  reducers: {
    getUser: (state, { payload }) => {
      state.userInformation = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNoticeTransactionDashboard.fulfilled,
      (state, action) => {
        state.noticeTransactionDashboard = action.payload.result;
      }
    );
  },
});

export const { getUser } = slice.actions;

export default slice.reducer;
