import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { Modal, ModalProps } from "antd";
import "./index.scss";
import { CloseOutlined } from "@ant-design/icons";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import { ProductMedia } from "../../models/product.model";
import AppImageView from "../AppImageView";
import AppVideoPlayer from "../AppVideoPlayer";
import { MediaSlider } from "../AppSliderMedia";

export interface AppModalProps extends ModalProps {
  media?: MediaSlider | ProductMedia | any;
  onClose: () => void;
}

const AppModalMedia: FC<AppModalProps> = memo(({ ...props }) => {
  const { visible, onClose, media } = props;
  const [isVideoPlay, setIsVideoPlay] = useState(true);
  useEffect(() => {
    console.log(media?.type === 2);
  }, [media]);

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  const imgRef = useRef(null);
  const onUpdate = useCallback(({ x, y, scale }: any) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      (img as any).style.setProperty("transform", value);
    }
  }, []);
  const isMobile = useMediaQuery({
    query: "(max-width: 991px)",
  });
  return (
    <Modal
      className={classnames("app-modal-full")}
      visible={visible}
      centered
      footer={null}
      closable={false}
      onCancel={onClose}
    >
      <div onClick={onClose} className="close-icon">
        <CloseOutlined />
      </div>

      {
        media?.type === "video" || media?.type === 2 ? (
          <AppVideoPlayer
            isPlay={isVideoPlay}
            url={media?.src?.concat(sasCode) || media?.url?.concat(sasCode) || ""}
            width="100%"
            key={media?.id}
            isEnableControl
            onPlayChange={(isPlay) => {
              setIsVideoPlay(isPlay);
            }}
          />
        ) : isMobile ? (
          <QuickPinchZoom onUpdate={onUpdate}>
            <img
              ref={imgRef}
              src={
                media?.src?.concat(sasCode) || media?.url?.concat(sasCode) || ""
              }
              alt=""
              style={{ width: "100%" }}
            />
          </QuickPinchZoom>
        ) : (
          <AppImageView
            src={media?.src?.concat(sasCode) || media?.url?.concat(sasCode) || ""}
          />
        )

        //
      }
    </Modal>
  );
});

export default AppModalMedia;

AppModalMedia.defaultProps = {};
