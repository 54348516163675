import { Button, Col, Row, Skeleton } from "antd";
import React, { FC, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import VerticalItem, {
  ItemViewType,
} from "@components/utils/vertical-list-item";
import { useTranslation } from "react-i18next";
import { ProductItem } from "@models/product.model";
import "./styles.scss";
import { Desktop, Mobile } from "src/layouts/break-point";
import AppButton from "@components/AppButton";
import { ReactComponent as ArrowLeft } from "@components/Icons/arrow-left-3.svg";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_NAME } from "src/configs";
import { ReactComponent as InfoIcon } from "@components/Icons/info-icon.svg";


export interface InfoProps {
  productInfo: ProductItem;
  onConfirm: any;
}
const InfoScreen: FC<InfoProps> = ({ ...props }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  return (
      <div className="product-confirm-info">
        <Desktop>
          <div
            style={{
              fontSize: "24px",
              fontWeight: 700,
              color: "#333333",
              padding: "48px 0 16px 16px",
            }}
          >
            <ArrowLeft
              style={{ cursor: "pointer", marginRight: "32px" }}
              onClick={() => navigate(`/product-detail/${id}`)}
            />
            {translate("product.delivery.step1")}
          </div>
        </Desktop>

        {
          (!!Object.keys(props.productInfo).length && !props.productInfo.isAllowOnlineTours) && (
            <div className="confirm-caption">
              <div className="ui-my-page-bank-view__guideline" style={{ padding: 0 }}>
                <span>
                  <InfoIcon />
                </span>
                <span>この商品はオンライン見学ができません。</span>
              </div>

              <span>{translate("product.confirm.caption1")}</span>
              <br />
              <span>{translate("product.confirm.caption2")}</span>
            </div>
          )
        }

        {
          Object.keys(props.productInfo).length ? (
            <div className="product-order-detail">
              <VerticalItem
                item={props.productInfo}
                type={ItemViewType.CONFIRM_BUY}
              ></VerticalItem>
              <div className="order-price">
                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("common.commission")}
                  </div>
                  <div className="i-value color-primary text-body-4 bold">
                    <div>
                      <span className="text-caption-2 ">¥</span>
                      <NumberFormat
                        value={Number(
                          (props.productInfo?.commissionAmount as number) +
                          (props.productInfo?.consumptionTaxAmount as number) || 0
                        )}
                        displayType="text"
                        thousandSeparator
                      />
                      <span className="color-title text-caption-2">（内税）</span>
                    </div>
                    {/* <div className="color-body text-caption-2">
                {`(${translate("product.confirm.note")} `}
                <span className="text-caption-2">¥</span>
                <NumberFormat
                  value={props.productInfo.consumptionTaxAmount}
                  displayType="text"
                  thousandSeparator
                />
                {` ${translate("product.confirm.note2")})`}
              </div> */}
                  </div>
                </div>
                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("common.total")}
                  </div>
                  <div className="i-value color-primary text-body-4 bold">
                    <span className="text-caption-2">¥</span>
                    <NumberFormat
                      value={props.productInfo.totalAmount}
                      displayType="text"
                      thousandSeparator
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Row gutter={[32, 32]} justify="center" className="skeleton-product">
              <Col xs={24}>
                <Skeleton.Avatar
                  active
                  shape="square"
                  className="product-skeleton-slider"
                />
              </Col>

              <Col xs={24}>
                <Skeleton.Avatar
                  active
                  shape="square"
                  className="product-skeleton-slider small"
                />
              </Col>

              <Col xs={24}>
                <Skeleton.Avatar
                  active
                  shape="square"
                  className="product-skeleton-slider small"
                />
              </Col>
            </Row>
          )
        }

        <Mobile>
          <div className="button-container">
            <Button className="button-secondary" onClick={props.onConfirm}>
              {translate("product.confirm.text.confirm")}
            </Button>
          </div>
        </Mobile>

        <Desktop>
          <Row gutter={[16, 16]} justify="center" style={{ marginTop: "32px" }}>
            <Col xs={8}>
              <AppButton buttontype="primary" onClick={props.onConfirm}>
                {translate("product.confirm.text.confirm")}
              </AppButton>
            </Col>
          </Row>
        </Desktop>
      </div>
   
  );
};

export default InfoScreen;
