import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@store/configureStore";
import AppNavigation from "@components/AppNavigation";

import { ReactComponent as ArrowLeft } from "@components/Icons/arrow-left-3.svg";
import { PATH_NAME } from "src/configs";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import "./index.scss";
import useHideBottomBar from "../../../../hooks/useHideBottomBar";

const TitleMenu: FC = ({ ...props }) => {
  useHideBottomBar(false);
  const navigate = useNavigate();
  const userInfo = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const { t } = useTranslation();

  const [title, setTitleMenu] = useState("");

  const location = useLocation();

  const titleToolBar = useSelector(
    (state: RootState) => state.AppStateReducer.titleToolBar
  );

  function getTitle() {
    const titlePath = location.pathname.slice(9, location.pathname.length);
    switch (titlePath) {
      case PATH_NAME.MY_PAGE_PROFILE:
        setTitleMenu(t("my.page.profile"));
        break;

      case PATH_NAME.MY_PAGE_BANK_ACCOUNT:
        setTitleMenu(t("my.page.bank.account"));
        break;

      case PATH_NAME.MY_PAGE_FAVORITE:
        setTitleMenu(t("my.page.favorite"));
        break;

      case PATH_NAME.MY_PAGE_PURCHASE_HISTORY:
        setTitleMenu(t("my.page.purchase.history"));
        break;

      case PATH_NAME.MY_PAGE_SALE_HISTORY:
        setTitleMenu(t("my.page.sales.history"));
        break;

      case PATH_NAME.CHANGE_PASSWORD:
        setTitleMenu(t("my.page.change.password"));
        break;

      case PATH_NAME.MY_PAGE_QUIT_MEMBERSHIP:
        setTitleMenu(t("my.page.quit.membership"));
        break;

      default:
        setTitleMenu("");
    }
  }

  useEffect(() => {
    getTitle();
  }, [location]);

  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );

  function handleBack() {
    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      navigate((location.state as any)?.routeState || -1);
    }
  }

  return (
    <div className="ui-my-page-title-menu">
      <Row gutter={[32, 32]} justify="center">
        <Col xs={24}>
          <AppNavigation title="マイページ" />
        </Col>
      </Row>

      <div className="ui-my-page-title-menu__content">
        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} md={24} xl={24}>
            <div className="ui-my-page-title-menu__title">
              {titleToolBar?.length > 0 ? (
                <>
                  <div
                    className="ui-my-page-title-menu__title-icon"
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    <ArrowLeft />
                  </div>
                  <div className="ui-my-page-title-menu__title-content">
                    <div>{titleToolBar}</div>
                    {(location.pathname.includes("profile") ||
                      location.pathname.includes("bank-account")) && (
                      <div className="ui-my-page-title-menu__id">
                        会員ID: {userInfo?.id}
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="ui-my-page-title-menu__title-content">
                  <div>{title}</div>
                  {(location.pathname.includes("profile") ||
                    location.pathname.includes("bank-account")) && (
                    <div className="ui-my-page-title-menu__id">
                      会員ID: {userInfo?.id}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TitleMenu;

TitleMenu.defaultProps = {};
