import React, { FC, useEffect, useState } from "react";
import Slider from "react-slick";
import { CloseOutlined } from "@ant-design/icons";
import "./styles.scss";
import classNames from "classnames";
import { Desktop, Mobile } from "src/layouts/break-point";
import { ReactSortable } from "react-sortablejs";
import { useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import AppVideoPlayer from "../AppVideoPlayer";
import AppImageView from "../AppImageView";
import AppModalMedia from "../AppModalMedia";

export interface MediaSlider {
  type: "video" | "camera" | string;
  src: string;
  id: number | string;
  data?: any;
}

function PrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={classNames(className, "button-slider")} onClick={onClick}>
      <img className="prev" src="/assets/icons/arr-left.svg" alt="" />
    </div>
  );
}
function NextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={classNames(className, "button-slider")} onClick={onClick}>
      <img className="next" src="/assets/icons/arr-right.svg" alt="" />
    </div>
  );
}
export interface Props {
  listMedia: MediaSlider[];
  onRemoveItem: (id: any) => void;
  disableClose?: boolean;
  disabled?: boolean;
  onChangePosition?: (listMedia: MediaSlider[]) => void;
}

const AppSliderMedia: FC<Props> = ({ ...props }) => {
  const [listMedia, setImageMedia] = useState(props.listMedia);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState<MediaSlider>();
  useEffect(() => {
    setImageMedia(props.listMedia);
  }, [props.listMedia]);
  useEffect(() => {
    console.log(currentMedia);
  }, [currentMedia]);

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  const onDragDropEnds = (oldIndex: any, newIndex: any) => {};

  return (
    <>
      {showFullScreen && (
        <AppModalMedia
          visible={showFullScreen}
          onClose={() => {
            setShowFullScreen(false);
          }}
          media={currentMedia}
        />
      )}
      <Mobile>
        {props.disabled ? (
          <div className="app-media-slider">
            <Slider
              infinite={false}
              slidesToShow={listMedia.length >= 3 ? 3 : listMedia.length}
              // slidesToScroll={1}
              swipeToSlide
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
              className={classNames({ "slider-normal": listMedia.length < 3 })}
            >
              {listMedia.map((option: MediaSlider) => (
                <div className="div-s-ratio" key={option.src}>
                  <div
                    className="media-item"
                    onClick={() => {
                      setCurrentMedia(option);
                      setShowFullScreen(true);
                    }}
                  >
                    {option.type === "video" ? (
                      <AppVideoPlayer
                        url={option.src.concat(sasCode as string)}
                        height="100%"
                        width="100%"
                      />
                    ) : (
                      <AppImageView
                        src={option.src.concat(sasCode as string)}
                      />
                    )}
                    {!props.disableClose && (
                      <div
                        className="media-item__close-icon"
                        onClick={() => props.onRemoveItem(option.id)}
                      >
                        <div className="button-close">
                          <CloseOutlined />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div className="app-media-slider grid-view">
            <ReactSortable
              disabled={props.disabled}
              list={listMedia}
              setList={(newList) => {
                setImageMedia(newList);
                if (props.onChangePosition) {
                  props.onChangePosition(newList);
                }
              }}
              ghostClass="dropArea"
              handle=".dragHandle"
              filter=".ignoreDrag"
              // chosenClass="chosenClass"
              preventOnFilter={true}
              delay={50}
              className="grid-container"
              onEnd={({ oldIndex, newIndex }) =>
                onDragDropEnds(oldIndex, newIndex)
              }
            >
              <>
                {listMedia.map((option: MediaSlider, index: number) => (
                  <div key={option.src}>
                    <div className="div-s-ratio">
                      <div
                        className="media-item"
                        key={index}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          setCurrentMedia(option);
                          setShowFullScreen(true);
                        }}
                      >
                        <div className="dragHandle media-item">
                          {option.type === "video" ? (
                            <AppVideoPlayer
                              url={option.src.concat(sasCode as string)}
                              height="100%"
                              width="100%"
                            />
                          ) : (
                            <AppImageView
                              src={option.src.concat(sasCode as string)}
                            />
                          )}
                        </div>
                        {!props.disableClose && (
                          <div
                            className="media-item__close-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.onRemoveItem(option.id);
                            }}
                          >
                            <div className="button-close">
                              <CloseOutlined id="close-button" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            </ReactSortable>
          </div>
        )}
      </Mobile>

      <Desktop>
        <div className="app-media-slider">
          <ReactSortable
            disabled={props.disabled}
            list={listMedia}
            setList={(newList) => {
              setImageMedia(newList);
              if (props.onChangePosition) {
                props.onChangePosition(newList);
              }
            }}
            ghostClass="dropArea"
            chosenClass="chosenClass"
            dragClass="dragClass"
            handle=".dragHandle"
            filter=".ignoreDrag"
            preventOnFilter={true}
            className="grid-container"
            onEnd={({ oldIndex, newIndex }) =>
              onDragDropEnds(oldIndex, newIndex)
            }
          >
            <>
              {listMedia.map((option: MediaSlider) => (
                <div
                  className="media-item"
                  key={option.src}
                  onClick={() => {
                    setCurrentMedia(option);
                    setShowFullScreen(true);
                  }}
                >
                  <div className="dragHandle media-item">
                    {option.type === "video" ? (
                      <AppVideoPlayer
                        url={option.src.concat(sasCode as string)}
                        height="100%"
                        width="100%"
                      />
                    ) : (
                      <AppImageView
                        src={option.src.concat(sasCode as string)}
                      />
                    )}
                  </div>
                  {!props.disableClose && (
                    <div
                      className="media-item__close-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onRemoveItem(option.id);
                      }}
                    >
                      <div className="button-close">
                        <CloseOutlined />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </>
          </ReactSortable>
        </div>
      </Desktop>
    </>
  );
};

export default AppSliderMedia;
