import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Register } from "@components/Icons/register.svg";
import { ReactComponent as Language } from "@components/Icons/language.svg";
import "./styles.scss";
import { Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/configureStore";
import classNames from "classnames";
import { CaretDownOutlined } from "@ant-design/icons";
import UserService from "src/services/API/User";
import { clearAll } from "src/store/Product-Filter";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import { setOffline, setShowTranslate } from "@store/State";
import { clearRoomIds } from "@store/Chat";
import ChatService from "@service/API/Chat";
import { Collection } from "@models/chat.model";
import NotificationService from "src/services/API/Notification";
import {
  setTotalUnreadMyNotice,
  setTotalUnreadNotice,
  setVideoCallStatus,
} from "src/store/Global";
import Translate from '@components/Icons/translate';
import { PATH_NAME } from "../../configs/index";
import UserMenu from "../Icons/user-dk";
import NotiMenu from "../Icons/noti-dk";
import DashboardMenu from "../Icons/dashboard-dk";
import AppSearchForm from "../core/search-form";
import AppModal from "../AppModal";

interface Props {
  title?: string;
  route?: string;
  onClick?: () => void;
}

const AppToolbar: FC<Props> = memo(({ ...props }) => {
  const dispatch = useDispatch();
  const locationHook = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const titleToolBar = useSelector(
    (state: RootState) => state.AppStateReducer.titleToolBar
  );

  const userInfo = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const totalUnReadNotice = useSelector(
    (state: RootState) => state.GlobalReducer.totalUnreadNotice
  );

  const totalUnreadMyNotice = useSelector(
    (state: RootState) => state.GlobalReducer.totalUnreadMyNotice
  );

  const appTotalUnreadNotice = useMemo(
    () => (totalUnReadNotice || 0) + (totalUnreadMyNotice || 0),
    [totalUnReadNotice, totalUnreadMyNotice]
  );

  const deviceToken = useSelector(
    (state: RootState) => state.GlobalReducer.deviceToken
  );

  const connectionSystem = useSelector(
    (state: RootState) => state.AppStateReducer.connectionSystem
  );

  const [isConfirmLogout, setIsConfirmLogout] = useState(false);

  const handleLogout = async () => {
    dispatch(clearRoomIds([]));
    setIsConfirmLogout(false);
    UserService.logout();
    if (deviceToken) {
      const userIndex = await ChatService.getDocumentIndex(
        Collection.USERS,
        "userId",
        userInfo?.id as number
      );
      await ChatService.deleteUserToken(
        userIndex as string,
        deviceToken as string
      );
    }
    navigate(PATH_NAME.LOGIN);
    dispatch(setTotalUnreadNotice(0));
    dispatch(setTotalUnreadMyNotice(0));
    dispatch(setVideoCallStatus(undefined));
    const message = {
      type: "LOGOUT",
    };
    (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify(message));
    if (connectionSystem) {
      connectionSystem.stop();
    }
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <div
              className="text-route"
              onClick={() => {
                navigate("/my-page/profile");
              }}
            >
              会員情報管理
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div
              className="text-route"
              onClick={() => {
                navigate("/my-page/bank-account");
              }}
            >
              銀行口座情報
            </div>
          ),
          key: "1",
        },
        {
          label: (
            <div
              className="text-route"
              onClick={() => {
                navigate("/my-page/favorite");
              }}
            >
              お気に入り一覧
            </div>
          ),
          key: "2",
        },
        {
          label: (
            <div
              className="text-route"
              onClick={() => {
                navigate("/my-page/purchase-history?tab=0");
              }}
            >
              購入履歴
            </div>
          ),
          key: "3",
        },
        {
          label: (
            <div
              className="text-route"
              onClick={() => {
                navigate("/my-page/sales-history?tab=0");
              }}
            >
              販売履歴
            </div>
          ),
          key: "4",
        },
        {
          label: (
            <div
              className="text-route"
              onClick={() => {
                navigate("/my-page/change-password");
              }}
            >
              パスワード変更
            </div>
          ),
          key: "5",
        },
        {
          type: "divider",
          className: "custom-divider-dropdown",
        },
        {
          label: (
            <div
              className="text-route"
              onClick={() => setIsConfirmLogout(true)}
            >
              ログアウト
            </div>
          ),
          key: "6",
        },
      ]}
    />
  );

  const [isDashboard, setIsDashboard] = useState(false);
  const isAuthenticate = useSelector(
    (state: RootState) => state.UserReducer.isLogin
  );
  const showTranslate = useSelector(
    (state: RootState) => state.AppStateReducer.showTranslate
  );
  const [title, setTitle] = useState("");
  function getTitle() {
    switch (locationHook.pathname) {
      case PATH_NAME.LOGIN:
        setIsDashboard(false);
        setTitle(t("login.login"));
        break;

      case PATH_NAME.SIGN_UP:
        setIsDashboard(false);
        setTitle(t(`${titleToolBar}`));
        break;

      case PATH_NAME.SIGN_UP_SUCCESS:
        setIsDashboard(false);
        setTitle(t(`${titleToolBar}`));
        break;

      case PATH_NAME.RESET_PASSWORD:
        setIsDashboard(false);
        setTitle(t(`${titleToolBar}`));
        break;

      case PATH_NAME.RESET_PASSWORD_SUCCESS:
        setIsDashboard(false);
        setTitle(t(`${titleToolBar}`));
        break;

      case PATH_NAME.CHANGE_PASSWORD:
        setIsDashboard(false);
        setTitle(t(`${titleToolBar}`));
        break;

      default:
        setIsDashboard(false);
        setTitle("");
    }
  }

  function checkIsDashboard() {
    setIsDashboard(
      [
        `/${PATH_NAME.DASHBOARD}`,
        `/${PATH_NAME.SHOP}`,
        `/${PATH_NAME.MY_PAGE}`,
        `/${PATH_NAME.NOTIFICATION}`,
        `/${PATH_NAME.CONTACT_PAGE}`,
        `${PATH_NAME.PRODUCT_DETAIL}`,
        `${PATH_NAME.EDIT_PRODUCT}`,
        `${PATH_NAME.CHANGE_PASSWORD}`,
        `${PATH_NAME.PRODUCT_CONFIRM}`,
        `/product-detail`,
      ].some((item) => locationHook.pathname.indexOf(item) >= 0)
    );
  }
  useEffect(() => {
    getTitle();
    checkIsDashboard();
  }, [locationHook, titleToolBar]);

  useEffect(() => {}, [isAuthenticate]);

  function onGoDashboard() {
    if (window.navigator.onLine) {
      dispatch(setOffline(false));
      dispatch(clearAll());
      navigate(PATH_NAME.DASHBOARD);
      if (locationHook.pathname === "/dashboard") {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  }

  return (
    <div className="ui-toolbar">
      <AppModal
        icon={<Warning />}
        visible={isConfirmLogout}
        title={t("logout.modal.title")}
        okText={t("logout.modal.ok")}
        cancelText={t("logout.modal.cancel")}
        onOk={handleLogout}
        onCancel={() => setIsConfirmLogout(false)}
      />
      <div className="toolbar-body">
        <div className="image-icon-app" onClick={onGoDashboard}>
          <img
            className="image-icon-app"
            src="/assets/images/icon-app.svg"
            alt=""
          />
        </div>

        {!isDashboard && <div className="toolbar-title">{title}</div>}

        {isDashboard && (
          <div className="toolbar-action-container">
            <AppSearchForm></AppSearchForm>
            {!isAuthenticate && (
              <>
                <div
                  className="box-login box-action pointer"
                  onClick={() => {
                    navigate(PATH_NAME.LOGIN);
                  }}
                >
                  <UserMenu active />
                  <div className="text">ログイン</div>
                </div>
                <div
                  className="box-register box-action pointer"
                  onClick={() => {
                    navigate(PATH_NAME.SIGN_UP);
                  }}
                >
                  <Register></Register>
                  <div className="text">会員登録</div>
                </div>
              </>
            )}

            {isAuthenticate && (
              <>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <div
                    className={classNames("box-route box-action", "pointer", {
                      active:
                        locationHook.pathname.includes(PATH_NAME.MY_PAGE) ||
                        locationHook.pathname.includes(
                          PATH_NAME.PRODUCT_CONFIRM
                        ),
                    })}
                  >
                    <UserMenu
                      active={
                        locationHook.pathname.includes(PATH_NAME.MY_PAGE) ||
                        locationHook.pathname.includes(
                          PATH_NAME.PRODUCT_CONFIRM
                        )
                      }
                    />
                    <div className="text bold user-name">
                      <span className="three-dot">{userInfo?.name}</span>
                      <CaretDownOutlined />
                    </div>
                  </div>
                </Dropdown>
                <div
                  className={classNames("box-route box-action", "pointer", {
                    active: locationHook.pathname.includes(
                      PATH_NAME.NOTIFICATION
                    ),
                  })}
                  onClick={() => {
                    navigate(PATH_NAME.NOTIFICATION);
                  }}
                >
                  <div
                    className={classNames("h-20", {
                      relative: !!(
                        appTotalUnreadNotice && appTotalUnreadNotice >= 1
                      ),
                    })}
                  >
                    <NotiMenu
                      active={locationHook.pathname.includes(
                        PATH_NAME.NOTIFICATION
                      )}
                    />
                    {!!(appTotalUnreadNotice && appTotalUnreadNotice > 0) && (
                      <div
                        className={classNames(
                          "toolbar-notice",
                          locationHook.pathname.includes(
                            PATH_NAME.NOTIFICATION
                          ) && "active"
                        )}
                      >
                        <span
                          className={classNames(
                            appTotalUnreadNotice > 99 && "font-9"
                          )}
                        >
                          {appTotalUnreadNotice > 99
                            ? "99+"
                            : appTotalUnreadNotice}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="text">お知らせ</div>
                </div>

                {/* <div
                  className={classNames("box-route box-action", "pointer")}
                  // onClick={() => {
                  //   navigate(PATH_NAME.LOGIN);
                  // }}
                >
                  <SettingMenu active={false} />
                  <div className="text">設定</div>
                </div> */}

                <div
                  className={classNames("box-route box-action", "pointer", {
                    active: locationHook.pathname.includes(PATH_NAME.SHOP),
                  })}
                  onClick={() => {
                    navigate(PATH_NAME.SHOP);
                  }}
                >
                  <DashboardMenu
                    active={locationHook.pathname.includes(PATH_NAME.SHOP)}
                  />
                  <div className="text">商品登録</div>
                </div>
              </>
            )}

            <div
              className="box-language box-action pointer"
              onClick={() => {
                const isOnline = window.navigator.onLine;
                if (!isOnline) {
                  navigate('/network-error');
                  return ;
                }
                dispatch(setShowTranslate(!showTranslate));
              }}
              style={{
                color: showTranslate ? "#FFF" : "#333333",
                background: showTranslate ? "#FF671E" : "#EFF0F1",
                borderRadius: '8px',
                padding: '4px 12px',
              }}
            >
              {
                showTranslate ? <Translate /> : <Language />
              }
              <div className="text">言語</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default AppToolbar;

AppToolbar.defaultProps = {};
