/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useState } from "react";

import "./home.scss";
import AppSearchForm from "@components/core/search-form/index";
import { useMediaQuery } from "react-responsive";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import { setIsQuitMembership, setMetaTitle } from "src/store/Global";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store/configureStore";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppHelmet from '@components/AppHelmet';
import { setStatusFilter } from "src/store/Product-Filter";
import { setError } from "src/store/State";
import DashboardTab from "./dashboard-tab";

export default function HomeScreen() {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const [key, setKey] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setIsQuitMembership(false));
    }, 1000);
  }, []);

  const [searchParams] = useSearchParams();
  const urlCategory = searchParams.get('categories');
  const { id } = useParams();

  const category = useMemo(() => (listCategory || []).find(category => category.id === Number(urlCategory || id)), [listCategory, urlCategory]);

  useEffect(() => {
    if (category) {
      dispatch(setMetaTitle(`マシプラ ${category ? `| ${category.name}` : ''}`));
    }
    if (id ) {
      if (/^\d+$/.test(id)) {
        dispatch(setStatusFilter({ status: true }));
      } else {
        dispatch(
          setError({
            message: "C009",
            title: "ページが存在しません。",
            action: () => {
              navigate("/dashboard");
            },
          })
        );
      }
    } 
    return () => {
      dispatch(setMetaTitle(''));
    };
  }, [category]);

  return (
    <>
      <AppHelmet content={category?.description} />
      {!isDesktop && (
        <AppPullToRefresh
          onRefresh={() => {
            setKey(Math.random());
          }}
        >
          <div className="home-dashboard-container" key={key}>
            <AppSearchForm placeholder="Search Product"></AppSearchForm>
            <DashboardTab />
          </div>
        </AppPullToRefresh>
      )}
      {isDesktop && (
        <div className="home-dashboard-container">
          <DashboardTab />
        </div>
      )}
    </>
  );
}
