import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserInfo } from "@models/user.model";
import UserService from "@service/API/User";
import { get } from "lodash";
import StorageService from '@service/Storage/index';

interface LoginState {
    isLogin: boolean;
    userInfo?: UserInfo;
}

export const login = createAsyncThunk<any, any, any>(
    'user/login',
    // eslint-disable-next-line consistent-return
    async ({ email, password, remember }) => {
        const response = await UserService.login(email, password);
        if (response) {
            StorageService.saveUserToken(get(response, "data.result.accessToken"), remember as boolean);
            try {
                const message = { type: 'TOKEN', data: { accessToken: get(response, "data.result.accessToken") } };
                (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
            } catch (e) {
                console.log('Send message to app fail');
            }

            return response.data;
        }
         
    },
);

export const getUserInfo = createAsyncThunk(
    'user/userInfo',
    // eslint-disable-next-line consistent-return
    async () => {
        const response = await UserService.getUserInfo();
        if (response) {
            return response.data;
        }

    },
);

const initialState: LoginState = { isLogin: false, userInfo: {} as UserInfo };
const slice = createSlice({
    name: "User",
    initialState,
    reducers: {
        signIn: (state, { payload: { userInfo } }) => {
            state.isLogin = true;
            // state.userInfo = userInfo;
        },

        signOut: (state) => {
            state.isLogin = false;
            state.userInfo = undefined;
        },
    },
    extraReducers: builder => {

        builder.addCase(login.fulfilled, (state, action) => {
            state.isLogin = true;
        });
        builder.addCase(login.rejected, state => {
            state.isLogin = false;
        });

        builder.addCase(getUserInfo.fulfilled, (state, action) => {
            state.userInfo = action.payload.result;
        });

        builder.addCase(getUserInfo.rejected, state => {
            state.userInfo = undefined;
        });
    }
});

export const { signIn, signOut } = slice.actions;

export default slice.reducer;
