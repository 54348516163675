import React from 'react';
import { Skeleton } from "antd";
import "./index.scss";

const ChatSkeleton = () => (
  <div className="ui-chat-skeleton">
    <Skeleton.Avatar className="ui-chat-skeleton__avatar" active size="large" shape="square"/>
    <Skeleton className="ui-chat-skeleton__title" active paragraph={{ rows: 0 }} />
    <Skeleton className="ui-chat-skeleton__message" active paragraph={{ rows: 0 }} />
    <Skeleton className="ui-chat-skeleton__message" active paragraph={{ rows: 0 }} />
    <Skeleton className="ui-chat-skeleton__message" active paragraph={{ rows: 0 }} />
  </div>
);

export default ChatSkeleton;